import React, { useEffect, useState ,useRef} from 'react';
import { Box, Stack, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { getDatabase, ref, onValue, get, push, set } from 'firebase/database';
import { getFirestore, doc, getDoc, addDoc, collection, updateDoc } from 'firebase/firestore';
import theme from './theme';
import CustomBingoText from './customBingoText';
import { useMediaQuery } from '@mui/material';
import BingoNumber from './BingoNew';
import ResponsiveAppBar from './appbar';
import CustomizedDialogs from './car';
import { OrbitSpace } from 'orbit-space';
import LoginDialog from '../login2';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import axios from 'axios';
import { motion } from 'framer-motion';
import PlayerList from './playerList';
import { Person } from '@mui/icons-material';
import TextToSpeech from './texttoSpeech';
import b1Sound from '../assets/bingosound/b1.mp3';

import b2Sound from '../assets/bingosound/b2.mp3';
import b3Sound from '../assets/bingosound/b3.mp3';
import b4Sound from '../assets/bingosound/b4.mp3';
import b5Sound from '../assets/bingosound/b5.mp3';
import b6Sound from '../assets/bingosound/b6.mp3';
import b7Sound from '../assets/bingosound/b7.mp3';
import b8Sound from '../assets/bingosound/b8.mp3';
import b9Sound from '../assets/bingosound/b9.mp3';
import b10Sound from '../assets/bingosound/b10.mp3';
import b11Sound from '../assets/bingosound/b11.mp3';
import b12Sound from '../assets/bingosound/b12.mp3';
import b13Sound from '../assets/bingosound/b13.mp3';
import b14Sound from '../assets/bingosound/b14.mp3';
import b15Sound from '../assets/bingosound/b15.mp3';
import b16Sound from '../assets/bingosound/b16.mp3';
import b17Sound from '../assets/bingosound/b17.mp3';
import b18Sound from '../assets/bingosound/b18.mp3';
import b19Sound from '../assets/bingosound/b19.mp3';
import b20Sound from '../assets/bingosound/b20.mp3';
import b21Sound from '../assets/bingosound/b21.mp3';
import b22Sound from '../assets/bingosound/b22.mp3';
import b23Sound from '../assets/bingosound/b23.mp3';
import b24Sound from '../assets/bingosound/b24.mp3';
import b25Sound from '../assets/bingosound/b25.mp3';
import b26Sound from '../assets/bingosound/b26.mp3';
import b27Sound from '../assets/bingosound/b27.mp3';
import b28Sound from '../assets/bingosound/b28.mp3';
import b29Sound from '../assets/bingosound/b29.mp3';
import b30Sound from '../assets/bingosound/b30.mp3';
import b31Sound from '../assets/bingosound/b31.mp3';
import b32Sound from '../assets/bingosound/b32.mp3';
import b33Sound from '../assets/bingosound/b33.mp3';
import b34Sound from '../assets/bingosound/b34.mp3';
import b35Sound from '../assets/bingosound/b35.mp3';
import b36Sound from '../assets/bingosound/b36.mp3';
import b37Sound from '../assets/bingosound/b37.mp3';
import b38Sound from '../assets/bingosound/b38.mp3';
import b39Sound from '../assets/bingosound/b39.mp3';
import b40Sound from '../assets/bingosound/b40.mp3';
import b41Sound from '../assets/bingosound/b41.mp3';
import b42Sound from '../assets/bingosound/b42.mp3';
import b43Sound from '../assets/bingosound/b43.mp3';
import b44Sound from '../assets/bingosound/b44.mp3';
import b45Sound from '../assets/bingosound/b45.mp3';
import b46Sound from '../assets/bingosound/b46.mp3';
import b47Sound from '../assets/bingosound/b47.mp3';
import b48Sound from '../assets/bingosound/b48.mp3';
import b49Sound from '../assets/bingosound/b49.mp3';
import b50Sound from '../assets/bingosound/b50.mp3';
import b51Sound from '../assets/bingosound/b51.mp3';
import b52Sound from '../assets/bingosound/b52.mp3';
import b54Sound from '../assets/bingosound/b54.mp3';
import b55Sound from '../assets/bingosound/b55.mp3';
import b56Sound from '../assets/bingosound/b56.mp3';
import b57Sound from '../assets/bingosound/b57.mp3';
import b58Sound from '../assets/bingosound/b58.mp3';
import b59Sound from '../assets/bingosound/b59.mp3';
import b60Sound from '../assets/bingosound/b60.mp3';
import b61Sound from '../assets/bingosound/b61.mp3';
import b62Sound from '../assets/bingosound/b62.mp3';
import b63Sound from '../assets/bingosound/b63.mp3';
import b64Sound from '../assets/bingosound/b64.mp3';
import b65Sound from '../assets/bingosound/b65.mp3';
import b66Sound from '../assets/bingosound/b66.mp3';
import b67Sound from '../assets/bingosound/b67.mp3';
import b68Sound from '../assets/bingosound/b68.mp3';
import b69Sound from '../assets/bingosound/b69.mp3';
import b70Sound from '../assets/bingosound/b70.mp3';
import b71Sound from '../assets/bingosound/b71.mp3';
import b72Sound from '../assets/bingosound/b72.mp3';
import b73Sound from '../assets/bingosound/b73.mp3';
import b74Sound from '../assets/bingosound/b74.mp3';
import b75Sound from '../assets/bingosound/b75.mp3';
import b53Sound from '../assets/bingosound/b53.mp3';
import bingo from './bingo.mp3'
import stopBingo from './stop.mp3'
import shuf from './shuffle.mp3'
import { Howl, Howler } from 'howler';
import useWebAudioPlayer from './player'; // Adjust the path as needed
import { playAudio } from './playAudio'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';

const sizeAnimation = keyframes`
from {
  font-size: 80px;
}
to {
  font-size: 100px;
}
`;

const blink = keyframes`
50% {
  opacity: 0;
}
`;

const BeautifulButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg,  #FFEB68 40%, #74710c 90%)',
  border: '1px solid #FF8E53',
  borderRadius: '50px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: '110px',
  width: '110px',
  padding: '0 30px',
  fontSize: '18px',
  fontWeight: 'bold',
  textTransform: 'none',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    boxShadow: '0 6px 10px 4px rgba(255, 105, 135, .3)',
  },
}));
const speakLastCalledNumber = (number) => {
  let text;
  if (number >= 1 && number <= 15) {
      text = `B${number}`;
  } else if (number >= 16 && number <= 30) {
      text = `I${number}`;
  } else if (number >= 31 && number <= 45) {
      text = `N${number}`;
  } else if (number >= 46 && number <= 60) {
      text = `G${number}`;
  } else {
      text = `O${number}`;
  }
  // Pass the text to the TextToSpeech component
  return <TextToSpeech text={text} />;
};
const audioFiles = [
  b1Sound, b2Sound, b3Sound, b4Sound, b5Sound, b6Sound, b7Sound, b8Sound, b9Sound, b10Sound,
  b11Sound, b12Sound, b13Sound, b14Sound, b15Sound, b16Sound, b17Sound, b18Sound, b19Sound, b20Sound,
  b21Sound, b22Sound, b23Sound, b24Sound, b25Sound, b26Sound, b27Sound, b28Sound, b29Sound, b30Sound,
  b31Sound, b32Sound, b33Sound, b34Sound, b35Sound, b36Sound, b37Sound, b38Sound, b39Sound, b40Sound,
  b41Sound, b42Sound, b43Sound, b44Sound, b45Sound, b46Sound, b47Sound, b48Sound, b49Sound, b50Sound,
  b51Sound, b52Sound, b53Sound, b54Sound, b55Sound, b56Sound, b57Sound, b58Sound, b59Sound, b60Sound,
  b61Sound, b62Sound, b63Sound, b64Sound, b65Sound, b66Sound, b67Sound, b68Sound, b69Sound, b70Sound, b71Sound, b72Sound,
b73Sound, b74Sound, b75Sound, stopBingo,shuf,bingo
];

const howls = audioFiles.map(sound => new Howl({ src: [sound] }));

// Variable to keep track of the currently playing sound
let currentPlayingSound = null;

// Function to play audio by index


const LastCalledNumberContainer = styled(Stack)({
  top: 0,
  right: 10,
  borderRadius: 100,
  width: '200px',
  background: "linear-gradient(to bottom, #f6b129 35%, #e4840c 75%)",
  animation: 'drop 1.5s ease forwards', // Animation to drop from top
  '@keyframes drop': {
    '0%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
});

const LastCalledNumbersContainer = styled(Stack)({
  top: 'calc(50% + 100px)', // Adjust the distance from the main circle
  left: '50%',
  transform: 'translateX(-50%)',
  flexDirection: 'row',
  justifyContent: 'center',
});

const LastCalledNumberItem = styled(Stack)({
  width: '60px',
  height: '50px',
  borderRadius: '50%',
  background: 'linear-gradient(to bottom, #f6b129 35%, #e4840c 75%)',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 10px',
});

const StyledButton = styled(Button)(({ theme, called }) => ({
  // Custom styles based on props...
}));

const BingoNumbersContainer = styled(Stack)(({ isSmallScreen }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: 0,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#0b0c10',
    border: '2px solid #45a29e',
    borderRadius: '8px',
    color: '#66fcf1',
    boxShadow: '0 0 15px #45a29e',
    backgroundImage: 'linear-gradient(to bottom right, #0b0c10, #1f2833)',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      border: '2px solid #45a29e',
      zIndex: -1,
    },
    '&::before': {
      transform: 'translate(4px, 4px)',
    },
    '&::after': {
      transform: 'translate(-4px, -4px)',
    },
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: '#1f2833',
    borderBottom: '1px solid #45a29e',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    borderTop: '1px solid #45a29e',
  },
  '& .MuiButton-root': {
    color: '#66fcf1',
    border: '1px solid #45a29e',
    '&:hover': {
      backgroundColor: '#1f2833',
    },
  },
}));


const LastCalledNumber = ({ number }) => {
  useEffect(() => {
    if (number !== null) {
      const index = number - 1;
      playAudio(index); // Play the corresponding audio
    }
  }, [number]);

  function getLetter(number) {
    if (number >= 1 && number <= 15) {
      return 'B';
    } else if (number >= 16 && number <= 30) {
      return 'I';
    } else if (number >= 31 && number <= 45) {
      return 'N';
    } else if (number >= 46 && number <= 60) {
      return 'G';
    } else {
      return 'O';
    }
  }

  return (
    <LastCalledNumberContainer key={number}>
      <Stack
        justifyContent="center"
        alignItems="center"
        width="200px"
        height="200px"
        borderRadius="50%"
        bgcolor="linear-gradient(to bottom, #fff521 35%, #9d9302 75%)"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
      >
        <Typography alignSelf="center" variant="h2" color="black" fontWeight="bold">
          {getLetter(number)}
          <br />
          {number}
        </Typography>
      </Stack>
    </LastCalledNumberContainer>
  );
};

const LastCalledNumbers = ({ lastCalledNumbers }) => {
  return (
    <LastCalledNumbersContainer>
      {lastCalledNumbers.map((number, index) => (
        <LastCalledNumberItem key={index}>
          <Stack>
            <Typography variant="h6" color="black" fontWeight="bold">
              {number == 0 ? null : number}
            </Typography>
          </Stack>
        </LastCalledNumberItem>
      ))}
    </LastCalledNumbersContainer>
  );
};

const BingoNumbers = React.memo(({ numbers, calledNumbers, lastCalledNumber }) => {
  const [isWhite, setIsWhite] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsWhite(prevState => !prevState);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <BingoNumbersContainer isSmallScreen={isSmallScreen}>
      {numbers.map((row, rowIndex) => (
        <Stack key={rowIndex} direction={'row'} marginTop={0}>
          <CustomBingoText>{String.fromCharCode(66 + rowIndex)}</CustomBingoText>
          <Stack direction={'row'}>
            {row.map((number, colIndex) => {
              const category = number <= 15 ? 'b' : number <= 30 ? 'i' : number <= 45 ? 'n' : number <= 60 ? 'g' : 'o';
              const isCalled = calledNumbers[category].includes(number);
              const formattedNumber = number < 10 ? `0${number}` : number;
              const isLastCalled = lastCalledNumber === number;

              return (
                <StyledButton
                  key={number}
                  called={isCalled}
                  className="bingo-number-button"
                  sx={{ fontSize: '0.1em' }}
                  style={{
                    color: isCalled ? 'white' : '#3c3c3c',
                    background: isLastCalled ? (isWhite ? 'white' : '#3c3c3c') : 'none',
                    border: 'none',
                    animation: isLastCalled ? `${blink} 1s infinite` : 'none',
                  }}
                >
                  <Typography fontWeight={isCalled ? 'bold' : 'normal'}>{formattedNumber}</Typography>
                </StyledButton>
              );
            })}
          </Stack>
        </Stack>
      ))}
    </BingoNumbersContainer>
  );
});
const getWinnerName = async (uid) => {
  if (!uid) {
    return null;  // Return or handle the error as necessary
  }
  const db = getFirestore();
  const userRef = doc(db, "users", uid);
  try {
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      return docSnap.data().userName;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return null;
  }
};
const saveWinner = async (winnerName) => {
  try {
    const db = getFirestore();

    const winnersRef = collection(db, 'winners');
    const result = await addDoc(winnersRef, { name: winnerName, date: new Date() });
    console.log('Document written with ID: ', result.id);
  } catch (error) {
    console.error('Error adding document: ', error);
  }
};
const WinnerModal = ({ winnerName, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.5 }}
      >
        <DialogTitle>Game Over!</DialogTitle>
        <DialogContent>
          <Typography variant="h6">{`${winnerName} has won the game!`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </motion.div>
    </Dialog>
  );
};
const MainBingoBoard = () => {
  const [calledNumbers, setCalledNumbers] = useState({
    b: [],
    i: [],
    n: ['free'],
    g: [],
    o: []
  });
  const [lastCalledNumber, setLastCalledNumber] = useState(null);
  const [lastThreeCalledNumbers, setLastThreeCalledNumbers] = useState([]);
  const [autoCall, setAutoCall] = useState(true);
  const [intervalSeconds, setIntervalSeconds] = useState(3);
  const [latestSession, setLatestSession] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showUsers, setUsers] = useState(false);
  const [playerNames, setPlayerNames] = useState([]); // State to store player names
  const [userWasOnPage, setUserWasOnPage] = useState(false);
  const navigate = useNavigate();
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [betPlaced, setBetPlaced] = useState(localStorage.getItem('betPlaced') === 'true'); // New state for bet placement
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [isPlayerInSession, setIsPlayerInSession] = useState(false); // New state to check if player is in session
  const [open, setOpen] = React.useState(false);
  const [gameSessionNumber, setGameSessionNumber] = useState(null);
  const functions = getFunctions();
  const database = getDatabase();
  const [winnerName, setWinnerName] = useState("");
  const [showWinnerModal, setShowWinnerModal] = useState(false);
  const db = getFirestore();
  const { stake } = useParams();
  const [isPlacingBet, setIsPlacingBet] = useState(false); // New state for loading

  const [currentGame, setCurrentGame] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const db = getDatabase();
    const gameSessionNumberRef = ref(db, 'BingoGames/currentGame');

    const unsubscribe = onValue(gameSessionNumberRef, (snapshot) => {
      const data = snapshot.val();
      setCurrentGame(data);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const database = getDatabase();
    const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');

    get(gameSessionNumberRef).then(snapshot => {
      const gameSessionNumber = snapshot.val();
      const winnerRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/winner`);

      const unsubscribe = onValue(winnerRef, async (snapshot) => {
        const uid = snapshot.val();
        const userName = await getWinnerName(uid);
        if (userName) {
          setWinnerName(userName);
          setShowWinnerModal(true); // Trigger modal on name fetch
        }
      });

      return () => unsubscribe();
    });
  }, []);

  useEffect(() => {
    const fetchAndIncrementGameSessionNumber = async () => {
      const getAndIncrementGameSessionNumber = httpsCallable(functions, 'getAndIncrementGameSessionNumber');
      try {
        const result = await getAndIncrementGameSessionNumber();
        setGameSessionNumber(`Game${result.data.currentGameSessionNumber}`);
      } catch (error) {
        console.error('Error getting and incrementing game session number:', error);
      }
    };

    fetchAndIncrementGameSessionNumber();
  }, [functions]);

  useEffect(() => {
    if (gameSessionNumber) {
      const initializeGameSession = httpsCallable(functions, 'initializeGameSession');
      const uid = localStorage.getItem('uid');

      initializeGameSession({ gameSessionNumber, uid })
        .then((result) => {
          const { calledNumbers, lastCalledNumber, countdown } = result.data;
          setCalledNumbers(calledNumbers);
          setLastCalledNumber(lastCalledNumber);
          setRemainingTime(countdown);
        })
        .catch((error) => {
          console.error('Error initializing game session:', error);
        });
    }
  }, [gameSessionNumber, functions]);
  const handleClickOpen = () => {
    setOpen(true);
    fetchPlayerNames(); // Fetch player names when dialog is opened
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLoginClick = () => {
    setLoginDialogOpen(true);
  };

  const handleLoginDialogClose = () => {
    setLoginDialogOpen(false);
  };

  const handleAutoCall = () => {
    setAutoCall((prevAutoCall) => !prevAutoCall);
  };

  const { number } = useParams();
  console.log(stake);

  console.log(number);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    const database = getDatabase();

    const uid = localStorage.getItem('uid');

    const intervalId = setInterval(async () => {
      try {
        const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
        const snapshot2 = await get(gameSessionNumberRef);
        const currentGameSessionNumber = snapshot2.val();
        const gameSessionRef = ref(database, `BingoGames/Game${currentGameSessionNumber}/gameType${stake}/players`);

        const snapshot = await get(gameSessionRef);
        console.log(snapshot.val());
        console.log('fuckersss');

        if (snapshot.exists()) {
          const gameSessionData = snapshot.val();
          const uids = Object.values(gameSessionData);
          setIsPlayerInSession(uids.includes(uid));
        } else {
          setIsPlayerInSession(false);
        }
      } catch (error) {
        console.error('Error checking if player is in session:', error);
        setIsPlayerInSession(false);
      }
    }, 2000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const displayTime = formatTime(remainingTime);

  useEffect(() => {
    const database = getDatabase();
    const calledNumbersRef = ref(database, 'lastGameSession/number');

    const handleNewNumber = (snapshot) => {
      const newNumber = snapshot.val();

      // Determine the category based on the range of the new number
      let category;
      if (newNumber >= 1 && newNumber <= 15) {
        category = 'b';
      } else if (newNumber >= 16 && newNumber <= 30) {
        category = 'i';
      } else if (newNumber >= 31 && newNumber <= 45) {
        category = 'n';
      } else if (newNumber >= 46 && newNumber <= 60) {
        category = 'g';
      } else if (newNumber >= 61 && newNumber <= 75) {
        category = 'o';
      }
      setLastCalledNumber(newNumber);
      if (newNumber == 0 || showPopup == true) {
        if (userWasOnPage) {
          setShowPopup(true);
        }
        setCalledNumbers({
          b: [],
          i: [],
          n: [],
          g: [],
          o: []
        });
      }
      // Update the calledNumbers state with the new number
      setCalledNumbers(prevCalledNumbers => ({
        ...prevCalledNumbers,
        [category]: [...(prevCalledNumbers[category] || []), newNumber]
      }));
      setLastThreeCalledNumbers(prevCalledNumber => {
        const updatedNumbers = [newNumber, ...prevCalledNumber?.slice(0, 3)];
        return updatedNumbers;
      });
      setLastCalledNumber(newNumber);
      setBetPlaced(false);
    };

    const unsubscribe = onValue(calledNumbersRef, handleNewNumber, (error) => {
      console.error("Error fetching data:", error);
    });

    return () => unsubscribe();
  }, [latestSession, userWasOnPage]);

  useEffect(() => {
    setUserWasOnPage(true);
  }, []);

  const handleReplay = () => {
    setShowPopup(false);
  };

  const handleGoBack = () => {
    window.location.href = '/';
  };

  const numbers = React.useMemo(() => {
    const numbers = [];
    for (let i = 0; i < 5; i++) {
      numbers.push(Array.from({ length: 15 }, (_, index) => index + i * 15 + 1));
    }
    return numbers;
  }, []);

  useEffect(() => {
    const fetchTotalPlayers = async () => {
      try {
        const database = getDatabase();
        const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
        const snapshot1 = await get(gameSessionNumberRef);
        const gameSessionNumber = snapshot1.val();
        const gameSessionRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/players`);

        const snapshot = await get(gameSessionRef);
        if (snapshot.exists()) {
          const gameSessionData = snapshot.val();
          const playersCount = Object.keys(gameSessionData).length;
          setTotalPlayers(playersCount);
        } else {
          setTotalPlayers(0);
        }
      } catch (error) {
        console.error('Error fetching total players:', error);
      }
    };

    fetchTotalPlayers();
    const intervalId = setInterval(fetchTotalPlayers, 1000);

    return () => clearInterval(intervalId);
  }, [gameSessionNumber]);

  useEffect(() => {
    const database = getDatabase();
    const gameSessionsRef = ref(database, 'gameSessions');

    const fetchGameSessions = async () => {
      try {
        const snapshot = await get(gameSessionsRef);
        if (snapshot.exists()) {
          const gameSessions = snapshot.val();
          const totalSessions = Object.keys(gameSessions).length;
          setGameSessionNumber(totalSessions);

          const gameSessionRef = ref(database, `gameSessions/Game${totalSessions}`);
          onValue(gameSessionRef, (snapshot) => {
            if (snapshot.exists()) {
              const gameSessionData = snapshot.val();
              const playersCount = Object.keys(gameSessionData).length;
              setTotalPlayers(playersCount);
            } else {
              setTotalPlayers(0);
            }
          });
        }
      } catch (error) {
        console.error('Error fetching game sessions:', error);
      }
    };

    fetchGameSessions();
  }, []); // Make sure to specify any dependencies if needed

  const handlePlaceBet = async () => {
    const uid = localStorage.getItem('uid');
  
    if (!uid) {
      handleLoginClick(); // Prompt user to log in if not already logged in
      return;
    }
  
    try {
      setIsPlacingBet(true); // Start loading

      const userRef = doc(getFirestore(), 'users', uid);
      const userSnap = await getDoc(userRef);
  
      if (!userSnap.exists()) {
        console.error('User does not exist!');
        return; // Exit if user does not exist
      }
  
      const userData = userSnap.data();
      const walletBalance = userData.wallet || 0;
      const betAmount = 10; // Define the bet amount, adjust as necessary
  
      if (walletBalance < stake) {
        console.error('Insufficient funds');
        alert('Insufficient funds to place bet.');
        return; // Exit if not enough funds
      }
  
      const newWalletBalance = walletBalance - stake;
  
      // Update wallet balance in Firestore
      await updateDoc(userRef, {
        wallet: newWalletBalance
      });
  
      // Proceed to register the bet in the game session
      const database = getDatabase();
      const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
      const snapshot = await get(gameSessionNumberRef);
      const gameSessionNumber = snapshot.val();
      const gameSessionRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/players`);

      // Create a reference to the specific game session
      const gameStake = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/Stake`);
      const housePercent = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/housePercent`);
      // Push the UID under the game session
      await push(gameSessionRef, uid); 
    // Update house percentage
      const snapshot1 = await get(gameSessionRef);
      if (snapshot1.exists()) {
        const gameSessionData = snapshot1.val();
        const playersCount = Object.keys(gameSessionData).length;
        console.log(playersCount);
        await set(gameStake, playersCount * stake - playersCount * stake * 0.2); // Update game stake
        await set(housePercent, playersCount * stake * 0.2);
      }
      // Indicate that the bet has been placed
      setBetPlaced(true); 
      localStorage.setItem('betPlaced', 'true'); 
  
      console.log('Bet placed successfully for Game', gameSessionNumber, 'New wallet balance:', newWalletBalance);
    } catch (error) {
      console.error('Error placing bet:', error);
      // Handle any errors here (e.g., show error message to the user)
    } finally {
      setIsPlacingBet(false); // End loading- 
    }
  };
  
  

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(calledNumbers);
  const [cards, setCards] = useState([
    // Sample cards data, replace with actual data
    { cardname: 'card1', b: [5, 10, 15, 20, 25], i: [30, 35, 40, 45, 50], n: [55, 'free', 65, 70, 75], g: [80, 85, 90, 95, 100], o: [105, 110, 115, 120, 125] },
    { cardname: 'card2', b: [1, 2, 3, 4, 5], i: [6, 7, 8, 9, 10], n: [11, 'free', 13, 14, 15], g: [16, 17, 18, 19, 20], o: [21, 22, 23, 24, 25] }
  ]);
  const [inputNumber, setInputNumber] = useState('');
  const [secondDialogOpen, setSecondDialogOpen] = useState(false);

  const unlockAllNumbers = () => {
    // Function to unlock all numbers
  };

  const handleStopBroadcast = async () => {
    try {
      // Get a reference to the Firebase Realtime Database
      const response = await fetch('https://us-central1-onlinebingo-7c139.cloudfunctions.net/stopBroadcast', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const database = getDatabase();
      const stake = Number(localStorage.getItem("stake") || 10); // Assuming stake is stored in localStorage or set elsewhere
  
      // Fetch the current game session number
      const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
      const snapshot = await get(gameSessionNumberRef);
      const gameSessionNumber = snapshot.val();
  
      // Reference to the winner UID
      const winnerRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/winner`);
  
      // Check if a winner UID is already set
      const winnerSnapshot = await get(winnerRef);
      if (winnerSnapshot.exists() && winnerSnapshot.val()) {
        console.log("A winner has already been set.");
        alert("A winner has already been declared. You cannot declare another winner.");
        return; // Exit the function if a winner is already set
      }
  
      // Get UID from local storage or another state management store
      const uid = localStorage.getItem('uid');
  
      // Set the UID of the user as the winner
      await set(winnerRef, uid);
      console.log("Winner set successfully:", winnerRef);
  
      // Get a reference to the Firebase Firestore
      const functions = getFunctions();
    
      // Call the Cloud Function to update the wallet
      const updateWinnerWallet = httpsCallable(functions, "updateWinnerWallet");
      const result = await updateWinnerWallet({ uid, stake, totalPlayers });
      const db = getFirestore();
    const userDocRef = doc(db, 'users', uid);
    const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        const winnerName = userDocSnap.data().userName;
  
        // Save the winner's name in a new Firestore collection 'winners'
        const winnersRef = collection(db, 'winners');
        await addDoc(winnersRef, { 
          name: winnerName, 
          date: new Date(), 
          stake: totalPlayers==1?(stake * totalPlayers):(stake * totalPlayers - 0.2 * (stake * totalPlayers)), 
          GameNumber: gameSessionNumber 
        });
  
        console.log('Winner saved successfully in Firestore:', winnerName);
      } else {
        console.error('User document does not exist.');
      }
      if (result.data.success) {
        console.log("Wallet updated successfully.", result.data.newBalance);
      }
    
      if (response.ok) {
        console.log('Broadcasting stopped successfully.');
      } else {
        console.error('Failed to stop broadcasting.');
      }
    } catch (error) {
      console.error('Error during the stop broadcast process:', error);
    }
  };
  


  console.log(localStorage.getItem('bingo'));

  const handleButtonClick = () => {
    handleStopBroadcast();
    handleDeclareWinner();
  };
  const handleDeclareWinner = async () => {
    try {
      const database = getDatabase();
      const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
      const snapshot = await get(gameSessionNumberRef);
      const gameSessionNumber = snapshot.val();
  
      // Reference to get the UID of the winner
      const winnerRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/winner`);
      const winnerUid = winnerSnapshot.val();
      const winnerSnapshot = await get(winnerRef);
      if (winnerSnapshot.exists() && winnerSnapshot.val()) {
        console.log("A winner has already been set.");
        alert("A winner has already been declared. You cannot declare another winner.");
        return; // Exit the function if a winner is already set
      }
      // Now get the winner's name from Firestore
      const db = getFirestore();
      const userDocRef = doc(db, 'users', winnerUid);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        const winnerName = userDocSnap.data().userName;
  
        // Save the winner's name in a new Firestore collection
        const winnersRef = collection(db, 'winners');
        await addDoc(winnersRef, { name: winnerName, date: new Date(), stake: (stake * totalPlayers - 0.2 * (stake * totalPlayers)), GameNumber: gameSessionNumber });
  
        console.log('Winner saved successfully:', winnerName);
      } else {
        console.error('User document does not exist.');
      }
    } catch (error) {
      console.error('Error declaring winner:', error);
    }
  };

  // Function to fetch player names from Firestore using UIDs
  const fetchPlayerNames = async () => {
    try {
      const db = getFirestore();
      const database = getDatabase();
      const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');

      const snapshot1 = await get(gameSessionNumberRef);
      const gameSessionNumber = snapshot1.val();
      const gameSessionRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/players`);
      const snapshot = await get(gameSessionRef);
      if (snapshot.exists()) {
        const gameSessionData = snapshot.val();
        const uids = Object.values(gameSessionData);

        const namePromises = uids.map(async (uid) => {
          const userDoc = await getDoc(doc(db, 'users', uid));
          return userDoc.exists() ? userDoc.data().userName : 'Unknown';
        });

        const names = await Promise.all(namePromises);
        setPlayerNames(names); // Set the fetched names to the state
      } else {
        setPlayerNames([]);
      }
    } catch (error) {
      console.error('Error fetching player names:', error);
    }
  };
  const [isBettingOpen, setIsBettingOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBettingStatus = async () => {
      try {
        const response = await axios.get('https://us-central1-onlinebingo-7c139.cloudfunctions.net/isBettingOpen');
        setIsBettingOpen(response.data.isBettingOpen);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    console.log(isBettingOpen);
    fetchBettingStatus();
  }, []);
  console.log(isBettingOpen);
  useEffect(() => {
    const countdownRef = ref(database, 'countdown/seconds');

    const unsubscribe = onValue(countdownRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        setRemainingTime(data); // Update state with the new seconds value
        if (data === 50) {
          // Refresh the page when countdown hits 50 seconds
          window.location.reload();
        }
      }
    });

    return () => unsubscribe();
  }, [database]);
  return (
    <OrbitSpace>
      <Box>
        <ResponsiveAppBar />
       
        <Box sx={{ background: 'linear-gradient(200deg, #B344FD  50%, #7c4efe 90%)' }}>
          <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
          
            <Typography color={'white'} >{t("Prize")}: {(stake * totalPlayers) ?? 0} {t("ETB")}</Typography>
            <Typography color={'white'} >{t("Game")} #{currentGame}</Typography>
        
            <Typography color={'white'}> {t("Cartela")}: {number}</Typography>
          
              <Button sx={{ height:'10px', color: 'white', paddingTop:1.5 }} onClick={handleClickOpen}><Person />:<Typography variant='h6'>{totalPlayers}</Typography> </Button>
           
        
           
            
          </Stack>
        </Box>
        <BingoNumber numbers={numbers} calledNumbers={calledNumbers} />
        <Stack direction={isSmallScreen ? 'column' : 'row'}>
          <Stack direction={'row'} spacing={4}>
            <Stack>
              <Box paddingLeft={isSmallScreen ? '0' : '440px'} />
              {!isPlayerInSession || lastCalledNumber == 0 ? (
                remainingTime > 0 ? (
                  <>
                  <Typography variant="h6" color={'white'}>{t("Next Game")}  {displayTime}</Typography>\
                  <Box width={'90%'}>
                  <Typography  color={'grey'}>{t("Note: Bet placing is allowed after 00:50 Minute")} . </Typography></Box></>
                ) : (
                  <CircularProgress />
                )
              ) : lastCalledNumber !== null && <LastCalledNumber number={lastCalledNumber} />}
            </Stack>
            <Box paddingTop={1.8}>
              {!isPlayerInSession || lastCalledNumber == 0 ? (
                <BeautifulButton onClick={handlePlaceBet} disabled={remainingTime > 50 || remainingTime <= 0 || betPlaced || isPlacingBet}>
                  {isPlacingBet ? <CircularProgress size={24} /> : betPlaced ? 'Bet Placed' : t("Place Bet")}
                </BeautifulButton>
              ) : (
                localStorage.getItem('bingo') === 'true' && isPlayerInSession ? (
                  <BeautifulButton onClick={handleButtonClick}>Bingo</BeautifulButton>
                ) : (
                  <BeautifulButton>Bingo</BeautifulButton>
                )
              )}
            </Box>
          </Stack>
          <Box paddingLeft={15} justifyContent={'center'}>
            <LastCalledNumbers lastCalledNumbers={lastThreeCalledNumbers} />
          </Box>
          <CustomizedDialogs
            calledNumbers={calledNumbers}
            cards={cards}
            inputNumber={number}
            setInputNumber={setInputNumber}
            secondDialogOpen={secondDialogOpen}
            setSecondDialogOpen={setSecondDialogOpen}
            unlockAllNumbers={unlockAllNumbers}
          />
        </Stack>

        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogContent dividers>
            {playerNames.map((name, index) => (
              <Typography key={index} gutterBottom>
                {name}
              </Typography>
            ))}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <LoginDialog open={loginDialogOpen} onClose={handleLoginDialogClose} />
        <WinnerModal winnerName={winnerName} isOpen={showWinnerModal} onClose={() => setShowWinnerModal(false)} />
        <PlayerList />
      </Box>
    </OrbitSpace>
  );
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export default MainBingoBoard;
