import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Typography, Button, TextField, CircularProgress, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { app } from './firebase-config'; // Your Firebase configuration
import SignUpDialog from './login';
const db = getFirestore(app);

// Styled components
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  color: 'black',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'red',
  color: '#fff',
  marginTop: theme.spacing(2),
  '&:hover': {
    backgroundColor: '#115293',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#1976d2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#1976d2',
    },
    '&:hover fieldset': {
      borderColor: '#1976d2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#1976d2',
    },
  },
}));

const LoginDialog = ({ open, onClose }) => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false);
  const [signUpDialogOpen, setSignUpDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenSignUpDialog = () => {
    setSignUpDialogOpen(true);
  };
  const handleOpenDialog = () => {
      setDialogOpen(true);
  };
  const handleSignUpClose = () => {
    setSignUpDialogOpen(false); // Make sure this correctly updates the state
};
  const handleLogin = async () => {
    setLoading(true);
    setError("");
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("phone", "==", phone), where("password", "==", password));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError("Invalid phone number or password");
      } else {
        const userData = querySnapshot.docs[0].data();
        const uid = querySnapshot.docs[0].id;
        localStorage.setItem('uid', uid);
        localStorage.setItem('username', userData.userName);
        localStorage.setItem('phone', userData.phone);
        console.log("User authenticated successfully");

        window.dispatchEvent(new CustomEvent('authChange', { detail: { uid, username: userData.userName } }));

        onClose();
        console.log(localStorage.getItem('uid'));
        window.location.reload(); // Add this line to reload the page

      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Error logging in. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenForgotPasswordDialog = () => {
    setForgotPasswordDialogOpen(true);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{ color: 'black' }}>
        <DialogTitle sx={{ background: "white" }}>
          <StyledTypography>Login</StyledTypography>
        </DialogTitle>
        <DialogContent dividers sx={{ background: "white" }}>
          <Stack spacing={2} alignItems={'center'}>
            <StyledTextField
              autoFocus
              margin="dense"
              id="phone"
              label="Phone Number"
              type="text"
              fullWidth
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              sx={{ background: 'white' }}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Typography color="error">{error}</Typography>
            )}
            <Stack direction={'row'} justifyContent={'end'} width={'100%'}>
              <Box />
              <Typography color={'grey'} onClick={handleOpenForgotPasswordDialog} style={{ cursor: 'pointer' }}>
                Forgot password
              </Typography>
            </Stack>
            <Button onClick={handleOpenSignUpDialog}>
            <Typography>Don't have an account? Sign Up</Typography></Button>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-evenly', background: "white" }}>
          <StyledButton onClick={handleLogin} variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Login"}
          </StyledButton>
          <StyledButton variant="contained" onClick={onClose} color="secondary">
            Cancel
          </StyledButton>
        </DialogActions>
      </Dialog>
      <ForgotPasswordDialog open={forgotPasswordDialogOpen} onClose={() => setForgotPasswordDialogOpen(false)} />
      <SignUpDialog open={signUpDialogOpen} onClose={() => setSignUpDialogOpen(false)} />

    </>
  );
};

const ForgotPasswordDialog = ({ open, onClose }) => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const sendOtp = async () => {
    setLoading(true);
    setError("");
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("phone", "==", phone));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError("Phone number not found");
      } else {
        const otp = Math.floor(1000 + Math.random() * 9000);
        setGeneratedOtp(otp);
        setIsOtpSent(true);

        await fetch('https://sms.yegara.com/api3/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: '37229',
            to: phone,
            otp: otp,
            domain: 'ya-bingo.com',
          }),
          mode: 'no-cors'
        });
      }
    } catch (error) {
      console.error("Failed to send OTP:", error);
      setError("Failed to send OTP. Please try again.");
      setIsOtpSent(false);
    } finally {
      setLoading(false);
    }
  };

  const verifyOtpAndResetPassword = async () => {
    if (parseInt(otp, 10) === generatedOtp) {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where("phone", "==", phone));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDocRef = doc(db, 'users', userDoc.id);
          await updateDoc(userDocRef, { password: newPassword });

          alert("Password reset successfully");
          onClose();
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        setError("Failed to reset password. Please try again.");
      }
    } else {
      setError("Invalid OTP");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          autoFocus
          margin="dense"
          id="phone"
          label="Phone Number"
          type="text"
          fullWidth
          variant="standard"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Button onClick={sendOtp} disabled={!phone || isOtpSent || loading}>
          Send OTP
        </Button>
        {loading && <CircularProgress size={24} />}
        {isOtpSent && (
          <>
            <TextField
              margin="dense"
              id="otp"
              label="Enter OTP"
              type="text"
              fullWidth
              variant="standard"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <TextField
              margin="dense"
              id="newPassword"
              label="New Password"
              type="password"
              fullWidth
              variant="standard"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={verifyOtpAndResetPassword} disabled={!otp || !newPassword}>
          Reset Password
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
