import React from 'react';
import { Card, CardContent, Typography, Avatar, Box, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';


const CompaniesCard = () => {
  const { t, i18n } = useTranslation(); // Import the translation function

  const companies = [
    { name: 'kiya bingo', description: '+251 91 116 9749 ', logo: 'K' },
    { name: 'Ya Bingo', description: '+251 91 116 9749', logo: 'YA' },
    
  ];

  return (
    <Card
      sx={{
        background: 'linear-gradient(14deg, #1b105f 30%, #5948d5 90%)', // Purplish color
        color: '#fff',
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
      }}
    >
              <InputLabel sx={{ color:'white' }}>{t('Companies')}</InputLabel>

      <CardContent>
        {companies.map((company, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: index < companies.length - 1 ? 2 : 0,
            }}
          >
            <Avatar
              sx={{
                backgroundColor: '#6a0dad',
                width: 56,
                height: 56,
              }}
            >
              {company.logo}
            </Avatar>
            <Box sx={{ marginLeft: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {company.name}
              </Typography>
              <Typography variant="body2">{company.description}</Typography>
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default CompaniesCard;
