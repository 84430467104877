import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Alert, Typography } from '@mui/material';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { app } from '../firebase-config'; // Your Firebase configuration
import ResponsiveAppBar from './appbar';
import { updateDoc, doc } from 'firebase/firestore';
import PlayerList from './playerList';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

// Initialize Firestore database
const db = getFirestore(app);

const HouseBetSelection = () => {
    const [houseBet, setHouseBet] = useState('');
    const [houseBets, setHouseBets] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHouseBets = async () => {
            try {
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('houseBet', '!=', ''));
                const querySnapshot = await getDocs(q);
    
                const houseBetMap = new Map();
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.houseBet) {
                        houseBetMap.set(data.houseBet, data.phone); // Store the houseBet with corresponding phone
                    }
                });
    
                setHouseBets(Array.from(houseBetMap.entries())); // Convert map to array of entries
            } catch (error) {
                console.error("Error fetching house bets: ", error);
                setError('Failed to load house bets.');
            }
        };
    
        fetchHouseBets();
    }, []);
    

    useEffect(() => {
        const fetchUsers = async () => {
            if (houseBet) {
                try {
                    const usersRef = collection(db, 'users');
                    const q = query(usersRef, where('houseBet', '==', houseBet), where('userRole', '==', 'subSuperAgent'));
                    const querySnapshot = await getDocs(q);

                    const usersList = [];
                    querySnapshot.forEach((doc) => {
                        usersList.push({ id: doc.id, ...doc.data() });
                    });

                    setUsers(usersList);
                } catch (error) {
                    console.error("Error fetching users: ", error);
                    setError('Failed to load users.');
                }
            } else {
                setUsers([]); // Reset the users list if no house bet is selected
            }
        };

        fetchUsers();
    }, [houseBet]);

    const handleSave = async () => {
        if (houseBet && selectedUser) {
            try {
                const userDoc = doc(db, 'users', localStorage.getItem('uid'));
                const selectedUserData = users.find(user => user.id === selectedUser);
                const houseBetSubSuperAgent = `${selectedUserData?.userName}`;

                await updateDoc(userDoc, {
                    houseBet: houseBet,
                    houseBetSubSuperAgent: houseBetSubSuperAgent
                });
                console.log(houseBetSubSuperAgent);
                setSuccess('House bet and user selection saved successfully.');
                setError('');
            } catch (error) {
                console.error("Error updating document: ", error);
                setError('Failed to save house bet and user.');
                setSuccess('');
            }
        } else {
            setError('Please select a house bet and a user.');
            setSuccess('');
        }
    };

    return (
        <>
            <ResponsiveAppBar />
            <Box>           
                <Box height={"100vh"}>
               
                <FormControl fullWidth margin="dense">
                    <InputLabel id="houseBet-label">Select Agent</InputLabel>
                    <Select
                        labelId="houseBet-label"
                        id="houseBet"
                        value={houseBet}
                        onChange={(e) => setHouseBet(e.target.value)}
                    >
                        {houseBets.map(([bet, phone], index) => (
                            <MenuItem key={index} value={bet}>
                                {`${bet} (${phone})`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Conditionally render the second dropdown only if there are users */}
                {houseBet && users.length > 0 && (
                    <FormControl fullWidth margin="dense" sx={{ mt: 2 }}>
                        <InputLabel id="user-label">Select User</InputLabel>
                        <Select
                            labelId="user-label"
                            id="user"
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                        >
                            {users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {`${user.userName}(${user.phone})`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Box>
                <Typography p={4} color={'grey'}>* ከላይ በሚገኘዉ ሱቆችን በመምረጥ save አድርገዉ deposit or withdraw ማድረግ ይችላሉ (ለበለጠ መረጃ ለኤጀንቶቹ ከጎን ባለዉ ስልክ ደዉለዉ ያዉሯቸዉ)</Typography>
                <Typography p={4} color={'grey'}>* Note አንዴ save ካሉት በኋላ በየጊዜዉ ማድረግ አይጠበቅባችሁም</Typography>

                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
                </Box>
                <PlayerList/>
               
            </Box>
        </>
    );
};

export default HouseBetSelection;
