import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ResponsiveAppBar from './appbar';
import { CardContent, Card, Typography, Stack, Divider, Button, CircularProgress } from '@mui/material';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import { app } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DepositDialog from './deposit';
import WithdrawDialog from './withdraw';
import telebirr from './Telebirr.png';
import img2 from '../assets/logo.png';
import { toast } from 'react-toastify';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DepositFinal() {
  const [value, setValue] = useState(0);
  const [uid, setUid] = useState(localStorage.getItem('uid'));
  const db = getFirestore(app);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionID, setSessionID] = useState('');
  const navigate = useNavigate();
  const [depositDialogOpen, setDepositDialogOpen] = useState(false);
  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('251'); // Initialize with 251
  const [user, setUser] = useState({
    uid: localStorage.getItem('uid'),
    username: localStorage.getItem('username')
  });

  useEffect(() => {
    const storedUid = localStorage.getItem('uid');
    if (storedUid) {
      setUid(storedUid);
    }
  }, []);

  const generateNonce = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

  async function sendTransferRequest(sessionId, phoneNumber) {
    const url = "https://game.bingobet.net/arifpay/withdraw";
    const headers = {
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({
      sessionId: sessionId,
      phone: phoneNumber
    });
    const loadingToastId = toast.loading('Please wait while your request is being processed ');

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      toast.update(loadingToastId, {
        render: "Request processed successfully!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      const responseData = await response.json();
      console.log("Response Data:", responseData);
    } catch (error) {
      toast.error('Error While withdrawing')
      console.error("Error making transfer request:", error);
      throw error;
    }
  }

  const handleWithdrawalConfirm = async (amount) => {
    setIsLoading(true);

    // Fetch user's wallet balance
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    const walletBalance = userDoc.data().wallet;

    if (amount > walletBalance) {
      alert('Insufficient wallet balance');
      setIsLoading(false);
      return;
    }

    let paymentDetails = {
      userId: uid,
      successUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      cancelUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      errorUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      notifyUrl: "https://us-central1-onlinebingo-7c139.cloudfunctions.net/receiveDataWithdrawal",
      paymentMethods: ["TELEBIRR"],
      items: [{
        name: "Bingo Deposit",
        quantity: 1,
        price: amount,
      }],
      beneficiaries: [{
        accountNumber: "65480441",
        bank: "ABYSETAA",
        amount: amount
      }],
      phone: phoneNumber, // Use the phoneNumber state
      nonce: generateNonce(),
    };

    try {
      const response = await axios.post('https://gateway.arifpay.net/api/checkout/session', paymentDetails, {
        headers: {
          'x-arifpay-key': 'HaKMbhlGLyUNnkWWO56HpegT6HUM3iGL',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      const sessionId = response.data.data.sessionId;
      setSessionID(sessionId);

      if (uid) {
        await updateDoc(userRef, {
          sessionId: sessionId
        });
      }
      await sendTransferRequest(sessionId, phoneNumber); // Use the phoneNumber state
      setIsLoading(false);
      setWithdrawDialogOpen(false); // Close the dialog on success
    } catch (error) {
      console.error('Error initiating payment:', error);
      setIsLoading(false);
    }
  };

  const handleDepositConfirm = async (amount) => {
    setIsLoading(true);

    let paymentDetails = {
      successUrl: "https://ya-bingo.com/",
      cancelUrl: "https://ya-bingo.com/",
      errorUrl: "https://ya-bingo.com/",
      notifyUrl: "https://us-central1-onlinebingo-7c139.cloudfunctions.net/receiveData",
      paymentMethods: ["TELEBIRR_USSD"],
      items: [{
        name: "Bingo Deposit",
        quantity: 1,
        price: amount,
      }],
      beneficiaries: [{
        accountNumber: "65480441",
        bank: "ABYSETAA",
        amount: amount
      }],
      phone: phoneNumber, // Use the phoneNumber state
      nonce: generateNonce(),
    };
    const loadingToastId = toast.loading('Please wait while your request is being processed ');

    try {
      const response = await axios.post('https://gateway.arifpay.net/api/checkout/telebirr-ussd/transfer/direct', paymentDetails, {
        headers: {
          'x-arifpay-key': 'HaKMbhlGLyUNnkWWO56HpegT6HUM3iGL',
          'Content-Type': 'application/json',
          "Accept": 'application/json'
        }
      });

      paymentDetails.successUrl = response.data.data.paymentUrl;
      setSessionID(response.data.data.sessionId);

      if (uid) {
        const userRef = doc(db, 'users', uid);
        await updateDoc(userRef, {
          sessionId: response.data.data.sessionId
        });
      }
      toast.update(loadingToastId, {
        render: "Request processed successfully!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      setIsLoading(false);
    } catch (error) {
      toast.update(loadingToastId, {
          render: "Request Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      console.error('Error initiating payment:', error);
      setIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDepositDialog = () => {
    setDepositDialogOpen(true);
  };

  const handleOpenWithdrawDialog = () => {
    setWithdrawDialogOpen(true);
  };

  const handleAmountClick = (amount) => {
    setSelectedAmount(amount);
  };

  const handleInputChange = (event) => {
    setSelectedAmount(event.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (input.startsWith('251')) {
      setPhoneNumber(input);
    } else {
      setPhoneNumber('251' + input);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ResponsiveAppBar />

      <CustomTabPanel value={value} index={0}>
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <Stack sx={{ alignItems: 'center' }}>
                <img src={img2} height={50} width={100} />
                <Box sx={{ height: '20px' }} />
                <Typography>
                  Process Time
                </Typography>
                <Divider sx={{ backgroundColor: 'grey', width: '100%' }} />
                <Typography>
                  Instant
                </Typography>
                <Box sx={{ height: '20px' }} />
                <Typography>
                  Min. Amount
                </Typography>
                <Divider sx={{ backgroundColor: 'grey', width: '100%' }} />
                <Typography>
                  20 ETB
                </Typography>
                <Box sx={{ height: '20px' }} />
                <Typography>
                  Max. Amount
                </Typography>
                <Divider sx={{ backgroundColor: 'grey', width: '100%' }} />
                <Typography>
                  200000 ETB
                </Typography>
                <Box sx={{ height: '20px' }} />
                <img src={telebirr} height={50} width={100} />
                <Typography fontSize={15}>Enter your phone number</Typography>
                <input
                  type="text"
                  placeholder="+251"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    width: 'calc(100% - 22px)',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    marginBottom: '20px',
                  }}
                />
                <Typography fontSize={15}>Select or type the amount to be deposited</Typography>
                <Stack direction="row" spacing={1} sx={{ margin: '10px 0' }}>
                  {[20, 50, 100].map((amount) => (
                    <Button
                      key={amount}
                      variant="contained"
                      sx={{ backgroundColor: '#00c9b7', color: '#fff' }}
                      onClick={() => handleAmountClick(amount)}
                    >
                      {amount}
                    </Button>
                  ))}
                </Stack>
                <Stack direction="row" spacing={1} sx={{ margin: '10px 0' }}>
                  {[200, 500, 1000].map((amount) => (
                    <Button
                      key={amount}
                      variant="contained"
                      sx={{ backgroundColor: '#00c9b7', color: '#fff' }}
                      onClick={() => handleAmountClick(amount)}
                    >
                      {amount}
                    </Button>
                  ))}
                </Stack>
                <input
                  type="number"
                  placeholder="Amount"
                  value={selectedAmount}
                  onChange={handleInputChange}
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    width: 'calc(100% - 22px)',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    marginBottom: '20px',
                  }}
                />
                {isLoading ? <CircularProgress /> : <Button
                  sx={{ width: '100%', backgroundColor: '#00c9b7' }}
                  variant="contained"
                  onClick={() => handleDepositConfirm(selectedAmount)}
                >
                  Deposit
                </Button>}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>

      <DepositDialog
        open={depositDialogOpen}
        onClose={() => setDepositDialogOpen(false)}
        onConfirm={handleDepositConfirm}
        isLoading={isLoading}
      />
      <WithdrawDialog
        open={withdrawDialogOpen}
        onClose={() => setWithdrawDialogOpen(false)}
        onConfirm={handleWithdrawalConfirm}
        isLoading={isLoading}
      />
    </Box>
  );
}
