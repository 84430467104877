import React,{useEffect} from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Page1 from './components/page1';
import MainBingoBoard from './components/bingoboard';
import GamingPage from './components/GaneType';
import NumberSelection from './components/selectCartelas';
import Login from './login';
import Bingo from './components/BingoGPT';
import TabComponent from './components/depositTabs';
import { toastNotification, sendNativeNotification } from './notificationHelpers';
import useVisibilityChange from './useVisibilityChange';
import { register } from './serviceWorker';
import { messaging } from './firebase-config';
import firebase from 'firebase/app';
import app from './firebase-config'
import 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import WithdrawTable from './components/transactionTable';
import TransactionTabs from './components/transactionTabs';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import DepositFinal from './components/depositFinal';
import CartelaPage from './components/CartelaPage';
import BingoCardPage from './components/offlineBingo';
import BingoCardPage1 from './components/offlineBingo1';
import BingoCardPage2 from './components/offlineBingo2';
import newLogo from './logo.svg'
import HouseBetSelection from './components/depositHouse';
import GroupChat from './components/GroupChat';
import TransactionHistory from './components/transactionLocal';
const db = getDatabase();

function App() {
  const isForeground = useVisibilityChange();
 
  
  return (
     
    <Router>
      <Routes>
        <Route path="/page1/:number/:stake" element={<MainBingoBoard />} />
        <Route path="/"  element={<GamingPage />} />
        <Route path="/game"  element={<Login />} />
        <Route path="/selectCartelas/:stake"  element={<NumberSelection />} />
        <Route path='/deposit' element={<TabComponent/>} />
        <Route path='/transaction' element={<TransactionTabs/>} />
        <Route path='/depositFinal' element={<DepositFinal/>} />
        <Route path="/Cartela" element={<CartelaPage />} />
        <Route path="/OfflineBingo"  element={<BingoCardPage />} />
        <Route path="/OfflineBingo1"  element={<BingoCardPage1 />} />
        <Route path="/OfflineBingo2"  element={<BingoCardPage2 />} />
        <Route path="/HouseDeposit"  element={<HouseBetSelection />} />
        <Route path="/GroupChat"  element={<GroupChat />} />
        <Route path="/Transaction1"  element={<TransactionHistory />} />




      </Routes>
      <ToastContainer />
    </Router>

  );
}

export default App;
