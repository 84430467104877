import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// StyledButton component with gradient background and border


// CustomButton component
const CustomBingoText = ({ children }) => {
    const text = children === 'A' ? 'B' : children === 'B' ? 'B' : children === 'C' ? "I" : children === 'D' ? 'N' : children === 'E' ?'G':'O';

  return (
      <Typography color={'white'} borderRadius={'4.575rem'} fontWeight={'bold'}  fontSize={20} >
        {text}
      </Typography>
  );
};

export default CustomBingoText;
