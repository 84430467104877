import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, useMediaQuery, Stack, Divider } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import CircularProgress from '@mui/material/CircularProgress';
import { OrbitSpace } from 'orbit-space';
import Carousel from './carousel';
import ResponsiveAppBar from './appbar'; // Assuming you have a ResponsiveAppBar component
import { useNavigate } from 'react-router-dom';
import img from './bingoHome.jpg';
import bingo from '../assets/Bingo 3.jpg';
import bingo1 from '../assets/Bingo Night 2.jpg';
import bingo2 from '../assets/bingo logo3.jpg';
import DepositDialog from './deposit';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { app } from '../firebase-config';
import goldBingo from './5832387579209696906.jpg';
import bonusBingo from './5832387579209696907.jpg';
import PlayerList from './playerList';
import { useTranslation } from 'react-i18next';

const GlowingButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(90deg, #6A1B9A, #8E24AA)',
  color: '#FFFFFF',
  borderRadius: '30px',
  padding: 3,
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '18px',
  boxShadow: '0 0 15px 5px rgba(142, 36, 170, 0.5)',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 0 20px 10px rgba(142, 36, 170, 0.7)',
    background: 'linear-gradient(90deg, #7B1FA2, #9C27B0)',
  },
  '&:active': {
    boxShadow: '0 0 10px 2px rgba(142, 36, 170, 0.7)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
const theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const packages = [
  { price: 10, time: '0:45', color: '#ff5722', imageUrl: img },
  { price: 20, time: '1:04', color: '#ff5722', imageUrl: img },
  { price: 50, time: '2:01', color: '#ff5722', imageUrl: img },
  { price: 100, time: '1:55', color: '#ff5722', imageUrl: img },
];

const beautifulCards = [
  { title: 'Brand Bingo', description: 'https://orca-app-4c284.ondigitalocean.app/', imageUrl: goldBingo },
  { title: 'Bonus Bingo', description:'https://walrus-app-4oxx5.ondigitalocean.app/', imageUrl: bonusBingo },
  { title: 'Golden Bingo', description: 'https://clownfish-app-izfo9.ondigitalocean.app/', imageUrl: goldBingo },
  { title: 'Happy Bingo', description: 'https://octopus-app-2-cq3yz.ondigitalocean.app/', imageUrl: bonusBingo },
];

const PackageCard = styled(Card)(({ theme, bgcolor, bgImage }) => ({
  background: `url(${bgImage}) no-repeat center center`,
  backgroundSize: 'cover',
  color: theme.palette.getContrastText(bgcolor),
  transition: 'transform 0.2s',
  position: 'relative',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const BeautifulCard = styled(Card)(({ theme, bgImage }) => ({
  background: `url(${bgImage}) no-repeat center center`,
  backgroundSize: 'cover',
  color: theme.palette.getContrastText('#000'),
  transition: 'transform 0.2s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '200px', // Adjust the height as needed
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const PackageButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5948d5',
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const GamingPage = () => {
  const [uid, setUid] = useState(null);
  const db = getFirestore(app);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionID, setSessionID] = useState('');
  const navigate = useNavigate();
  const [playersCount, setPlayersCount] = useState({});
  const [depositDialogOpen, setDepositDialogOpen] = useState(false);
  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const { t } = useTranslation();

  const [user, setUser] = useState({
    uid: localStorage.getItem('uid'),
    username: localStorage.getItem('username')
  });

  const generateNonce = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

  const handleDepositConfirm = async (amount) => {
    setIsLoading(true);

    let paymentDetails = {
      userId: uid,
      successUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      cancelUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      errorUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      notifyUrl: "https://us-central1-onlinebingo-7c139.cloudfunctions.net/receiveData",
      paymentMethods: ["TELEBIRR"],
      items: [{
        name: "Bingo Deposit",
        quantity: 1,
        price: amount, // Set the amount based on user input
      }],
      beneficiaries: [{
        accountNumber: "65480441",
        bank: "ABYSETAA",
        amount: amount // Set the amount based on user input
      }],
      phone: '+25935616694',
      nonce: generateNonce(),
    };

    try {
      const response = await axios.post('https://gateway.arifpay.net/api/checkout/session', paymentDetails, {
        headers: {
          'x-arifpay-key': 'HaKMbhlGLyUNnkWWO56HpegT6HUM3iGL',
          'Content-Type': 'application/json',
          "Accept": 'application/json'
        }
      });

      paymentDetails.successUrl = response.data.data.paymentUrl;
      setSessionID('sessionId', response.data.data.sessionId);

      if (uid) {
        const userRef = doc(db, 'users', uid);
        await updateDoc(userRef, {
          sessionId: response.data.data.sessionId
        }).then(() => {
          console.log("Session ID updated successfully.");
        }).catch((error) => {
          console.error("Error updating session ID:", error);
        });
      }

      console.log(response.data.data.paymentUrl);
      setIsLoading(false);
      window.open(response.data.data.paymentUrl, '_blank');

    } catch (error) {
      console.error('Error initiating payment:', error);
      setIsLoading(false);
    }
  };

  const handleOpenDepositDialog = () => {
    setDepositDialogOpen(true);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleRoute = (price) => {
    navigate(`/selectCartelas/${price}`);
  };
  const handleRedirect = (price) => {
    window.open(price, '_blank');
  };
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    const database = getDatabase();
    const countdownRef = ref(database, 'countdown/seconds');

    const unsubscribe = onValue(countdownRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        setRemainingTime(data);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPlayersCount = async (stake) => {
      const database = getDatabase();
      const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
      const snapshot2 = await get(gameSessionNumberRef);
      const currentGameSessionNumber = snapshot2.val();
      const gameSessionRef = ref(database, `BingoGames/Game${currentGameSessionNumber}/gameType${stake}/players`);
      const snapshot = await get(gameSessionRef);
      const players = snapshot.val();
      return players ? Object.keys(players).length : 0;
    };

    const updatePlayersCount = async () => {
      const counts = {};
      for (const pkg of packages) {
        const count = await fetchPlayersCount(pkg.price);
        counts[pkg.price] = count;
      }
      setPlayersCount(counts);
    };

    updatePlayersCount();
  }, []);

  const displayTime = formatTime(remainingTime);
  const images = [
    bingo, bingo1, bingo2
  ];

  useEffect(() => {
    const handleInstallPrompt = (event) => {
      event.preventDefault();
      setInstallPromptEvent(event);
    };

    window.addEventListener('beforeinstallprompt', handleInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
    };
  }, []);

  const handleInstall = () => {
    if (installPromptEvent) {
      installPromptEvent.prompt();
      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setInstallPromptEvent(null);
      });
    }
  };

  return (
    <OrbitSpace>
      <ThemeProvider theme={theme}>
        <ResponsiveAppBar />
        <Box>
          <Carousel images={images} />

          <Typography color={'white'} variant="h6" paddingTop={4}>💥 {t("Play Online Bingo")} 💥</Typography>
          <Box
            sx={{
              overflowX: isSmallScreen ? 'auto' : 'visible',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              paddingTop: 3
            }}
          >
            {packages.map((pkg) => (
              <Box
                key={pkg.price}
                sx={{
                  flex: '1 1 calc(25% - 16px)',
                  margin: '8px',
                  minWidth: isSmallScreen ? '250px' : 'auto'
                }}
              >
                <PackageCard bgcolor={pkg.color} bgImage={pkg.imageUrl || 'default-image-url.jpg'}>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '24px'
                  }}>
                    <CardContent>
                      <Stack direction={'row'} spacing={1}>
                      <Typography variant="h3" component="div" align="center" gutterBottom>
                        {pkg.price} 
                      </Typography>
                      <Typography variant="h5" component="div" align="end" gutterBottomn paddingTop={2}>
                     {t("Birr")}
                      </Typography>
                      </Stack>
              
                     
                      <Typography variant="h6" align="center" gutterBottom>
                       {t("Players")}: {playersCount[pkg.price] || 0}
                      </Typography>
                      <Stack direction={'row'} justifyContent={'space-evenly'}>
                        <Typography variant="body1" align="center" gutterBottom>
                          {pkg.description}
                        </Typography>
                        <Typography variant="body1" align="center" gutterBottom>
                      <Stack direction={'row'} >
                       <Typography paddingTop={2}>Start:  </Typography> 
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {remainingTime > 0 ? (
                              <Typography variant="h3">{displayTime}</Typography>
                            ) : (
                              <CircularProgress />
                            )}
                          </Box></Stack>
                        </Typography>
                      </Stack>

                      <Box display="flex" justifyContent="center" mt={2}>
                        <GlowingButton onClick={() => handleRoute(pkg.price)} variant="contained" disabled={remainingTime > 350 || remainingTime <= 0}>
                          <Typography paddingLeft={3} paddingRight={3} paddingTop={1} paddingBottom={1} fontWeight={'bold'}>{t("Play Now")}</Typography>
                        </GlowingButton>
                      </Box>

                    </CardContent>
                  </div>
                  <CardContent>
                  <Stack direction={'row'}>
                      <Typography variant="h3" component="div" align="center" gutterBottom>
                      ""
                      </Typography>
                      <Typography variant="h5" component="div" align="center" gutterBottom>
                       ""
                      </Typography>
                      </Stack>
                    <Typography variant="h6" align="center" gutterBottom>
               ""
                    </Typography>
                    <Stack direction={'row'} justifyContent={'space-evenly'}>
                      <Typography variant="body1" align="center" gutterBottom>
                        {pkg.description}
                      </Typography>
                      <Typography variant="body1" align="center" gutterBottom>
                        Time Left:{' '}
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {remainingTime > 0 ? (
                            <Typography variant="h3" color={'transparent'}>{displayTime}</Typography>
                          ) : (
                            <CircularProgress />
                          )}
                        </Box>
                      </Typography>
                    </Stack>

                   
                  </CardContent>
                </PackageCard>
                <Divider sx={{ backgroundColor: 'white', height: 2 }} />
              </Box>
            ))}
          </Box>

          <Typography color={'white'} variant="h6" paddingTop={4}>🔥 {t("Play With our House")} 🔥</Typography>
          <Box
            sx={{
              overflowX: isSmallScreen ? 'auto' : 'visible',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              paddingTop: 3
            }}
          >
            {beautifulCards.map((card) => (
              <Box
                key={card.title}
                sx={{
                  flex: '1 1 calc(25% - 16px)',
                  margin: '8px',
                  minWidth: isSmallScreen ? '250px' : 'auto'
                }}
              >
                <BeautifulCard bgImage={card.imageUrl}>
                  <CardContent sx={{ backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', textAlign: 'center' }}>
                    <Typography variant="h5" component="div" gutterBottom>
                      {card.title}
                    </Typography>
                    <PackageButton onClick={() => handleRedirect(card.description)} variant="contained" disabled={remainingTime > 350 || remainingTime <= 0}>
                     {t(" Play Now")}
                    </PackageButton>
                  </CardContent>
                </BeautifulCard>
              </Box>
            ))}
          </Box>
          <PlayerList/>
        </Box>
      </ThemeProvider>
      <DepositDialog
        open={depositDialogOpen}
        onClose={() => setDepositDialogOpen(false)}
        onConfirm={handleDepositConfirm}
        isLoading={isLoading}
      />
     
    </OrbitSpace>
  );
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export default GamingPage;
