import React, { useState } from 'react';
import { Button, Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrbitSpace } from 'orbit-space';
import { useNavigate } from 'react-router-dom';

const NumberButton = styled(Button)(({ selected }) => ({
  width: '40px',
  height: '40px',
  margin: '5px',
  backgroundColor: selected ? '#4caf50' : '#f5f5f5',
  color: selected ? 'white' : 'black',
  '&:hover': {
    backgroundColor: selected ? '#388e3c' : '#e0e0e0',
  },
}));

const CartelaPage = () => {
  const [selectedNumbers, setSelectedNumbers] = useState([]);

  const handleNumberClick = (number) => {
    setSelectedNumbers((prevSelectedNumbers) =>
      prevSelectedNumbers.includes(number)
        ? prevSelectedNumbers.filter((n) => n !== number)
        : [...prevSelectedNumbers, number]
    );
  };
const navigate=useNavigate()
  const handlePlayNow = () => {
    // Add your play now logic here
    navigate('/OfflineBingo')
    console.log('Selected Numbers:', selectedNumbers);
  };

  return (
    <OrbitSpace>
    
          <Typography variant="h4" gutterBottom align="center" color={'white'}>
        Cartela
      </Typography>
          <Grid container justifyContent="center" marginTop={4}marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePlayNow}
          disabled={selectedNumbers.length === 0}
        >
          Play Now
        </Button>
      </Grid>
    
      <Grid container justifyContent="center">
        {Array.from({ length: 100 }, (_, i) => i + 1).map((number) => (
          <NumberButton
            key={number}
            selected={selectedNumbers.includes(number)}
            onClick={() => handleNumberClick(number)}
          >
            {number}
          </NumberButton>
        ))}
      </Grid>
    
   
    </OrbitSpace>
  );
};

export default CartelaPage;
                        