const cards=[
   
    {cardname:"card1",
        b:[15,11,12,3,10],
        i:[16,28,20,30,24],
        n:[39,40,"free",35,37],
        g:[59,51,56,60,53],
        o:[66,68,67,72,64]},
    {cardname:"card2",
      b:[5,15,10,2,6],
      i:[21,20,28,26,27],
      n:[35,42,"free",31,33],
      g:[46,51,47,49,52],
      o:[69,70,67,64,65]},
    {cardname:"card3",
       b:[14,13,3,6,10],
       i:[23,25,28,30,16],
       n:[40,32,"free",44,37],
       g:[58,46,50,54,53],
       o:[62,65,63,66,74]},
    {cardname:"card4",
        b:[1,5,6,14,2],
        i:[19,26,29,25,24],
        n:[41,36,"free",42,45],
        g:[49,50,60,47,54],
        o:[72,69,61,71,65]},
    {cardname:"card5",
        b:[2,4,9,1,14],
        i:[16,23,17,26,20],
        n:[43,32,"free",34,31],
        g:[47,58,51,59,57],
        o:[70,73,74,75,72]},
    {cardname:"card6",
        b:[3,15,14,6,11],
        i:[28,18,20,21,30],
        n:[42,36,"free",45,41],
        g:[46,53,55,57,60],
        o:[70,64,67,73,62]},
    {cardname:"card7",
       b:[15,10,3,9,14],
       i:[28,19,17,16,24],
       n:[39,34,"free",45,36],
       g:[58,54,59,51,49],
       o:[65,68,71,66,64]},
    {cardname:"card8",
         b:[7,13,9,4,2],
         i:[20,19,21,18,27],
         n:[32,36,"free",38,45],
         g:[47,53,57,59,51],
         o:[61,67,66,68,69]},
    {cardname:"card9",
         b:[5,2,1,9,13],
         i:[26,18,29,22,17],
         n:[33,39,"free",44,42],
         g:[56,54,58,57,55],
         o:[75,62,72,68,67]},
    {cardname:"card10",
       b:[1,13,6,7,2],
       i:[20,18,27,23,29],
       n:[34,40,"free",37,44],
       g:[58,59,52,48,57],
       o:[75,69,67,70,73]},
    {cardname:"card11",
        b:[11,4,2,14,5],
        i:[21,28,26,29,24],
        n:[44,34,"free",41,31],
        g:[49,55,47,48,51],
        o:[64,62,71,73,63]},
    {cardname:"card12",
        b:[9,1,6,12,2],
        i:[20,26,16,22,18],
        n:[35,43,"free",41,38],
        g:[59,56,58,49,51],
        o:[66,72,64,61,69]},
    {cardname:"card13",
        b:[11,1,4,9,14],
        i:[16,26,29,28,23],
        n:[45,44,"free",31,40],
        g:[60,55,47,51,59],
        o:[73,69,72,64,68]},
    {cardname:"card14",
        b:[5,1,7,2,10],
        i:[18,27,28,21,24],
        n:[45,42,"free",43,32],
        g:[58,50,54,60,51],
        o:[67,65,64,74,71]},
    {cardname:"card15",
         b:[5,1,2,3,12],
         i:[30,22,18,29,17],
         n:[38,42,"free",33,32],
         g:[48,60,50,46,55],
         o:[71,62,65,75,66]},
    {cardname:"card16",
        b:[7,3,12,4,13],
        i:[23,27,21,24,17],
        n:[45,42,"free",41,44],
        g:[55,60,57,49,50],
        o:[62,71,66,68,75]},
    
        {cardname:"card17",
        b:[10,3,13,2,7],
        i:[28,27,24,21,25],
        n:[32,40,"free",41,42],
        g:[59,47,57,60,58],
        o:[72,63,71,68,65]},
    {cardname:"card18",
         b:[13,7,10,8,14],
         i:[27,22,25,16,23],
         n:[33,42,"free",43,38],
         g:[51,48,54,52,60],
         o:[63,61,65,72,74]},
    {cardname:"card19",
        b:[1,15,3,8,4],
        i:[22,25,23,26,28],
        n:[39,35,"free",44,38],
        g:[51,47,50,49,53],
        o:[62,75,66,70,67]},
    {cardname:"card20",
        b:[9,8,4,11,1],
       i:[19,23,24,17,26],
       n:[35,43,"free",32,38],
       g:[54,57,58,50,49],
       o:[73,61,68,62,75]},
    {cardname:"card21",
         b:[8,2,13,15,6],
         i:[18,30,29,27,17],
         n:[39,37,"free",31,45],
         g:[54,57,56,49,52],
         o:[63,75,68,67,61]},
    {cardname:"card22",
         b:[6,13,9,3,8],
         i:[26,19,25,20,27],
         n:[44,32,"free",40,31],
         g:[55,60,49,46,56],
         o:[62,61,75,65,71]},
    {cardname:"card23",
           b:[1,4,7,9,11],
           i:[27,17,16,23,21],
           n:[40,33,"free",36,34],
           g:[54,46,48,56,50],
           o:[73,68,63,66,74]},
    {cardname:"card24",
         b:[9,8,1,7,12],
         i:[19,26,24,20,27],
         n:[40,31,"free",39,43],
         g:[46,48,59,49,57],
         o:[75,67,65,70,73]},
    {cardname:"card25",
           b:[3,1,4,14,9],
           i:[23,27,28,29,30],
           n:[40,45,"free",35,41],
           g:[53,51,46,56,52],
           o:[75,68,73,61,74]},
    {cardname:"card26",
         b:[10,14,2,6,3],
         i:[25,29,28,22,18],
         n:[37,38,"free",35,45],
         g:[53,58,56,57,60],
         o:[65,69,68,70,67]},
    {cardname:"card27",
         b:[11,3,10,7,13],
         i:[26,28,24,18,30],
         n:[39,33,"free",45,44],
         g:[51,56,58,53,47],
         o:[75,67,74,69,64]},
    {cardname:"card28",
         b:[8,2,14,9,15],
         i:[17,24,16,21,18],
         n:[42,39,"free",31,35],
         g:[52,56,60,47,54],
         o:[74,63,62,72,70]},
    {cardname:"card29",
          b:[14,15,10,2,1],
          i:[16,21,26,19,25],
          n:[32,34,"free",45,40],
          g:[53,59,55,56,51],
          o:[74,65,66,61,64]},
    {cardname:"card30",
         b:[8,11,9,6,13],
         i:[27,26,19,23,22],
         n:[44,31,"free",41,40],
         g:[54,55,57,49,56],
         o:[70,64,67,62,72]},
    {cardname:"card31",
          b:[3,9,5,12,4],
          i:[27,24,17,18,25],
          n:[31,40,"free",38,36],
          g:[46,48,55,58,54],
          o:[71,67,62,68,73]},
    {cardname:"card32",
         b:[10,15,9,5,14],
         i:[20,28,24,25,23],
         n:[32,34,"free",37,31],
         g:[58,56,50,46,51],
         o:[73,61,75,67,65]},
    {cardname:"card33",
        b:[7,15,1,4,8],
        i:[29,27,18,16,21],
        n:[42,40,"free",38,39],
        g:[56,60,51,57,59],
        o:[69,64,74,67,68]},
    {cardname:"card34",
          b:[4,8,9,11,3],
          i:[17,29,24,27,22],
          n:[31,37,"free",34,36],
          g:[46,57,59,55,48],
          o:[70,65,75,63,73]},
    {cardname:"card35",
          b:[9,14,11,8,4],
          i:[17,24,18,21,27],
          n:[35,45,"free",36,37],
          g:[55,52,48,47,57],
          o:[72,68,66,71,70]},
    {cardname:"card36",
           b:[2,13,15,4,10],
           i:[22,21,30,26,29],
           n:[41,45,"free",39,34],
           g:[54,51,47,50,58],
           o:[62,70,63,75,64]},
    {cardname:"card37",
          b:[1,5,2,12,15],
          i:[21,28,26,24,17],
          n:[32,42,"free",34,43],
          g:[54,51,60,59,57],
          o:[65,63,61,62,72]},
    {cardname:"card38",
          b:[1,9,7,12,11],
          i:[30,24,20,17,18],
          n:[45,42,"free",36,35],
          g:[49,56,52,60,54],
          o:[66,69,74,62,63]},
    {cardname:"card39",
          b:[10,14,1,11,13],
          i:[27,16,25,26,28],
          n:[35,37,"free",41,42],
          g:[51,53,48,58,47],
          o:[61,72,69,70,68]},
    {cardname:"card40",
         b:[14,10,7,15,6],
         i:[17,28,16,24,29],
         n:[34,43,"free",41,36],
         g:[54,55,58,59,57],
         o:[63,70,71,69,64]},
    {cardname:"card41",
         b:[5,10,9,14,6],
         i:[18,21,28,25,20],
         n:[31,43,"free",40,35],
         g:[52,56,59,48,47],
         o:[62,66,69,67,71]},
    {cardname:"card42",
         b:[11,10,15,13,2],
         i:[20,25,17,21,23],
         n:[43,33,"free",42,35],
         g:[49,58,50,52,51],
         o:[75,74,67,71,64]},
    {cardname:"card43",
           b:[15,6,13,14,7],
           i:[18,19,16,27,29],
           n:[44,31,"free",35,38],
           g:[54,56,60,55,57],
           o:[69,64,70,66,72]},
    {cardname:"card44",
          b:[11,4,14,8,7],
          i:[28,26,16,25,22],
          n:[35,45,"free",33,44],
          g:[47,48,54,52,57],
          o:[72,73,71,61,68]},
    {cardname:"card45",
          b:[9,6,7,11,8],
          i:[27,20,19,22,17],
          n:[39,38,"free",35,45],
          g:[48,51,55,46,47],
          o:[70,63,68,74,69]},
    {cardname:"card46",
           b:[5,15,11,4,2],
           i:[17,18,21,23,25],
           n:[43,42,"free",39,33],
           g:[47,48,56,54,49],
           o:[74,63,64,66,65]},
    {cardname:"card47",
          b:[5,6,12,1,4],
          i:[17,20,25,16,26],
          n:[38,43,"free",45,35],
          g:[46,51,56,60,53],
          o:[70,75,61,68,74]},
    {cardname:"card48",
          b:[4,2,7,14,6],
          i:[28,19,16,26,18],
          n:[37,31,"free",39,32],
          g:[53,49,56,52,57],
          o:[61,62,64,74,67]},
    {cardname:"card49",
         b:[1,3,10,8,6],
         i:[21,29,24,26,19],
         n:[34,41,"free",35, 31],
         g:[52,54,57,53,58],
         o:[67,69,70,72,64]},
    {cardname:"card50",
         b:[3,10,14,2,15],
         i:[17,30,27,19,18],
         n:[36,40,"free",41,42],
         g:[49,52,58,59,47],
         o:[69,62,66,68,64]},
         {cardname:"card51",
            b:[2,12,10,9,14],
            i:[21,20,27,16,19],
            n:[31,45,"free",40,39],
            g:[49,54,48,46,57],
            o:[68,69,75,61,62]},
    {cardname:"card52",
             b:[10,2,11,15,14],
             i:[22,21,26,23,18],
             n:[36,44,"free",40,31],
             g:[59,55,48,57,58],
             o:[74,70,72,75,66]},
    {cardname:"card53",
          b:[15,5,8,7,11],
          i:[30,21,25,23,29],
          n:[35,45,"free",40,42],
          g:[59,51,46,58,54],
          o:[69,71,67,74,72]},
    {cardname:"card54",
          b:[1,6,4,15,7],
          i:[26,18,24,29,25],
          n:[34,35,"free",32,45],
          g:[60,52,50,48,53],
          o:[61,66,69,63,72]},
    {cardname:"card55",
          b:[12,8,15,7,14],
          i:[24,16,19,25,20],
          n:[45,42,"free",39,32],
          g:[51,53,59,56,48],
          o:[65,62,64,70,74]},
    {cardname:"card56",
              b:[13,3,15,12,4],
              i:[17,30,28,20,24],
              n:[44,45,"free",33,43],
              g:[53,56,55,50,52],
              o:[68,66,73,70,67]},
    {cardname:"card57",
             b:[5,12,14,15,11],
             i:[28,21,16,25,17],
             n:[40,36,"free",44,45],
             g:[56,53,60,58,54],
             o:[63,73,68,66,64]},
    {cardname:"card58",
           b:[1,3,9,10,13],
           i:[16,28,24,20,19],
           n:[32,44,"free",37,39],
           g:[58,60,49,47,46],
           o:[74,67,64,71,61]},
    {cardname:"card59",
         b:[7,2,3,12,5],
         i:[20,24,29,18,17],
         n:[34,43,"free",45,33],
         g:[47,55,46,49,57],
         o:[70,73,62,69,64]},
    {cardname:"card60",
           b:[14,9,1,13,6],
           i:[25,17,30,28,22],
           n:[41,34,"free",38,40],
           g:[48,51,60,49,54],
           o:[75,62,65,73,61]},
    {cardname:"card61",
          b:[11,5,14,7,9],
          i:[26,25,16,18,28],
          n:[38,37,"free",43,41],
          g:[60,52,59,54,46],
          o:[71,65,62,64,74]},
    {cardname:"card62",
         b:[13,8,11,6,2],
         i:[26,27,18,21,20],
         n:[31,43,"free",36,42],
         g:[56,59,53,48,55],
         o:[68,70,73,72,69]},
    {cardname:"card63",
          b:[12,1,15,5,4],
          i:[21,29,22,28,17],
          n:[35,38,"free",33,37],
          g:[49,55,51,50,60],
          o:[62,74,64,65,72]},
    {cardname:"card64",
           b:[15,1,14,2,4],
           i:[24,22,21,29,23],
           n:[38,44,"free",31,41],
           g:[58,60,48,47,56 ],
           o:[64,73,67,68,61]},
    {cardname:"card65",
           b:[6,10,7,9,2],
           i:[18,28,19,20,30],
           n:[35,32,"free",39,33],
           g:[57,52,48,49,59],
           o:[64,62,63,68,65]},
    {cardname:"card66",
          b:[1,2,14,3,4],
          i:[20,27,21,28,24],
          n:[34,33,"free",42,37],
          g:[54,51,58,46,55],
          o:[67,63,73,70,64]},
    {cardname:"card67",
          b:[13,14,5,12,8],
          i:[28,22,26,24,17],
          n:[38,44,"free",34,40],
          g:[58,51,56,53,52],
          o:[71,73,61,72,62]},
    {cardname:"card68",
            b:[14,7,9,13,15],
            i:[25,28,19,22,18],
            n:[41,38,"free",33,44],
            g:[55,59,53,56,57],
            o:[66,65,61,68,63]},
    {cardname:"card69",
            b:[10,6,2,15,8],
            i:[16,28,17,29,27],
            n:[35,40,"free",36,39],
            g:[55,46,59,47,51],
            o:[65,70,73,75,62]},
    {cardname:"card70",
              b:[15,9,12,6,4],
              i:[30,18,17,21,23],
              n:[36,32,"free",43,38],
              g:[50,59,58,46,48],
              o:[70,65,75,62,69]},
    {cardname:"card71",
         b:[6,4,2,7,12],
         i:[25,22,28,17,19],
         n:[31,43,"free",41,45],
         g:[49,53,57,54,46],
         o:[72,61,69,63,65]},
    {cardname:"card72",
           b:[8,5,6,10,2],
           i:[18,20,27,19,17],
           n:[40,35,"free",42,45],
           g:[46,47,49,55,58],
           o:[64,71,73,65,75]},
    {cardname:"card73",
           b:[6,2,15,13,7],
           i:[28,23,30,21,27],
           n:[37,43,"free",34,35],
           g:[48,57,54,60,46],
           o:[72,61,66,65,63]},
    {cardname:"card74",
           b:[10,14,3,7,9],
           i:[24,21,17,18,26],
           n:[45,36,"free",41,44],
           g:[51,53,49,48,54],
           o:[72,67,62,66,63]},
    {cardname:"card75",
    b:[6,7,4,3,14],
          i:[17,20,29,23,24],
          n:[44,37,"free",41,40],
          g:[59,46,50,49,52],
          o:[75,69,63,71,72]},
    {cardname:"card76",
           b:[4,10,6,8,2],
           i:[19,24,23,18,27],
           n:[39,31,"free",35,41],
           g:[48,60,51,50,47],
           o:[62,70,66,73,61]},
    {cardname:"card77",
            b:[1,7,15,14,13],
            i:[18,27,25,21,17],
            n:[34,35,"free",38,40],
            g:[60,56,55,53,58],
            o:[74,61,68,64,75]},
    {cardname:"card78",
           b:[15,11,1,8,13],
           i:[27,17,30,24,22],
           n:[37,34,"free",39,38],
           g:[47,58,46,50,57],
           o:[67,70,68,62,66]},
    {cardname:"card79",
          b:[4,13,11,3,8],
          i:[26,17,29,16,18],
          n:[39,40,"free",44,45],
          g:[57,58,54,53,46],
          o:[72,61,69,65,62]},
    {cardname:"card80",
            b:[8,4,1,5,2],
            i:[23,27,19,30,17],
            n:[39,37,"free",31,32],
            g:[57,56,51,47,48],
            o:[73,66,65,63,67]},
    {cardname:"card81",
         b:[3,5,14,9,2],
         i:[23,16,19,20,25],
         n:[45,41,"free",44,38],
         g:[49,50,47,51,52],
         o:[66,62,72,73,64]},
    {cardname:"card82",
           b:[14,8,1,7,2],
           i:[16,17,25,20,18],
           n:[36,31,"free",33,41],
           g:[54,59,55,47,58],
           o:[63,64,72,66,61]},
    {cardname:"card83",
          b:[1,3,9,10,2],
          i:[16,20,28,26,25],
          n:[31,34,"free",38,36],
          g:[53,57,49,54,47],
          o:[67,73,63,70,61]},
    {cardname:"card84",
           b:[11,12,13,10,5],
           i:[29,19,28,24,22],
           n:[32,41,"free",39,38],
           g:[59,60,56,46,58],
           o:[64,67,62,75,74]},
    {cardname:"card85",
          b:[13,1,14,3,4],
          i:[28,30,17,16,29],
          n:[31,34,"free",36,40],
          g:[51,59,50,56,47],
          o:[62,66,64,71,61]},
    {cardname:"card86",
            b:[4,13,15,2,9],
            i:[21,27,26,25,28],
            n:[37,44,"free",33,42],
            g:[54,57,46,58,48],
            o:[67,61,71,70,68]},
    {cardname:"card87",
          b:[2,1,15,14,8],
          i:[25,17,29,20,22],
          n:[35,43,"free",33,34],
          g:[46,49,59,58,48],
          o:[66,63,72,62,73]},
    {cardname:"card88",
           b:[13,14,12,15,7],
           i:[19,18,23,29,27],
           n:[43,42,"free",44,40],
           g:[55,48,58,52,57],
           o:[64,63,75,65,73]},
    {cardname:"card89",
          b:[2,5,14,10,7],
          i:[16,26,17,24,22],
          n:[44,40,"free",33,38],
          g:[58,56,54,57,60],
          o:[75,65,61,72,69]},
    {cardname:"card90",
          b:[13,3,5,12,1],
          i:[26,20,17,22,24],
          n:[44,38,"free",32,36],
          g:[48,58,46,56,54],
          o:[69,70,72,62,63]},
    {cardname:"card91",
          b:[5,1,10,8,6],
          i:[29,25,30,17,28],
          n:[41,31,"free",34,32],
          g:[59,46,57,55,47],
          o:[72,63,71,75,74]},
    {cardname:"card92",
            b:[5,9,14,13,1],
            i:[18,27,24,28,30],
            n:[37,38,"free",41,45],
            g:[59,57,52,56,46],
            o:[63,70,66,71,72]},
    {cardname:"card93",
          b:[14,15,13,7,8],
          i:[21,18,16,23,20],
          n:[33,40,"free",34,31],
          g:[46,53,51,48,47],
          o:[65,71,63,75,74]},
    {cardname:"card94",
         b:[1,8,7,5,2],
         i:[19,22,30,25,17],
         n:[39,40,"free",41,38],
         g:[58,53,50,46,56],
         o:[67,62,65,72,64]},
    {cardname:"card95",
         b:[4,6,5,12,9],
         i:[19,24,16,29,30],
         n:[37,43,"free",41,39],
         g:[52,60,56,51,48],
         o:[70,65,75,67,61]},
    {cardname:"card96",
          b:[2,14,11,13,4],
          i:[18,27,21,19,16],
          n:[34,45,"free",33,41],
          g:[54,57,56,48,53],
          o:[74,64,62,61,72]},
    {cardname:"card97",
          b:[2,12,9,6,15],
          i:[29,30,21,27,23],
          n:[45,42,"free",40,34],
          g:[47,60,58,48,57],
          o:[66,74,61,62,65]},
    {cardname:"card98",
         b:[13,15,9,8,2],
         i:[24,20,19,28,27],
         n:[40,45,"free",43,38],
         g:[57,50,60,56,47],
         o:[68,64,67,70,65]},
    {cardname:"card99",
           b:[7,12,3,10,11],
           i:[30,19,23,25,29],
           n:[45,35,"free",36,32],
           g:[49,55,53,50,51],
           o:[66,62,67,65,74]},
    {cardname:"card100",
          b:[15,10,8,11,4],
          i:[27,29,23,25,24],
          n:[31,37,"free",43,38],
          g:[54,50,57,58,46],
          o:[73,69,75,68,74]},
     {cardname:"card101",
            b:[15,9,1,7,10],
            i:[17,22,29,16,23],
            n:[35,43,"free",37,41],
            g:[59,54,51,48,52],
            o:[75,74,64,66,65]},
    {cardname:"card102",
           b:[7,10,13,12,4],
           i:[16,29,22,18,27],
           n:[32,38,"free",44,39],
           g:[50,48,53,56,57],
           o:[64,63,74,70,71]},
    {cardname:"card103",
                b:[3,4,12,2,8],
                i:[23,22,17,20,27],
                n:[41,35,"free",43,44],
                g:[58,50,59,52,51],
                o:[62,61,73,75,67]},
    {cardname:"card104",
           b:[13,9,4,5,6],
           i:[25,19,22,18,23],
           n:[39,42,"free",32,38],
           g:[58,46,52,49,51],
           o:[68,67,75,72,70]},
    {cardname:"card105",
            b:[12,6,4,5,1],
            i:[17,16,22,20,29],
            n:[36,41,"free",39,35],
            g:[49,56,57,60,51],
            o:[67,63,74,62,65]},
    {cardname:"card106",
               b:[15,9,13,14,10],
               i:[16,24,27,20,28],
               n:[43,42,"free",45,35],
               g:[54,60,56,57,53],
               o:[61,70,63,62,72]},
    {cardname:"card107",
             b:[3,14,4,9,2],
             i:[28,30,27,22,29],
             n:[34,40,"free",33,36],
             g:[57,52,49,58,47],
             o:[62,68,65,70,63]},
    {cardname:"card108",
              b:[1,7,3,2,6],
              i:[23,22,16,18,30],
              n:[41,40,"free",43,44],
              g:[47,52,58,50,57],
              o:[75,62,68,67,61]},
    {cardname:"card109",
            b:[15,13,5,7,6],
            i:[27,17,23,25,26],
            n:[36,42,"free",38,35],
            g:[47,59,57,50,52],
            o:[70,61,62,69,72]},
    {cardname:"card110",
            b:[11,13,3,6,7],
            i:[27,30,28,18,26],
            n:[41,34,"free",37,32],
            g:[51,55,54,46,57],
            o:[64,74,66,62,70]},
    {cardname:"card111",
           b:[3,12,8,13,1],
           i:[30,25,20,24,28],
           n:[41,32,"free",39,38],
           g:[51,53,47,60,50],
           o:[66,72,71,74,63]},
    {cardname:"card112",
                b:[12,14,7,3,1],
                i:[24,21,17,20,29],
                n:[41,35,"free",43,33],
                g:[50,52,46,57,48],
                o:[68,65,70,74,62]},
    {cardname:"card113",
             b:[11,12,3,2,4],
             i:[21,23,24,26,22],
             n:[34,32,"free",37,36],
             g:[56,53,54,49,48],
             o:[63,69,71,72,75]},
    {cardname:"card114",
            b:[12,14,7,13,3],
            i:[30,19,28,22,20],
            n:[37,43,"free",31,38],
            g:[51,57,48,46,54],
            o:[74,63,67,62,70]},
    {cardname:"card115",
            b:[2,9,13,11,14],
            i:[19,23,27,26,28],
            n:[39,34,"free",38,44],
            g:[50,49,59,53,54],
            o:[70,64,61,67,63]},
    {cardname:"card116",
           b:[7,15,1,4,12],
           i:[21,26,30,27,28],
           n:[38,34,"free",43,44],
           g:[56,52,50,54,59],
           o:[65,75,71,66,70]},
    {cardname:"card117",
           b:[1,2,9,6,14],
           i:[21,29,25,28,18],
           n:[33,32,"free",34,37],
           g:[57,50,53,55,47],
           o:[65,61,74,75,67]},
    {cardname:"card118",
          b:[1,6,10,14,5],
          i:[22,26,27,24,18],
          n:[31,37,"free",42,34],
          g:[47,54,49,55,59],
          o:[67,64,62,72,71]},
    {cardname:"card119",
           b:[14,12,15,3,13],
           i:[26,29,23,28,16],
           n:[45,42,"free",41,31],
           g:[56,55,48,49,54],
           o:[72,62,73,61,65]},
    {cardname:"card120",
            b:[7,13,4,5,8],
            i:[25,20,18,26,17],
            n:[37,43,"free",40,39],
            g:[60,50,55,48,46],
            o:[70,69,74,67,73]},
    {cardname:"card121",
           b:[11,7,5,12,8],
           i:[27,20,28,29,25],
           n:[38,41,"free",33,40],
           g:[51,59,54,56,55],
           o:[63,64,71,73,69]},
    {cardname:"card122",
           b:[11,6,5,1,9],
           i:[21,28,18,16,17],
           n:[32,44,"free",38,31],
           g:[51,60,57,46,53],
           o:[70,74,63,62,73]},
    {cardname:"card123",
           b:[14,10,6,8,3],
           i:[20,28,21,18,29],
           n:[43,40,"free",35,34],
           g:[54,51,53,50,48],
           o:[70,69,67,74,64]},
   {cardname:"card124",
          b:[6,5,4,13,15],
          i:[20,21,30,26,19],
          n:[38,33,"free",41,36],
          g:[50,49,48,60,56],
          o:[66,61,75,65,63]},
    {cardname:"card125",
            b:[12,1,13,14,5],
            i:[28,25,20,21,22],
            n:[33,39,"free",44,38],
            g:[58,60,48,46,54],
            o:[75,66,61,65,67]},
    {cardname:"card126",
           b:[13,12,6,1,2],
           i:[22,30,24,16,17],
           n:[41,32,"free",44,45],
           g:[57,60,58,50,53],
           o:[65,62,61,63,69]},
    {cardname:"card127",
                  b:[13,4,2,9,5],
                  i:[26,16,25,17,27],
                  n:[43,38,"free",34,41],
                  g:[60,52,58,47,46],
                  o:[65,62,63,74,68]},
    {cardname:"card128",
               b:[7,11,2,3,1],
               i:[25,16,23,22,30],
               n:[43,32,"free",38,36],
               g:[47,58,55,46,57],
               o:[63,61,71,68,72]},
    {cardname:"card129",
              b:[3,2,13,10,11],
              i:[25,20,23,29,21],
              n:[43,35,"free",38,42],
              g:[57,59,60,49,53],
              o:[63,71,74,73,70]},
    {cardname:"card130",
              b:[5,15,4,1,2],
              i:[18,27,16,30,28],
              n:[31,42,"free",45,43],
              g:[56,59,57,51,53],
              o:[75,66,71,64,67]},
    {cardname:"card131",
             b:[2,14,3,12,1],
             i:[19,29,28,23,20],
             n:[36,37,"free",32,40],
             g:[51,46,55,56,48],
             o:[64,70,68,67,71]},
    {cardname:"card132",
            b:[1,5,6,7,2],
            i:[30,22,17,24,18],
            n:[32,42,"free",43,37],
            g:[48,57,58,46,55],
            o:[67,64,65,73,74]},
    {cardname:"card133",
            b:[14,6,7,4,10],
            i:[28,16,22,25,23],
            n:[37,39,"free",32,40],
            g:[55,54,58,51,52],
            o:[73,72,63,74,61]},
    {cardname:"card134",
            b:[10,9,1,4,14],
            i:[25,24,26,28,17],
            n:[38,41,"free",39,42],
            g:[58,49,48,57,52],
            o:[74,69,66,75,61]},
    {cardname:"card135",
            b:[13,8,7,9,5],
            i:[24,22,17,27,26],
            n:[45,35,"free",42,40],
            g:[52,60,54,59,49],
            o:[61,73,68,63,71]},
    {cardname:"card136",
            b:[15,4,13,5,2],
            i:[30,22,24,29,17],
            n:[40,32,"free",35,31],
            g:[47,55,53,58,51],
            o:[72,70,66,61,71]},
    {cardname:"card137",
            b:[1,7,3,11,12],
            i:[27,19,24,22,16],
            n:[38,43,"free",31,45],
            g:[56,53,52,51,57],
            o:[61,70,65,74,71]},
    {cardname:"card138",
            b:[4,6,1,10,13],
            i:[30,24,27,26,21],
            n:[35,42,"free",39,36],
            g:[58,56,54,55,53],
            o:[65,61,71,64,67]},
    {cardname:"card139",
           b:[11,4,12,6,14],
           i:[29,21,17,22,19],
           n:[45,41,"free",40,44],
           g:[47,46,53,51,50],
           o:[75,67,69,74,62]},
    {cardname:"card140",
           b:[6,8,2,13,7],
           i:[18,21,16,30,29],
           n:[32,43,"free",41,35],
           g:[51,57,49,59,48],
           o:[62,65,71,75,64]},
    {cardname:"card141",
           b:[11,5,6,4,14],
           i:[20,26,18,21,19],
           n:[42,41,"free",45,36],
           g:[49,47,60,57,52],
           o:[70,72,66,63,69]},
           {cardname:"card142",
           b:[13,10,2,4,12],
           i:[23,27,24,29,25],
           n:[44,42,"free",31,45],
           g:[60,47,58,59,55],
           o:[69,71,68,63,65]},
    {cardname:"card143",
            b:[6,13,10,14,8],
            i:[30,20,16,28,29],
            n:[41,37,"free",35,39],
            g:[48,53,57,54,51],
            o:[63,66,73,67,72]},
    {cardname:"card144",
           b:[14,12,15,4,6],
           i:[20,27,21,17,25],
           n:[42,43,"free",41,39],
           g:[60,49,50,55,51],
           o:[65,66,64,67,72]},
    {cardname:"card145",
              b:[15,9,4,3,14],
              i:[30,29,22,28,16],
              n:[33,32,"free",43,39],
              g:[56,57,46,48,52],
              o:[69,65,66,72,67]},
    {cardname:"card146",
             b:[10,2,7,14,4],
             i:[20,19,28,30,23],
             n:[36,39,"free",35,45],
             g:[55,58,54,60,56],
             o:[64,67,63,66,62]},
    {cardname:"card147",
         b:[11,8,12,2,15],
         i:[29,26,27,30,23],
         n:[34,35,"free",42,41],
         g:[50,58,46,49,57],
         o:[68,74,64,73,69]},
    {cardname:"card148",
             b:[14,15,10,3,6],
             i:[20,22,17,25,29],
             n:[38,45,"free",37,35],
             g:[53,56,50,51,59],
             o:[74,64,63,69,61]},
    {cardname:"card149",
           b:[14,15,7,6,12],
           i:[23,27,18,16,20],
           n:[37,40,"free",42,32],
           g:[52,54,47,57,60],
           o:[72,63,65,61,62]},
    {cardname:"card150",
            b:[6,10,7,13,11],
            i:[24,19,28,17,21],
            n:[33,37,"free",43,35],
            g:[48,50,57,58,53],
            o:[75,64,68,72,74]},
            
  {cardname:"card150",
            b:[6,10,7,13,11],
            i:[24,19,28,17,21],
            n:[33,37,"free",43,35],
            g:[48,50,57,58,53],
            o:[75,64,68,72,74]},
    {cardname:"card151",
                 b:[7,1,2,13,5],
                 i:[17,29,27,24,22],
                 n:[31,32,"free",43,36],
                 g:[49,46,57,56,47],
                 o:[70,62,75,68,67]},
    {cardname:"card152",
              b:[3,8,15,9,1],
              i:[19,22,29,17,18],
              n:[42,37,"free",39,40],
              g:[56,46,57,60,59],
              o:[71,62,72,69,64]},
    {cardname:"card153",
           b:[14,1,7,4,10],
           i:[16,17,29,30,18],
           n:[44,34,"free",41,43],
           g:[51,56,47,54,49],
           o:[69,67,75,65,61]},
    {cardname:"card154",
             b:[10,14,5,6,15],
             i:[30,18,26,20,27],
             n:[35,38,"free",32,39],
             g:[53,47,60,56,48],
             o:[67,64,69,61,65]},
    {cardname:"card155",
          b:[12,9,13,2,7],
           i:[16,23,20,28,24],
           n:[31,41,"free",42,35],
           g:[54,56,60,57,50],
           o:[62,73,61,67,63]},
    {cardname:"card156",
         b:[14,11,1,8,15],
         i:[26,30,18,29,16],
         n:[42,31,"free",34,40],
         g:[56,57,54,52,50],
         o:[66,69,70,71,62]},
    {cardname:"card157",
       b:[6,10,13,1,14],
       i:[24,26,25,28,29],
       n:[40,31,"free",41,35],
       g:[58,47,57,48,53],
       o:[62,74,64,65,73]},
    {cardname:"card158",
        b:[1,15,11,7,14],
        i:[18,17,24,20,21],
        n:[40,42,"free",38,43],
        g:[56,54,60,51,48],
        o:[75,64,70,72,65]},
    {cardname:"card159",
        b:[12,9,4,10,8],
        i:[17,21,26,18,22],
        n:[36,38,"free",40,35],
        g:[53,56,50,59,55],
        o:[72,65,70,67,69]},
    {cardname:"card160",
        b:[3,5,9,12,10],
        i:[25,24,23,27,18],
        n:[33,36,"free",32,43],
        g:[56,54,60,49,46],
        o:[69,70,66,68,73]},
    {cardname:"card161",
       b:[2,9,6,11,10],
       i:[25,26,27,17,22],
       n:[36,42,"free",39,37],
       g:[51,59,50,52,60],
       o:[65,61,62,75,72]},
    {cardname:"card162",
        b:[8,13,14,2,3],
        i:[20,30,29,25,19],
        n:[43,40,"free",34,31],
        g:[56,47,57,60,54],
        o:[69,70,75,61,71]},
    {cardname:"card163",
       b:[12,1,13,3,15],
       i:[19,23,21,30,20],
       n:[37,35,"free",39,31],
       g:[53,46,55,54,58],
       o:[61,74,72,64,75]},
    {cardname:"card164",
       b:[13,15,5,11,14],
       i:[22,30,18,25,24],
       n:[42,32,"free",39,41],
       g:[55,52,54,58,46],
       o:[65,72,75,61,68]},
    {cardname:"card165",
       b:[13,14,12,10,7],
       i:[30,26,22,18,16],
       n:[36,40,"free",37,39],
       g:[57,52,51,47,56],
       o:[70,64,68,66,67]},
    {cardname:"card166",
        b:[1,9,4,12,8],
        i:[19,26,29,21,18],
        n:[41,42,"free",32,45],
        g:[47,53,54,56,49],
        o:[74,68,67,65,70]},
    {cardname:"card167",
         b:[8,5,6,15,12],
         i:[18,24,20,19,26],
         n:[33,31,"free",37,42],
          g:[49,55,59,47,46],
         o:[67,66,72,73,71]},
    {cardname:"card168",
         b:[12,9,11,7,2],
         i:[27,24,26,18,20],
         n:[33,38,"free",31,32],
         g:[48,49,57,46,60],
         o:[69,71,75,61,73]},
    {cardname:"card169",
        b:[13,10,3,15,12],
        i:[18,19,26,16,21],
        n:[34,32,"free",38,37],
        g:[52,54,51,50,48],
        o:[75,61,62,70,66]},
    {cardname:"card170",
        b:[9,5,4,15,10],
        i:[23,22,19,24,29],
        n:[34,35,"free",40,42],
        g:[48,55,50,49,51],
        o:[73,72,67,63,64]},
    {cardname:"card171",
        b:[5,11,9,12,6],
        i:[25,24,29,30,27],
        n:[36,31,"free",39,38],
        g:[57,51,60,48,52],
        o:[70,69,66,72,68]},
    {cardname:"card172",
        b:[11,3,5,14,15],
        i:[20,29,18,30,24],
        n:[43,41,"free",39,40],
        g:[59,50,46,52,47],
        o:[70,69,65,74,64]},
    {cardname:"card173",
         b:[14,15,4,3,7],
         i:[16,30,18,19,23],
         n:[38,31,"free",43,39],
         g:[53,60,56,58,48],
         o:[66,61,68,69,71]},
    {cardname:"card174",
        b:[11,5,13,3,9],
        i:[21,18,17,26,25],
        n:[33,44,"free",38,43],
        g:[49,48,53,57,56],
        o:[68,74,75,71,66]},
    {cardname:"card175",
        b:[10,2,12,1,8],
        i:[17,27,29,22,21],
        n:[43,42,"free",31,45],
        g:[52,51,55,57,46],
        o:[62,74,61,75,70]},
    {cardname:"card176",
         b:[8,6,15,9,14],
         i:[27,20,24,26,22],
         n:[44,40,"free",33,42],
         g:[49,51,60,52,53],
         o:[65,63,68,70,67]},
    {cardname:"card177",
          b:[3,1,15,9,13],
          i:[28,19,18,16,21],
          n:[39,44,"free",40,35],
          g:[55,50,57,52,54],
          o:[62,61,71,75,64]},
    {cardname:"card178",
       b:[6,2,7,15,4],
       i:[28,24,20,26,19],
       n:[37,39,"free",43,34],
       g:[60,56,46,47,48],
       o:[61,66,63,74,65]},
    {cardname:"card179",
       b:[4,5,14,11,15],
       i:[22,27,26,24,30],
       n:[45,33,"free",43,36],
       g:[46,49,58,48,53],
       o:[75,65,63,70,73]},
    {cardname:"card180",
         b:[12,9,2,15,5],
         i:[23,29,18,21,19],
         n:[31,43,"free",39,38],
         g:[54,55,57,56,52],
         o:[65,70,68,64,75]},
    {cardname:"card181",
       b:[9,4,5,8,13],
       i:[24,19,25,28,21],
       n:[44,38,"free",42,35],
       g:[53,57,55,49,59],
       o:[68,63,66,70,62]},
    {cardname:"card182",
       b:[10,4,11,15,12],
       i:[16,29,20,19,27],
       n:[32,42,"free",38,31],
       g:[55,46,50,49,58],
       o:[74,67,70,75,71]},
    {cardname:"card183",
         b:[1,13,15,2,6],
         i:[23,18,26,19,28],
         n:[45,38,"free",39,32],
         g:[57,49,48,58,55],
         o:[61,63,62,70,72]},
    {cardname:"card184",
       b:[10,2,8,1,15],
       i:[22,17,18,27,30],
       n:[32,42,"free",40,43],
       g:[54,59,55,56,49],
       o:[68,66,63,69,71]},
    {cardname:"card185",
      b:[10,5,13,2,3],
      i:[24,22,21,20,28],
      n:[44,43,"free",31,33],
      g:[58,46,54,55,50],
      o:[66,69,75,61,74]},
    {cardname:"card186",
        b:[1,14,9,5,7],
        i:[29,28,25,23,16],
        n:[43,45,"free",33,31],
        g:[54,51,59,58,48],
        o:[61,69,67,63,74]},
    {cardname:"card187",
        b:[12,1,3,9,11],
        i:[26,29,30,21,18],
        n:[36,38,"free",33,40],
        g:[48,49,59,60,52],
        o:[71,74,70,64,67]},
    {cardname:"card188",
        b:[8,13,10,4,11],
        i:[29,21,16,24,25],
        n:[44,41,"free",43,40],
        g:[49,58,53,48,47],
        o:[65,71,69,74,63]},
    {cardname:"card189",
        b:[14,3,13,15,9],
        i:[17,23,28,24,26],
        n:[36,45,"free",41,42],
        g:[52,59,53,56,55],
        o:[63,70,74,67,66]},
    {cardname:"card190",
          b:[7,15,14,2,8],
          i:[26,18,16,20,28],
          n:[40,45,"free",38,32],
          g:[47,57,58,46,56],
          o:[65,63,71,72,64]},
    {cardname:"card191",
        b:[1,7,5,13,9],
        i:[30,27,20,17,24],
        n:[39,45,"free",44,36],
        g:[55,60,58,57,51],
        o:[66,74,69,62,65]},
    {cardname:"card192",
      b:[8,9,14,2,13],
      i:[29,25,20,19,28],
      n:[35,41,"free",42,43],
      g:[60,53,55,51,57],
      o:[71,67,65,68,61]},
    {cardname:"card193",
      b:[14,8,13,7,3],
      i:[29,25,19,18,23],
      n:[44,43,"free",39,36],
      g:[53,52,59,57,50],
      o:[65,72,71,75,69]},
    {cardname:"card194",
      b:[4,15,1,6,5],
      i:[29,26,17,21,18],
      n:[44,53,"free",37,36],
      g:[50,49,57,48,47],
      o:[67,75,63,66,72]},
    {cardname:"card195",
       b:[8,1,11,10,2],
       i:[22,19,27,24,20],
       n:[31,32,"free",40,45],
       g:[53,58,56,47,60],
       o:[73,61,71,68,65]},
    {cardname:"card196",
       b:[4,2,5,6,8],
       i:[16,30,19,22,25],
       n:[38,42,"free",44,39],
       g:[49,60,56,51,54],
       o:[74,73,64,62,70]},
    {cardname:"card197",
       b:[4,5,8,2,9],
       i:[25,24,22,30,28],
       n:[41,39,"free",43,35],
       g:[49,51,54,47,57],
       o:[65,61,73,62,67]},
    {cardname:"card198",
       b:[6,4,5,13,12],
       i:[17,25,30,29,28],
       n:[42,43,"free",45,33],
       g:[54,59,57,58,55],
       o:[73,66,64,67,71]},
    {cardname:"card199",
       b:[10,5,12,9,2],
       i:[29,17,25,16,21],
       n:[40,33,"free",36,31],
       g:[57,50,54,60,59],
       o:[61,73,63,68,71]},
    {cardname:"card200",
        b:[6,2,5,10,14],
        i:[27,26,24,19,30],
        n:[43,45,"free",40,35],
        g:[48,54,47,46,52],
        o:[62,70,74,65,61]},
  
    
    ]
    
      export default cards;
    