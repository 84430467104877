import React, { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate , useLocation} from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, IconButton, Button, Drawer, List, ListItem,
  ListItemText, Divider, Stack, ListItemIcon, Menu, MenuItem,Select,InputLabel,FormControl
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoginDialog from '../login2';  // Adjust the import path as needed
import axios from 'axios';
import DepositDialog from './deposit';
import {
  initializeApp, getAnalytics, getAuth, RecaptchaVerifier, signInWithPhoneNumber,
  PhoneAuthProvider, initializeAuth
} from "firebase/auth";
import {
  getFirestore, doc, getDoc, updateDoc, onSnapshot, collection, where,
  getDocs, increment
} from 'firebase/firestore';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoneyTwoTone from '@mui/icons-material/MoneyTwoTone';
import Transcribe from '@mui/icons-material/Transcribe';
import { grey } from '@mui/material/colors';
import SignUpButton from '../signUpButton';
import SignUpDialog from '../login';
import { app } from '../firebase-config';
import PaymentDialog from './santim';
import { useInstallPrompt } from './useInstallPrompt';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CardTravelSharp, InstallDesktop, TableChart, TableBar, RemoveRedEye } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import CompaniesCard from '../sideCard';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const StyledAppBar = styled(AppBar)({
  marginBottom: '16px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(200deg, #B344FD  50%, #7c4efe 90%)',
});

const InstallPromptContext = createContext();

const NavButton = styled(Button)({
  color: 'white',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

function ResponsiveAppBar() {
  const [uid, setUid] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [signUpDialogOpen, setSignUpDialogOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [userWallet, setUserWallet] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [installPromptEvent, setInstallPromptEvent] = useState(null);

  const [depositDialogOpen, setDepositDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    uid: localStorage.getItem('uid'),
    username: localStorage.getItem('username')
  });
  const [loginOpen, setLoginOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [currentPhone, setCurrentPhone] = useState('');
  const { promptInstall } = useInstallPrompt();

  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation(); // Import the translation function
  const [language, setLanguage] = useState('en');

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage); // Save the language to localStorage
};
useEffect(() => {
  const storedLanguage = localStorage.getItem('language');
  if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage); // Set the initial language from localStorage
  }
}, []);

  const handleForgotPassword = (phone) => {
    setCurrentPhone(phone);
    setForgotPasswordOpen(true);
    setLoginOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Dependency on sessionId state
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleSignUpClose = () => {
    setSignUpDialogOpen(false); // Make sure this correctly updates the state
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  
  // Function to open the deposit dialog
  useEffect(() => {
    const handleAuthChange = (event) => {
      setUser({
        uid: event.detail.uid,
        username: event.detail.username
      });
    };

    window.addEventListener('authChange', handleAuthChange);

    return () => {
      window.removeEventListener('authChange', handleAuthChange);
    };
  }, []);
  const handleOpenDepositDialog = () => {
    setDepositDialogOpen(true);
  };
  const handleOpenTransaction = () => {
    navigate('/transaction')
  };
  const generateNonce = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [sessionID, setSessionID] = useState('');
  const [isWalletVisible, setWalletVisible] = useState(true)

  const handleDepositConfirm = async (amount) => {
    setIsLoading(true);

    let paymentDetails = {
      userId: uid,
      // Existing payment details
      successUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      cancelUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      errorUrl: "https://hammerhead-app-dqibq.ondigitalocean.app/",
      notifyUrl: "https://us-central1-onlinebingo-7c139.cloudfunctions.net/receiveData",
      paymentMethods: ["TELEBIRR"],
      items: [{
        name: "Bingo Deposit",
        quantity: 1,
        price: amount, // Set the amount based on user input
      }],
      beneficiaries: [{
        accountNumber: "65480441",
        bank: "ABYSETAA",
        amount: amount // Set the amount based on user input
      }],
      phone: '+25935616694',
      nonce: generateNonce(),
      // More details...
    };

    // Proceed with sending the request as previously detailed
    try {
      const response = await axios.post('https://gateway.arifpay.net/api/checkout/session', paymentDetails, {
        headers: {
          'x-arifpay-key': 'HaKMbhlGLyUNnkWWO56HpegT6HUM3iGL',
          'Content-Type': 'application/json',
          "Accept": 'application/json'
        }
      });
      paymentDetails.successUrl = response.data.data.paymentUrl;
      setSessionID('sessionId', response.data.data.sessionId);
      if (uid) {
        const userRef = doc(db, 'users', uid);
        await updateDoc(userRef, {
          sessionId: response.data.data.sessionId
        }).then(() => {
          console.log("Session ID updated successfully.");
        }).catch((error) => {
          console.error("Error updating session ID:", error);
        });
      }
      console.log(response.data.data.paymentUrl);
      // Redirect or handle the response as needed
      setIsLoading(false);
      window.open(response.data.data.paymentUrl, '_blank');


    } catch (error) {
      console.error('Error initiating payment:', error);
      setIsLoading(false);

    }
  };
  useEffect(() => {
    const storedUid = localStorage.getItem('uid');
    if (storedUid) {
      setUid(storedUid);

      // Set up real-time listener for the user's data
      const userRef = doc(db, 'users', storedUid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setUsername(userData.userName); // Assuming 'username' is the field name
          setUserWallet(userData.wallet); // Assuming 'wallet' is the field name
        } else {
          console.log("No such user!");
        }
      }, (error) => {
        console.error("Error fetching user data:", error);
      });

      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }
  }, []);



  useEffect(() => {
    // This should ideally check for auth state and fetch user data securely
    const storedUid = localStorage.getItem('uid');
    if (storedUid) {
      setUid(storedUid);
      // Example: Fetch user data from your API
      // You would replace this with your actual fetch logic
    }
  }, []);
  const db = getFirestore(app);
  useEffect(() => {
    const onUidChange = (event) => {
      if (event.key === 'uid') {
        const newUid = event.newValue;
        setUid(newUid);

        if (newUid) {
          const userRef = doc(db, 'users', newUid);
          onSnapshot(userRef, (doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              setUsername(userData.userName);
              setUserWallet(userData.wallet);
            } else {
              console.log("No such user!");
            }
          });
        } else {
          setUsername('');
          setUserWallet('');
        }
      }
    };

    window.addEventListener('storage', onUidChange);

    return () => {
      window.removeEventListener('storage', onUidChange);
    };
  }, []);


  const handleLogout = () => {
    localStorage.removeItem('uid');
    setUid(null);
    setUsername('');
    setUserWallet('');
    navigate('/');  // Redirect to home on logout
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const drawerList = (
    <List sx={{ background: 'linear-gradient(14deg, #1b105f 30%, #5948d5 90%)', height: '100%', color: grey[300], width: '270px', padding: '10px' }}>

      <Stack sx={{background:'linear-gradient(14deg, #1b105f 30%, #5948d5 90%)'}}>
        <Typography padding={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center' }}>
          {username || "Welcome!"}
        </Typography>
        <Typography padding={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center' }}>
          {`${isWalletVisible?userWallet: '****' ?? 0} ETB ` || "0.00 ETB"}
        </Typography>

      </Stack>
      <Divider sx={{ backgroundColor: grey[700] }} />
      <ListItem button key="Home" onClick={() => navigate('/')} sx={{ marginTop: '10px' }}>
        <ListItemIcon sx={{ color: grey[300] }}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={t('home')}/>
      </ListItem>
      {!uid ? (
        <>
          <ListItem button onClick={() => setSignUpDialogOpen(true)}>
            <ListItemIcon sx={{ color: grey[300] }}>
              <AccountCircleIcon />
            </ListItemIcon>

            <SignUpDialog open={dialogOpen} onClose={handleSignUpClose} />   {t("signup")}      </ListItem>
          <ListItem button onClick={() => setLoginDialogOpen(true)}>
            <ListItemIcon sx={{ color: grey[300] }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={t("Login")} />
          </ListItem>
        </>
      ) : (
        <>
          {/* <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} sx={{ marginTop: '10px' }}>


            <ListItem button key="Deposit" onClick={() => navigate('/Deposit')} >
              <ListItemIcon sx={{ color: grey[300] }}>
                <MonetizationOnIcon />          </ListItemIcon>
              <ListItemText primary="Deposit & Withdraw" />
            </ListItem>
          </Stack> */}

          {/* <ListItem button onClick={() => navigate('/transaction')}>
            <ListItemIcon sx={{ color: grey[300] }}>
              <Transcribe />
            </ListItemIcon>
            <ListItemText primary="Transactio
            n" />
          </ListItem> */}
             <ListItem button onClick={() => navigate('/HouseDeposit')}>
            <ListItemIcon sx={{ color: grey[300] }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('Withdraw')} />
          </ListItem>
          <ListItem button onClick={() => navigate('/Transaction1')}>
            <ListItemIcon sx={{ color: grey[300] }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Transaction" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon sx={{ color: grey[300] }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t("Logout" )}/>
          </ListItem>
          <ListItem button onClick={promptInstall}>
            <ListItemIcon sx={{ color: grey[300] }}>
              <InstallDesktop />
            </ListItemIcon>
            <ListItemText primary="Install" />
          </ListItem>
          <CompaniesCard/>
          <FormControl fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
         
        <InputLabel sx={{ color: grey[300] }}>{t('language')}</InputLabel>
      
        <Select
          value={language}
          onChange={handleLanguageChange}
          label={t('language')}
          sx={{ color: grey[300] }}
        >
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'am'}>Amharic</MenuItem>
        </Select>
      </FormControl>

          
        </>
      )}
    </List>
  );

  return (
    <>
      <StyledAppBar position="static">
        <Toolbar>
        {location.pathname !== '/' && (
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <IconButton onClick={handleMenuOpen}>
            <TableChart />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}av
            onClose={handleMenuClose}
          >
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/OfflineBingo2'); }}>Kiya Cartela</MenuItem>

            <MenuItem onClick={() => { handleMenuClose(); navigate('/OfflineBingo2'); }}>Brand Cartela</MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/OfflineBingo'); }}>Bonus Cartela</MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/OfflineBingo1'); }}>Golden Cartela</MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/OfflineBingo'); }}>Happy Cartela</MenuItem>
          </Menu>
<Typography>BINGO</Typography>
          
          <NavButton onClick={() => navigate('/HouseDeposit')}>{t("Deposit")}</NavButton>
   
          {isMobile ? (
            <Stack direction={'row'} alignItems={'center'}>
               <IconButton onClick={()=> isWalletVisible?setWalletVisible(false):setWalletVisible(true) }>
                  <VisibilityOffIcon/>
                </IconButton>
                <Typography paddingRight={2}>{isWalletVisible? userWallet:'****'} {t("ETB")}  </Typography>
                <FormControl  variant="outlined" >
     
     <Select
       value={language}
       onChange={handleLanguageChange}
      
       sx={{ color: grey[300] }}
     >
       <MenuItem value={'en'}>en</MenuItem>
       <MenuItem value={'am'}>Am</MenuItem>
     </Select>
   </FormControl>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
              <MenuIcon />

            </IconButton></Stack>
          ) : (
            <>
              <Stack direction={'row'} sx={{backgroundColor:'linear-gradient(90deg, #F79c81 30%, #Fe9385 90%)'}}>
                <IconButton onClick={()=> isWalletVisible?setWalletVisible(false):setWalletVisible(true) }>
                  <VisibilityOffIcon/>
                </IconButton>
        <Typography padding={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center' }}>
          {username || "Welcome!"}
        </Typography>
        <Typography padding={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center' }}>
          {` ${isWalletVisible?userWallet: '****'  ?? 0} Birr ` || "0.00 Birr"}
        </Typography>

      </Stack>
              <NavButton onClick={() => navigate('/')}>Home</NavButton>
              {uid ? (
                <>
                  {/* <NavButton onClick={() => navigate('/Deposit')}>Deposit & Withdraw</NavButton>
                  <NavButton onClick={handleOpenDepositDialog}>Deposit</NavButton> */}
                  
    
                                <NavButton onClick={() => navigate('/HouseDeposit')}>Withdraw</NavButton>

                  <NavButton onClick={handleLogout}>Logout</NavButton>

                  <NavButton onClick={promptInstall}>Install</NavButton>

                </>
              ) : (
                <>
                  <NavButton onClick={() => setSignUpDialogOpen(true)}>Sign Up</NavButton>
                  <NavButton onClick={() => setLoginDialogOpen(true)}>Login</NavButton>

                  <NavButton onClick={promptInstall}>Install</NavButton>

                </>
              )}
            </>
          )}
        </Toolbar>
      </StyledAppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ '& .MuiDrawer-paper': { backgroundColor: '#333', color: 'white' } }}
      >
        {drawerList}
      </Drawer>
      <SignUpDialog open={signUpDialogOpen} onClose={() => setSignUpDialogOpen(false)} />
      <LoginDialog open={loginDialogOpen} onClose={() => setLoginDialogOpen(false)} />
      <DepositDialog
        open={depositDialogOpen}
        onClose={() => setDepositDialogOpen(false)}
        onConfirm={handleDepositConfirm}
        isLoading={isLoading}
      />
      {/* Install Button */}

    </>
  );
}

export default ResponsiveAppBar;
