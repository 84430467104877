import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { app } from '../firebase-config';

// Function to convert the date string to a desired format
const formatDateTime = (timestamp) => {
  try {
    const date = DateTime.fromJSDate(timestamp.toDate());
    return date.toFormat('MMMM d, yyyy h:mm:ss a');
  } catch (error) {
    console.error("Date parsing error:", error);
    return '';
  }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e35b1',
    color: theme.palette.common.white,
  },
  fontSize: 14,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  fontSize: 20,
}));

function createData(name, calories, fat, date, protein) {
  return { name, calories, fat, date, protein };
}

const db = getFirestore(app);

export default function DepositTable() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "receivedData"));
      const rowData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        rowData.push(createData(data.phone, data.totalAmount, data.transactionStatus, data.timestamp));
      });
      setRows(rowData);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div style={{ padding: 0 }}>
        <Box height={25} />
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .filter((item) => {
                  const storedPhone = localStorage.getItem('phone');
                  const itemPhoneLast9 = item.name?.slice(-9);
                  const storedPhoneLast9 = storedPhone?.slice(-9);
                  return itemPhoneLast9 === storedPhoneLast9;
                })
                .map((row) => (
                  <StyledTableRow key={row.name}>
                     <StyledTableCell align="right">
                      {formatDateTime(row.date)}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.calories}</StyledTableCell>
                    <StyledTableCell align="right">{row.fat}</StyledTableCell>
                  
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
