import React, { useState, useEffect } from 'react';
import './carousel.css'; // Ensure to import the CSS for styling
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#63a4ff',
      dark: '#004ba0',
    },
    secondary: {
      main: '#ff4081',
      light: '#ff79b0',
      dark: '#c60055',
    },
  },
  shape: {
    borderRadius: 15,
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'Source Sans Pro, sans-serif',
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  ],
});

function Carousel({ images }) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [height, setHeight] = useState(isSmallScreen ? '100px' : '250px');

  useEffect(() => {
    setHeight(isSmallScreen ? '100px' : '250px');
  }, [isSmallScreen]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  const slideStyle = {
    transform: `translateX(-${currentIndex * 100}%)`,
    transition: 'transform 0.5s ease-in-out',
  };

  return (
    <div className="carousel-container" style={{ height: height }}>
      <div className="carousel-wrapper" style={slideStyle}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Slide ${index}`} style={{ width: '20%', height: '100%', objectFit: 'contain' }} />
        ))}
      </div>
      <button className="left" onClick={() => setCurrentIndex(currentIndex - 1 >= 0 ? currentIndex - 1 : images.length - 1)}>&lt;</button>
      <button className="right" onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}>&gt;</button>
    </div>
  );
}

export default Carousel;
