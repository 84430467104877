import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
import cards from './constant';
import { getDatabase, ref, onValue, get } from 'firebase/database';

const fetchCalledNumbers = async (setCalledNumbers) => {
  const database = getDatabase();
  try {
    const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
    const snapshot2 = await get(gameSessionNumberRef);
    const currentGameSessionNumber = snapshot2.val();

    if (currentGameSessionNumber) {
      const gameSessionRef = ref(database, `BingoGames/Game${currentGameSessionNumber}/calledNumbers`);
      onValue(gameSessionRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          let formattedCalledNumbers = { b: [], i: [], n: ['free'], g: [], o: [] };

          Object.keys(data).forEach(category => {
            Object.values(data[category]).forEach(number => {
              if (!formattedCalledNumbers[category].includes(number)) {
                formattedCalledNumbers[category].push(number);
              }
            });
          });

          setCalledNumbers(formattedCalledNumbers);
        }
      });
    }
  } catch (error) {
    console.error("Error fetching called numbers:", error);
  }
};

export default function CustomizedDialogs({
  inputNumber,
  setInputNumber,
  secondDialogOpen,
  setSecondDialogOpen,
  unlockAllNumbers,
}) {
  const [calledNumbers, setCalledNumbers] = useState({ b: [], i: [], n: ['free'], g: [], o: [] });
  const [selectedCard, setSelectedCard] = useState(null);
  const [lockedCards, setLockedCards] = useState([]);
  const [matchedDiagonals, setMatchedDiagonals] = useState({ diagonal1: false, diagonal2: false });
  const [matchedRows, setMatchedRows] = useState([]);
  const [matchedColumns, setMatchedColumns] = useState([]);
  const [clickedCalledNumbers, setClickedCalledNumbers] = useState([]);

  useEffect(() => {
    fetchCalledNumbers(setCalledNumbers);
  }, []);

  const handleSecondDialogClose = () => {
    setSecondDialogOpen(false);
    setSelectedCard(null);
    setInputNumber('');
  };

  useEffect(() => {
    const number = parseInt(inputNumber);
    const selectedCard = cards.find(card => card.cardname === `card${number}`);
    if (selectedCard) {
      if (!lockedCards.includes(selectedCard.cardname)) {
        setSelectedCard(selectedCard);
        setSecondDialogOpen(true);
      }
    } else {
      console.error('Card not found.');
    }
  }, [inputNumber]);

  const lockBoard = () => {
    if (selectedCard) {
      setLockedCards([...lockedCards, selectedCard.cardname]);
      setSelectedCard(null);
      setSecondDialogOpen(false);
    }
  };

  useEffect(() => {
    if (selectedCard) {
      const diagonal1Matched =
        calledNumbers.b.includes(selectedCard.b[0]) &&
        calledNumbers.i.includes(selectedCard.i[1]) &&
        calledNumbers.g.includes(selectedCard.g[3]) &&
        calledNumbers.o.includes(selectedCard.o[4]);

      const diagonal2Matched =
        calledNumbers.b.includes(selectedCard.b[4]) &&
        calledNumbers.i.includes(selectedCard.i[3]) &&
        calledNumbers.g.includes(selectedCard.g[1]) &&
        calledNumbers.o.includes(selectedCard.o[0]);

      setMatchedDiagonals({ diagonal1: diagonal1Matched, diagonal2: diagonal2Matched });

      const matchedRows = [];
      for (let i = 0; i < selectedCard.b.length; i++) {
        let rowMatched = true;
        for (const column of Object.keys(selectedCard)) {
          const calledColumn = calledNumbers[column];
          if (
            calledColumn &&
            Array.isArray(calledColumn) &&
            selectedCard[column][i] !== "free" &&
            !calledColumn.includes(selectedCard[column][i])
          ) {
            rowMatched = false;
            break;
          }
        }
        if (rowMatched) {
          matchedRows.push(i);
        }
      }
      setMatchedRows(matchedRows);

      const matchedColumns = Object.keys(selectedCard).filter(column => {
        const col = selectedCard[column];
        const numbers = calledNumbers[column];
        if (col && Array.isArray(col)) {
          if (col.every(num => numbers.includes(num))) {
            return true;
          }
        }
        return false;
      });
      setMatchedColumns(matchedColumns);
    }
  }, [selectedCard, calledNumbers]);

  const handleCellClick = (column, rowIndex) => {
    const number = selectedCard[column][rowIndex];
    const isCalled = calledNumbers[column]?.includes(number);

    if (isCalled) {
      setClickedCalledNumbers(prevClickedCalledNumbers => {
        const newClickedCalledNumbers = [...prevClickedCalledNumbers];
        const index = newClickedCalledNumbers.findIndex(clickedNumber => clickedNumber === number);
        if (index === -1) {
          newClickedCalledNumbers.push(number);
        } else {
          newClickedCalledNumbers.splice(index, 1);
        }
        return newClickedCalledNumbers;
      });
    }
  };

  useEffect(() => {
    const checkBingo = () => {
      for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
        for (const key of ['b', 'i', 'n', 'g', 'o']) {
          if (
            (matchedDiagonals.diagonal1 &&
              ((key === 'b' && rowIndex === 0) ||
                (key === 'i' && rowIndex === 1) ||
                (key === 'n' && rowIndex === 2) ||
                (key === 'g' && rowIndex === 3) ||
                (key === 'o' && rowIndex === 4))) ||
            (matchedDiagonals.diagonal2 &&
              ((key === 'b' && rowIndex === 4) ||
                (key === 'i' && rowIndex === 3) ||
                (key === 'n' && rowIndex === 2) ||
                (key === 'g' && rowIndex === 1) ||
                (key === 'o' && rowIndex === 0))) ||
            matchedRows?.includes(rowIndex) ||
            matchedColumns?.includes(key)
          ) {
            localStorage.setItem('bingo', 'true');
            localStorage.setItem('Message', 'BINGO YOU WON!!!');
            return;
          }
        }
      }
      localStorage.setItem('Message', 'YOU LOST!!!!!!');
      localStorage.setItem('bingo', 'false');
    };

    checkBingo();
  }, [matchedDiagonals, matchedRows, matchedColumns]);

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead sx={{height:'20px'}}>
            <TableRow>
              {['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
                <TableCell key={index} align="center" sx={{ background: 'linear-gradient(200deg, #B344FD  50%, #7c4efe 90%)',height:'20px' }}>
                  <Typography color={'WHITE'} variant="h6" fontWeight="bold">{letter}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedCard &&
              selectedCard.b.map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(selectedCard).filter(key => key !== 'cardname').map((key, colIndex) => (
                    <TableCell
                      key={colIndex}
                      align="center"
                      sx={{
                        backgroundColor: clickedCalledNumbers.includes(selectedCard[key][rowIndex]) ? 'red' : 'white',
                        border: '1px solid black',
                        width: '20px',
                        height: '20px'
                      }}
                      onClick={() => handleCellClick(key, rowIndex)}
                    >
                      <Typography variant="h6" fontWeight="bold" color={clickedCalledNumbers.includes(selectedCard[key][rowIndex]) ? 'white' : 'black'}>
                        {selectedCard[key][rowIndex] === 'free' ? 'Free' : selectedCard[key][rowIndex]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
