import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Box, Alert, Checkbox, FormControlLabel } from '@mui/material';
import { getFirestore, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { app } from './firebase-config'; // Your Firebase configuration

// Initialize Firestore database
const db = getFirestore(app);

const SignUpDialog = ({ open, onClose }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAdult, setIsAdult] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [error, setError] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const handleClose = () => {
        setIsVerified(false);
        setError('');
        onClose();
    };

    const handleRegister = async () => {
        setError(''); // Clear any previous errors
    
        if (!isAdult || !agreeTerms || !password) {
            setError('Please confirm age, agree to terms, and enter a valid password.');
            return;
        }
    
        try {
            const usersRef = collection(db, 'users');
            
            // Normalize the phone number (e.g., remove spaces, dashes)
            const normalizedPhoneNumber = phoneNumber.replace(/\s+/g, '').replace(/-/g, '');
    
            const q = query(usersRef, where('phone', '==', normalizedPhoneNumber));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                setError('Phone number is already registered.');
                return;
            }
    
            setIsVerified(true);
    
            await addDoc(usersRef, {
                userName: username,
                phone: normalizedPhoneNumber,
                password: password, // Consider hashing the password before storing it
                wallet: 0,
                isAdult: true,
                agreeTerms: true
            });
    
            onClose();
            alert('User registered successfully');
        } catch (error) {
            console.error("Error adding document: ", error);
            setError('Failed to register user.');
        }
    };
    

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Sign Up</DialogTitle>
            <DialogContent>
                {error && <Alert severity="error">{error}</Alert>}
                <TextField
                    autoFocus
                    margin="dense"
                    id="username"
                    label="Username"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label="Phone Number"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="standard"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={isAdult} onChange={(e) => setIsAdult(e.target.checked)} />}
                    label="I confirm that I am OVER 18 *"
                />
                <FormControlLabel
                    control={<Checkbox checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} />}
                    label="I’ve read and agree with the Terms of Use and Privacy Policy"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRegister} disabled={!username || !phoneNumber || !password || !isAdult || !agreeTerms}>
                    Register
                </Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
            {isVerified && (
                <Box textAlign="center" pb={2}>
                    <Typography color="primary">Your account has been created!</Typography>
                </Box>
            )}
        </Dialog>
    );
};

export default SignUpDialog;
