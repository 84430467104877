import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';

const NumberBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  textAlign: 'center',
  color: 'blue',
  fontSize: '1.2rem',
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function App() {
  const [calledNumbers, setCalledNumbers] = useState([]);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [isAutoCalling, setIsAutoCalling] = useState(false);

  useEffect(() => {
    let interval;
    if (isAutoCalling && calledNumbers.length < 75) {
      interval = setInterval(generateRandomNumber, 3000);
    }
    return () => clearInterval(interval);
  }, [isAutoCalling, calledNumbers]);

  const generateRandomNumber = () => {
    if (calledNumbers.length >= 75) return;

    let number;
    do {
      number = Math.floor(Math.random() * 75) + 1;
    } while (calledNumbers.includes(number));

    setCalledNumbers(prevNumbers => {
      const updatedNumbers = [...prevNumbers, number];
      return updatedNumbers.length > 5 ? updatedNumbers.slice(-5) : updatedNumbers;
    });
    setCurrentNumber(number);
  };

  const startAutoCalling = () => {
    setIsAutoCalling(true);
  };

  const stopAutoCalling = () => {
    setIsAutoCalling(false);
  };

  const renderCalledNumbers = () => {
    return calledNumbers.map((num, index) => (
      <Grid item key={index}>
        <NumberBox>{num}</NumberBox>
      </Grid>
    ));
  };

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom>
        Bingo Game
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={startAutoCalling}
        disabled={isAutoCalling || calledNumbers.length >= 75}
      >
        Start Auto Call
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={stopAutoCalling}
        disabled={!isAutoCalling}
        style={{ marginLeft: '10px' }}
      >
        Stop Auto Call
      </Button>
      {currentNumber !== null && (
        <Typography variant="h4" align="center" marginTop={2}>
          Current Number: {currentNumber}
        </Typography>
      )}
      <Grid container spacing={2} justifyContent="center" marginTop={3}>
        {renderCalledNumbers()}
      </Grid>
    </Container>
  );
}

export default App;
