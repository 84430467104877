import { useRef } from 'react';

const useWebAudioPlayer = (audioFiles) => {
  const audioContextRef = useRef(null);
  const audioBuffersRef = useRef([]);
  const currentSourceRef = useRef(null);

  // Initialize the audio context and load the audio files
  const initializeAudio = async () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    const fetchAndDecodeAudio = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return await audioContextRef.current.decodeAudioData(arrayBuffer);
    };

    const audioBuffers = await Promise.all(audioFiles.map(fetchAndDecodeAudio));
    audioBuffersRef.current = audioBuffers;
  };

  const playAudio = async (index) => {
    if (!audioContextRef.current) {
      await initializeAudio();
    }

    if (currentSourceRef.current) {
      currentSourceRef.current.stop();
    }

    if (index >= 0 && index < audioBuffersRef.current.length) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffersRef.current[index];
      source.connect(audioContextRef.current.destination);
      source.start();
      currentSourceRef.current = source;
    } else {
      console.error('Invalid audio index:', index);
    }
  };

  return playAudio;
};

export default useWebAudioPlayer;
