// src/api/santimPay.js
import axios from 'axios';

const santimPayAPI = axios.create({
  baseURL: 'https://services.santimpay.com/api/v1/gateway/initiate-payment',
  headers: {
    'Content-Type': 'application/json',
  }
});

export default santimPayAPI;
