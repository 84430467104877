import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { styled, keyframes } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#0b0c10',
    border: '2px solid #45a29e',
    borderRadius: '8px',
    color: '#66fcf1',
    boxShadow: '0 0 15px #45a29e',
    backgroundImage: 'linear-gradient(to bottom right, #0b0c10, #1f2833)',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      border: '2px solid #45a29e',
      zIndex: -1,
    },
    '&::before': {
      transform: 'translate(4px, 4px)',
    },
    '&::after': {
      transform: 'translate(-4px, -4px)',
    },
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: '#1f2833',
    borderBottom: '1px solid #45a29e',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    borderTop: '1px solid #45a29e',
  },
  '& .MuiButton-root': {
    color: '#66fcf1',
    border: '1px solid #45a29e',
    '&:hover': {
      backgroundColor: '#1f2833',
    },
  },
}));
function DepositDialog({ open, onClose, onConfirm, isLoading }) {
  const [amount, setAmount] = useState('');

  const handleConfirm = () => {
    onConfirm(parseFloat(amount)); // Trigger the confirmation with the amount
  };

  return (
    <BootstrapDialog  open={open} onClose={onClose} aria-labelledby="customized-dialog-title" >
      <DialogTitle id="form-dialog-title">Enter Deposit Amount</DialogTitle>
      <DialogContent>
      <TextField
  autoFocus
  margin="dense"
  id="amount"
  label="Amount"
  type="number"
  fullWidth
  variant="outlined"
  value={amount}
  onChange={(e) => setAmount(e.target.value)}
  disabled={isLoading}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00c9b7', // Border color
      },
      '&:hover fieldset': {
        borderColor: '#00c9b7', // Hover border color
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00c9b7', // Focused border color
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
  }}
/>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={isLoading} color="primary">
          {isLoading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
      </DialogActions>
      </BootstrapDialog>
  );
}
export default DepositDialog;