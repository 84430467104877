const cards=[
    {cardname:"card1",
    b:[5,10,2,12,3],
    i:[20,22,19,23,26],
    n:[38,41,"free",36,31],
    g:[58,52,57,51,53],
    o:[61,64,62,63,74]
    },
    {cardname:"card2",
      b:[9,12,15,7,1],
      
      i:[22,21,24,30,16],
      n:[36,32,"free",43,33],
      g:[60,56,47,59,52],
      o:[69,74,73,63,67]
    },
    {cardname:"card3",
      b:[8,6,14,4,5],
      i:[16,26,23,18,25],
      n:[41,34,"free",31,44],
      g:[59,58,57,55,52],
      o:[67,65,62,72,68]
     },
    {cardname:"card4",
      b:[12,5,6,3,1],
      i:[20,26,17,29,19],
      n:[39,33,"free",40,31],
      g:[55,58,54,57,49],
      o:[64,67,74,71,69]
     },
   {cardname:"card5",
      b:[1,10,12,2,3],
      i:[22,23,30,16,24],
      n:[45,33,"free",39,34],
      g:[55,57,56,48,50],
      o:[74,70,64,62,72]
     },
  {cardname:"card6",
     b:[3,12,10,6,11],
     i:[27,28,24,29,18],
     n:[35,34,"free",45,43],
     g:[60,46,50,49,59],
     o:[73,66,67,74,69]
    },
  {cardname:"card7",
     b:[13,4,14,6,11],
     i:[19,16,26,21,17],
     n:[36,42,"free",43,39],
     g:[50,53,46,60,48],
     o:[64,62,63,67,70]
  },
   {cardname:"card8",
      b:[1,8,3,5,10],
      i:[20,22,18,28,30],
      n:[35,34,"free",32,31],
      g:[55,58,48,59,53],
      o:[73,71,65,67,74]
  },
   {cardname:"card9",
      b:[14,8,15,11,4],
      i:[28,30,29,20,16],
      n:[42,43,"free",45,34],
      g:[56,53,47,46,57],
      o:[70,69,65,72,61]
  },
  {cardname:"card10",
      b:[7,2,11,15,6],
      i:[21,26,18,23,19],
      n:[43,41,"free",35,31],
      g:[51,56,57,53,48],
      o:[62,74,66,71,72]
  },
  {cardname:"card11",
       b:[6,2,3,5,7],
       i:[21,27,19,28,23],
       n:[31,42,"free",44,39],
       g:[54,50,58,46,56],
       o:[65,75,63,66,68]
  },
  {cardname:"card12",
        b:[13,7,1,15,10],
        i:[16,19,18,24,26],
        n:[45,41,"free",32,31],
        g:[49,53,48,46,56],
        o:[66,72,64,69,67]
  },
  {cardname:"card13",
        b:[5,14,10,12,6],
        i:[19,28,22,21,23],
        n:[31,41,"free",34,40],
        g:[57,47,53,48,50],
        o:[73,62,75,72,63]
  },
  {cardname:"card14",
         b:[2,15,14,1,11],
         i:[16,24,23,27,17],
         n:[34,31,"free",36,37],
         g:[53,57,49,56,55],
         o:[66,61,68,63,75]
  },
  {cardname:"card15",
          b:[15,11,4,3,9],
          i:[21,30,27,16,19],
          n:[36,45,"free",40,38],
          g:[60,52,53,57,46],
          o:[63,68,65,61,69]
  },
  {cardname:"card16",
          b:[14,2,11,10,7],
          i:[25,18,22,17,16],
          n:[42,36,"free",34,38],
          g:[55,58,57,60,56],
          o:[74,61,63,72,62]
  },
  {cardname:"card17",
          b:[6,14,12,15,7],
          i:[27,29,25,20,18],
          n:[32,42,"free",37,33],
          g:[51,60,54,55,50],
          o:[71,75,62,66,67]
  },
  {cardname:"card18",
           b:[7,4,1,2,6],
           i:[19,24,20,23,26],
           n:[39,38,"free",40,35],
           g:[54,57,52,51,49],
           o:[63,65,66,74,62]
  },
  {cardname:"card19",
           b:[6,8,2,5,13],
           i:[20,18,25,16,19],
           n:[34,40,"free",32,43],
           g:[58,55,60,46,56],
           o:[73,70,64,61,74]
   },
  {cardname:"card20",
            b:[9,8,12,4,10],
            i:[28,19,26,27,16],
            n:[37,36,"free",43,45],
            g:[60,52,53,54,55],
            o:[74,72,69,75,64]
  },
  {cardname:"card21",
            b:[7,2,1,14,13],
            i:[19,17,28,26,21],
            n:[36,38,"free",44,37],
            g:[48,56,60,53,46],
            o:[64,69,75,67,62]
  },
  {cardname:"card22",
          b:[1,6,10,15,13],
          i:[30,28,22,23,16],
          n:[38,36,"free",31,43],
          g:[56,55,51,58,53],
          o:[70,72,68,61,74]
  },
  {cardname:"card23",
           b:[8,14,13,1,11],
           i:[25,22,17,21,26],
           n:[43,44,"free",34,39],
           g:[46,60,53,57,48],
           o:[73,66,64,62,63]
  },
  {cardname:"card24",
           b:[10,9,12,1,4],
           i:[26,20,25,22,17],
           n:[34,38,"free",39,31],
           g:[49,55,53,57,54],
           o:[69,65,67,75,68]
  },
  {cardname:"card25",
         b:[10,5,2,9,3],
         i:[26,16,25,19,27],
         n:[34,40,"free",38,37],
         g:[49,47,58,54,55],
         o:[64,72,65,66,68]
  },
  {cardname:"card26",
           b:[4,14,1,5,6],
           i:[17,18,16,30,28],
           n:[44,45,"free",43,40],
           g:[59,58,52,56,54],
           o:[68,74,67,71,72]
  },
  {cardname:"card27",
         b:[1,8,7,10,11],
         i:[29,16,21,24,26],
         n:[32,38,"free",44,35],
         g:[47,54,50,60,48],
         o:[72,61,73,65,66]
  },
  {
  cardname:"card28",
           b:[10,3,12,11,7],
           i:[20,27,24,28,29],
           n:[40,39,"free",34,36],
           g:[50,49,58,48,52],
           o:[72,74,62,64,71]
  },
  {cardname:"card29",
         b:[15,13,6,14,1],
         i:[27,17,20,18,26],
         n:[43,44,"free",37,42],
         g:[60,47,52,55,59],
         o:[73,65,67,70,74]},
  {cardname:"card30",
            b:[14,1,3,15,5],
            i:[30,28,21,29,25],
            n:[43,38,"free",35,39],
            g:[49,46,59,55,50],
            o:[64,65,75,63,61]},
  {cardname:"card31",
             b:[4,9,5,11,10],
             i:[17,16,19,24,25],
             n:[35,34,"free",37,41],
             g:[60,46,56,52,48],
             o:[66,63,68,72,65]},
  {cardname:"card32",
           b:[2,3,8,15,6],
           i:[17,28,24,18,25],
           n:[36,33,"free",44,41],
           g:[53,54,58,50,46],
           o:[62,68,71,67,61]},
  {cardname:"card33",
         b:[1,14,12,5,11],
         i:[22,24,18,29,26],
         n:[41,34,"free",43,39],
         g:[57,58,60,53,54],
         o:[61,74,73,65,68]},
  {cardname:"card34",
            b:[11,12,4,9,10],
            i:[28,17,25,19,26],
            n:[38,42,"free",34,39],
            g:[54,50,47,53,55],
            o:[62,63,73,67,68]},
  {cardname:"card35",
            b:[3,7,4,11,2],
            i:[22,20,29,30,23],
            n:[44,33,"free",43,37],
            g:[53,60,56,47,49],
            o:[75,72,62,63,74]},
  {cardname:"card36",
           b:[4,12,9,6,1],
           i:[18,19,28,23,27],
           n:[42,45,"free",38,43],
           g:[58,54,60,52,56],
           o:[75,67,68,64,71]},
  {cardname:"card37",
          b:[1,3,13,5,14],
          i:[28,29,21,23,27],
          n:[45,35,"free",33,38],
          g:[46,55,56,48,59],
          o:[65,74,69,64,68]},
  {cardname:"card38",
          b:[1,2,6,8,3],
          i:[20,16,27,24,29],
          n:[40,31,"free",43,38],
          g:[47,50,52,54,48],
          o:[63,64,74,67,69]},
  {cardname:"card39",
           b:[9,15,1,7,13],
           i:[22,30,23,20,29],
           n:[40,32,"free",42,43],
           g:[50,57,48,53,56],
           o:[61,64,62,75,69]},
  {cardname:"card40",
          b:[6,15,2,8,14],
          i:[17,21,27,26,23],
          n:[33,43,"free",37,42],
          g:[60,50,53,52,56],
          o:[73,67,65,74,72]},
  {cardname:"card41",
         b:[8,13,6,10,12],
         i:[26,21,19,20,16],
         n:[44,40,"free",35,42],
         g:[48,46,54,58,53],
         o:[62,63,69,67,68]},
  {cardname:"card42",
          b:[10,3,1,11,6],
          i:[17,30,21,29,19],
          n:[44,36,"free",31,33],
          g:[59,54,53,50,60],
          o:[67,75,69,73,61]},
  {cardname:"card43",
          b:[13,4,8,14,9],
          i:[20,18,27,26,29],
          n:[42,32,"free",34,37],
          g:[57,48,53,56,60],
          o:[65,71,72,70,73]},
  {cardname:"card44",
         b:[14,2,10,3,5],
         i:[21,28,22,16,23],
         n:[31,34,"free",45,43],
         g:[53,58,52,60,49],
         o:[73,67,68,74,70]},
  {cardname:"card45",
         b:[3,5,9,12,13],
         i:[26,27,22,28,21],
         n:[44,33,"free",36,34],
         g:[55,47,49,48,57],
         o:[75,61,72,62,74]},
  {cardname:"card46",
       b:[6,9,8,2,11],
       i:[18,27,20,30,16],
       n:[33,39,"free",36,38],
       g:[46,51,58,48,52],
       o:[69,73,62,70,68]},
  {cardname:"card47",
       b:[8,11,4,10,14],
       i:[29,24,26,23,27],
       n:[34,44,"free",43,35],
       g:[48,52,50,53,55],
       o:[70,63,73,72,75]},
  {cardname:"card48",
        b:[4,3,12,5,6],
        i:[22,24,18,16,17],
        n:[32,34,"free",31,45],
        g:[55,54,59,51,46],
        o:[66,65,61,68,69]},
  {cardname:"card49",
           b:[15,14,4,8,5],
           i:[17,24,27,26,19],
           n:[42,33,"free",40,37],
           g:[51,54,58,53,52],
           o:[75,65,73,61,71]},
  {cardname:"card50",
            b:[9,11,8,15,6],
            i:[28,29,23,21,16],
            n:[41,36,"free",42,40],
            g:[56,59,51,48,50],
            o:[61,63,69,72,73]},
  {cardname:"card51",
          b:[4,15,1,10,9],
          i:[21,27,16,23,29],
          n:[34,31,"free",44,45],
          g:[54,60,46,59,51],
          o:[70,71,73,74,64]},
  {cardname:"card52",
           b:[14,1,11,4,6],
           i:[18,27,29,24,30],
           n:[44,42,"free",37,32],
           g:[58,59,46,51,49],
           o:[63,61,68,69,66]},
  {cardname:"card53",
        b:[5,4,15,2,3],
        i:[17,30,29,28,26],
        n:[37,39,"free",42,36],
        g:[60,54,51,48,59],
        o:[75,68,73,62,65]},
  {cardname:"card54",
        b:[10,7,11,8,14],
        i:[16,20,19,30,25],
        n:[39,40,"free",41,38],
        g:[55,60,57,51,53],
        o:[68,63,74,71,66]},
  {cardname:"card55",
        b:[15,7,9,6,4],
        i:[30,20,19,28,29],
        n:[45,35,"free",43,36],
        g:[56,59,51,46,49],
        o:[61,72,74,65,63]},
  {cardname:"card56",
            b:[4,7,1,14,10],
            i:[30,23,18,17,27],
            n:[40,31,"free",41,32],
            g:[48,59,57,47,60],
            o:[74,69,71,61,73]},
  {cardname:"card57",
           b:[13,8,15,12,7],
           i:[24,19,27,18,25],
           n:[45,35,"free",37,40],
           g:[53,55,48,58,54],
           o:[71,67,68,72,69]},
  {cardname:"card58",
         b:[1,11,2,9,3],
         i:[30,23,26,29,24],
         n:[31,34,"free",36,37],
         g:[46,57,55,53,60],
         o:[73,65,66,71,75]},
  {cardname:"card59",
       b:[13,2,11,1,10],
       i:[20,22,19,25,21],
       n:[35,40,"free",31,36],
       g:[54,47,59,46,55],
       o:[62,70,74,69,66]},
  {cardname:"card60",
         b:[9,4,6,13,14],
         i:[19,20,18,17,30],
         n:[31,37,"free",44,33],
         g:[47,60,50,52,54],
         o:[67,71,66,69,63]},
  {cardname:"card61",
        b:[15,5,2,4,14],
        i:[21,18,28,17,29],
        n:[37,42,"free",35,45],
        g:[46,57,58,52,53],
        o:[63,72,68,61,66]},
  {cardname:"card62",
       b:[10,8,14,11,3],
       i:[25,26,24,23,18],
       n:[37,34,"free",33,40],
       g:[46,47,52,49,60],
       o:[61,66,71,68,69]},
  {cardname:"card63",
        b:[8,7,4,14,3],
        i:[16,24,22,21,18],
        n:[41,31,"free",39,35],
        g:[58,51,49,55,56],
        o:[74,69,70,75,67]},
  {cardname:"card64",
         b:[10,5,6,13,12],
         i:[26,22,29,16,20],
         n:[45,42,"free",31,36],
         g:[56,48,59,47,55],
         o:[68,62,74,61,66]},
  {cardname:"card65",
         b:[14,13,5,11,1],
         i:[24,25,21,18,19],
         n:[37,41,"free",31,40],
         g:[53,46,51,55,52],
         o:[68,65,61,63,69]},
  {cardname:"card66",
        b:[5,11,2,7,1],
        i:[26,29,27,28,23],
        n:[36,43,"free",40,33],
        g:[46,54,59,56,52],
        o:[65,71,69,61,64]},
  {cardname:"card67",
        b:[13,5,15,8,4],
        i:[25,19,16,22,28],
        n:[34,39,"free",38,32],
        g:[60,55,58,48,51],
        o:[72,71,67,70,64]},
  {cardname:"card68",
          b:[12,10,4,9,3],
          i:[17,24,23,22,28],
          n:[45,36,"free",42,33],
          g:[59,52,56,57,50],
          o:[74,72,63,69,70]},
  {cardname:"card69",
          b:[10,8,15,11,1],
          i:[18,21,23,22,29],
          n:[39,34,"free",44,45],
          g:[49,52,54,59,56],
          o:[68,74,69,71,67]},
  {cardname:"card70",
            b:[13,10,9,12,8],
            i:[24,19,21,27,30],
            n:[33,44,"free",41,31],
            g:[50,49,47,55,59],
            o:[65,68,66,72,69]},
  {cardname:"card71",
       b:[8,14,13,15,4],
       i:[24,16,20,21,30],
       n:[42,36,"free",34,43],
       g:[47,57,50,49,55],
       o:[69,63,65,68,61]},
  {cardname:"card72",
         b:[6,8,10,9,11],
         i:[18,19,28,17,26],
         n:[32,41,"free",43,39],
         g:[47,57,53,60,46],
         o:[73,62,61,66,71]},
  {cardname:"card73",
         b:[5,10,11,2,6],
         i:[28,29,23,20,16],
         n:[44,33,"free",45,31],
         g:[60,53,50,56,51],
         o:[63,75,71,64,62]},
  {cardname:"card74",
         b:[7,14,10,9,2],
         i:[25,30,19,23,20],
         n:[38,41,"free",34,37],
         g:[54,47,58,59,57],
         o:[69,62,67,72,75]},
  {cardname:"card75",
        b:[8,5,2,7,14],
        i:[17,29,24,19,30],
        n:[38,44,"free",37,35],
        g:[54,57,47,59,55],
        o:[67,64,63,74,70]},
  {cardname:"card76",
         b:[13,15,14,12,3],
         i:[21,26,22,19,25],
         n:[31,41,"free",33,36],
         g:[47,51,56,59,52],
         o:[63,67,75,72,68]},
  {cardname:"card77",
          b:[4,11,14,9,12],
          i:[18,20,16,24,25],
          n:[34,45,"free",33,35],
          g:[59,51,54,47,46],
          o:[64,63,69,72,75]},
  {cardname:"card78",
         b:[3,11,14,6,2],
         i:[21,27,30,28,24],
         n:[35,36,"free",39,44],
         g:[55,59,46,47,48],
         o:[65,64,62,68,71]},
  {cardname:"card79",
        b:[10,13,12,1,7],
        i:[28,29,16,23,24],
        n:[31,32,"free",39,35],
        g:[48,59,52,55,60],
        o:[72,65,67,68,71]},
  {cardname:"card80",
          b:[14,12,4,1,9],
          i:[16,28,27,17,19],
          n:[37,39,"free",31,41],
          g:[48,54,53,59,50],
          o:[62,69,65,61,66]},
  {cardname:"card81",
       b:[3,14,15,11,10],
       i:[27,20,22,24,16],
       n:[45,35,"free",39,37],
       g:[51,58,60,55,47],
       o:[75,70,66,72,71]},
  {cardname:"card82",
         b:[9,12,13,11,3],
         i:[22,25,19,20,21],
         n:[40,45,"free",38,36],
         g:[49,47,59,52,56],
         o:[72,63,68,69,73]},
  {cardname:"card83",
        b:[7,15,10,8,13],
        i:[19,20,16,29,21],
        n:[43,32,"free",33,34],
        g:[59,57,49,48,52],
        o:[69,64,63,71,61]},
  {cardname:"card84",
         b:[9,1,2,7,6],
         i:[24,16,17,23,30],
         n:[35,41,"free",38,42],
         g:[49,46,56,57,60],
         o:[70,63,75,61,73]},
  {cardname:"card85",
        b:[4,15,11,5,10],
        i:[17,16,26,24,22],
        n:[32,45,"free",35,44],
        g:[51,52,53,48,49],
        o:[72,74,68,69,65]},
  {cardname:"card86",
          b:[15,5,6,7,4],
          i:[16,26,17,20,19],
          n:[45,33,"free",39,38],
          g:[49,58,52,50,54],
          o:[64,61,71,63,73]},
  {cardname:"card87",
        b:[10,2,14,15,9],
        i:[19,20,17,30,21],
        n:[43,39,"free",42,41],
        g:[55,48,53,56,46],
        o:[68,71,61,66,75]},
  {cardname:"card88",
         b:[2,4,12,5,10],
         i:[19,23,26,29,25],
         n:[36,35,"free",42,31],
         g:[59,52,51,56,46],
         o:[62,61,65,70,75]},
  {cardname:"card89",
        b:[9,12,14,1,11],
        i:[20,28,16,25,18],
        n:[41,42,"free",36,31],
        g:[54,50,53,51,55],
        o:[74,65,66,70,68]},
  {cardname:"card90",
        b:[8,9,15,1,14],
        i:[24,16,23,27,18],
        n:[34,41,"free",32,40],
        g:[52,50,48,47,57],
        o:[64,73,72,61,62]},
  {cardname:"card91",
        b:[1,4,14,7,13],
        i:[26,22,18,23,20],
        n:[34,38,"free",44,31],
        g:[49,56,55,57,53],
        o:[69,71,67,70,72]},
  {cardname:"card92",
          b:[4,3,13,1,5],
          i:[23,24,30,16,17],
          n:[45,34,"free",42,38],
          g:[51,46,57,56,58],
          o:[62,64,67,70,71]},
  {cardname:"card93",
        b:[6,8,1,9,14],
        i:[17,24,16,18,25],
        n:[45,44,"free",40,33],
        g:[48,49,54,50,56],
        o:[67,63,75,70,65]},
  {cardname:"card94",
       b:[14,7,2,9,10],
       i:[24,27,22,30,20],
       n:[41,35,"free",38,42],
       g:[56,46,48,60,49],
       o:[70,65,61,74,62]},
  {cardname:"card95",
       b:[15,11,10,14,3],
       i:[27,25,30,23,17],
       n:[39,34,"free",33,45],
       g:[55,48,58,53,50],
       o:[70,72,61,63,66]},
  {cardname:"card96",
        b:[11,10,12,5,1],
        i:[16,25,27,21,28],
        n:[31,32,"free",38,43],
        g:[60,51,47,57,50],
        o:[67,73,70,63,71]},
  {cardname:"card97",
        b:[1,9,15,8,3],
        i:[29,21,16,25,26],
        n:[44,39,"free",33,37],
        g:[59,51,54,46,55],
        o:[68,64,61,75,63]},
  {cardname:"card98",
       b:[15,4,13,1,6],
       i:[22,21,23,24,17],
       n:[35,43,"free",41,36],
       g:[50,58,53,47,57],
       o:[73,61,62,69,70]},
  {cardname:"card99",
         b:[5,4,15,1,13],
         i:[23,16,26,22,24],
         n:[33,35,"free",44,36],
         g:[49,53,55,52,54],
         o:[73,69,72,62,66]},
  {cardname:"card100",
        b:[4,13,15,10,12],
        i:[24,23,22,28,29],
        n:[37,39,"free",44,36],
        g:[56,59,60,53,49],
        o:[66,71,64,63,70]},
  {cardname:"card101",
          b:[11,5,3,2,1],
          i:[18,20,19,17,21],
          n:[36,34,"free",40,43],
          g:[56,53,52,48,57],
          o:[74,72,71,68,62]},
  {cardname:"card102",
         b:[1,15,13,2,6],
         i:[16,18,30,17,20],
         n:[45,40,"free",44,41],
         g:[58,46,59,57,50],
         o:[75,72,61,66,71]},
  {cardname:"card103",
              b:[7,11,14,9,12],
              i:[16,20,21,30,22],
              n:[40,37,"free",43,34],
              g:[48,52,55,49,58],
              o:[75,66,65,62,70]},
  {cardname:"card104",
         b:[13,4,1,3,6],
         i:[25,18,28,20,27],
         n:[31,41,"free",37,34],
         g:[58,54,57,56,46],
         o:[66,68,65,62,73]},
  {cardname:"card105",
          b:[9,11,1,7,6],
          i:[22,19,24,23,29],
          n:[45,42,"free",35,40],
          g:[60,54,55,59,52],
          o:[68,65,71,66,72]},
  {cardname:"card106",
             b:[8,11,14,4,2],
             i:[30,19,20,17,23],
             n:[45,40,"free",32,31],
             g:[50,54,56,59,57],
             o:[74,67,64,73,70]},
  {cardname:"card107",
           b:[12,1,8,15,14],
           i:[19,29,27,18,20],
           n:[34,38,"free",43,33],
           g:[49,54,59,55,51],
           o:[70,68,65,61,74]},
  {cardname:"card108",
            b:[3,15,12,5,7],
            i:[26,16,19,29,18],
            n:[37,44,"free",33,45],
            g:[50,59,47,55,53],
            o:[71,74,62,67,66]},
  {cardname:"card109",
          b:[12,7,9,15,11],
          i:[21,20,27,22,29],
          n:[40,37,"free",34,33],
          g:[51,50,46,49,52],
          o:[69,63,74,64,66]},
  {cardname:"card110",
          b:[2,13,1,3,11],
          i:[18,19,20,26,25],
          n:[41,33,"free",42,37],
          g:[60,50,47,56,48],
          o:[64,61,62,68,65]},
  {cardname:"card111",
         b:[15,8,10,12,6],
         i:[24,29,18,16,23],
         n:[33,38,"free",35,37],
         g:[58,54,60,56,50],
         o:[73,69,74,62,68]},
  {cardname:"card112",
              b:[3,10,2,13,1],
              i:[16,23,30,27,25],
              n:[32,43,"free",37,38],
              g:[59,60,56,52,58],
              o:[68,73,72,65,69]},
  {cardname:"card113",
           b:[8,3,4,10,5],
           i:[30,18,22,16,19],
           n:[35,44,"free",37,32],
           g:[57,56,58,50,48],
           o:[72,70,71,62,66]},
  {cardname:"card114",
          b:[5,3,7,1,11],
          i:[18,17,21,25,28],
          n:[33,32,"free",41,38],
          g:[53,57,56,50,51],
          o:[63,69,75,70,67]},
  {cardname:"card115",
          b:[2,12,14,1,15],
          i:[19,20,30,18,25],
          n:[34,35,"free",43,39],
          g:[56,60,49,58,52],
          o:[61,69,62,73,66]},
  {cardname:"card116",
         b:[6,4,13,9,3],
         i:[16,27,25,30,23],
         n:[43,34,"free",44,38],
         g:[56,55,48,46,54],
         o:[66,61,69,63,72]},
  {cardname:"card117",
         b:[7,4,12,8,11],
         i:[18,20,25,30,19],
         n:[37,43,"free",32,31],
         g:[55,51,59,47,54],
         o:[68,64,71,69,62]},
  {cardname:"card118",
        b:[6,8,1,10,4],
        i:[17,18,26,23,21],
        n:[36,44,"free",31,32],
        g:[48,55,49,59,53],
        o:[65,63,70,61,69]},
  {cardname:"card119",
         b:[2,7,11,10,6],
         i:[25,22,23,24,18],
         n:[40,42,"free",31,33],
         g:[50,52,58,60,55],
         o:[69,62,73,66,67]},
  {cardname:"card120",
          b:[7,4,15,6,3],
          i:[18,23,24,19,21],
          n:[32,39,"free",45,43],
          g:[59,54,55,47,51],
          o:[74,66,67,70,72]},
  {cardname:"card121",
         b:[11,10,15,9,5],
         i:[20,17,24,30,19],
         n:[34,41,"free",39,32],
         g:[51,57,46,47,52],
         o:[67,66,69,72,63]},
  {cardname:"card122",
         b:[12,11,4,5,13],
         i:[24,18,26,27,19],
         n:[32,44,"free",31,40],
         g:[48,49,47,59,57],
         o:[66,61,71,75,64]},
  {cardname:"card123",
         b:[2,8,12,10,3],
         i:[22,19,20,27,29],
         n:[45,35,"free",34,42],
         g:[59,60,47,48,58],
         o:[67,71,68,72,62]},
  {cardname:"card124",
        b:[3,1,14,13,12],
        i:[30,22,24,19,20],
        n:[44,41,"free",35,40],
        g:[60,56,59,49,55],
        o:[64,70,65,68,62]},
  {cardname:"card125",
          b:[7,6,10,1,12],
          i:[28,27,26,25,20],
          n:[34,38,"free",44,41],
          g:[50,51,49,57,52],
          o:[66,68,65,63,71]},
  {cardname:"card126",
         b:[4,1,8,7,15],
         i:[26,16,17,24,20],
         n:[31,45,"free",43,34],
         g:[58,46,60,48,47],
         o:[67,66,62,64,68]},
  {cardname:"card127",
                b:[13,14,5,11,15],
                i:[24,19,30,22,20],
                n:[41,31,"free",33,45],
                g:[53,54,57,47,49],
                o:[70,66,62,73,75]},
  {cardname:"card128",
             b:[12,5,6,10,8],
             i:[18,24,20,19,22],
             n:[38,41,"free",42,45],
             g:[46,49,58,50,48],
             o:[63,72,67,65,62]},
  {cardname:"card129",
            b:[8,14,12,2,1],
            i:[22,29,27,26,30],
            n:[36,43,"free",31,40],
            g:[59,47,60,53,58],
            o:[67,61,68,74,69]},
  {cardname:"card130",
            b:[1,7,9,14,4],
            i:[26,30,21,20,27],
            n:[45,38,"free",32,35],
            g:[47,51,49,53,48],
            o:[71,69,67,75,66]},
  {cardname:"card131",
           b:[7,3,14,1,15],
           i:[17,21,23,24,20],
           n:[45,34,"free",36,43],
           g:[57,48,54,52,49],
           o:[67,61,63,74,64]},
  {cardname:"card132",
          b:[9,1,8,15,11],
          i:[26,29,24,30,22],
          n:[39,34,"free",38,41],
          g:[56,59,49,60,50],
          o:[67,68,72,66,64]},
  {cardname:"card133",
          b:[3,6,5,1,2],
          i:[23,18,20,26,16],
          n:[38,34,"free",41,37],
          g:[49,58,46,53,55],
          o:[61,62,65,72,75]},
  {cardname:"card134",
          b:[4,6,10,2,14],
          i:[28,18,29,24,22],
          n:[42,44,"free",37,34],
          g:[59,47,52,60,55],
          o:[75,74,62,66,69]},
  {cardname:"card135",
          b:[3,5,8,4,13],
          i:[23,21,29,28,22],
          n:[32,44,"free",43,37],
          g:[46,53,47,56,58],
          o:[72,68,65,67,69]},
  {cardname:"card136",
          b:[3,2,5,11,1],
          i:[29,23,18,19,24],
          n:[35,41,"free",37,45],
          g:[58,47,46,56,50],
          o:[61,70,65,71,62]},
  {cardname:"card137",
          b:[3,15,1,9,5],
          i:[22,19,29,18,16],
          n:[42,32,"free",43,38],
          g:[56,50,47,59,48],
          o:[73,62,65,69,68]},
  {cardname:"card138",
          b:[7,11,3,10,9],
          i:[19,23,26,22,21],
          n:[37,32,"free",33,45],
          g:[56,46,50,53,55],
          o:[65,71,72,63,66]},
  {cardname:"card139",
         b:[4,6,11,1,8],
         i:[28,23,24,26,25],
         n:[31,33,"free",36,32],
         g:[54,47,60,56,58],
         o:[66,62,74,72,67]},
  {cardname:"card140",
         b:[9,1,13,7,12],
         i:[30,29,23,22,18],
         n:[40,34,"free",44,45],
         g:[50,48,57,54,53],
         o:[69,63,64,61,66]},
  {cardname:"card141",
         b:[8,14,3,9,13],
         i:[30,18,23,22,29],
         n:[45,37,"free",43,33],
         g:[58,55,53,60,47],
         o:[67,68,73,65,63]},
  {cardname:"card142",
         b:[4,3,5,7,12],
         i:[19,22,17,29,20],
         n:[32,34,"free",33,40],
         g:[49,58,47,55,53],
         o:[74,69,62,75,72]},
  {cardname:"card143",
          b:[4,13,2,3,12],
          i:[21,22,29,16,20],
          n:[38,44,"free",31,39],
          g:[46,49,59,53,55],
          o:[66,75,61,70,72]},
  {cardname:"card144",
         b:[9,2,11,10,14],
         i:[28,29,21,20,26],
         n:[36,41,"free",35,31],
         g:[52,46,50,60,48],
         o:[66,71,64,73,65]},
  {cardname:"card145",
            b:[14,4,13,12,10],
            i:[22,21,28,23,18],
            n:[39,40,"free",35,33],
            g:[49,54,48,52,59],
            o:[69,73,75,67,71]},
  {cardname:"card146",
           b:[14,9,5,4,15],
           i:[26,28,16,19,24],
           n:[36,35,"free",38,32],
           g:[56,49,52,47,60],
           o:[63,75,69,66,65]},
  {cardname:"card147",
       b:[8,15,5,7,1],
       i:[24,25,19,28,22],
       n:[41,43,"free",37,31],
       g:[51,60,59,50,48],
       o:[65,72,64,75,62]},
  {cardname:"card148",
           b:[9,2,3,15,1],
           i:[21,18,20,26,28],
           n:[37,34,"free",43,44],
           g:[46,57,60,54,50],
           o:[70,75,74,66,73]},
  {cardname:"card149",
         b:[9,13,6,2,3],
         i:[20,30,19,17,18],
         n:[43,42,"free",37,35],
         g:[52,47,50,49,54],
         o:[73,70,66,68,62]},
  {cardname:"card150",
          b:[3,7,8,2,11],
          i:[17,27,24,28,23],
          n:[43,36,"free",41,45],
          g:[59,50,53,52,58],
          o:[64,68,62,71,63]},
  {cardname:"card151",
               b:[4,12,10,3,2],
               i:[23,29,17,16,26],
               n:[36,38,"free",42,43],
               g:[56,47,54,59,53],
               o:[61,71,65,70,75]},
  {cardname:"card152",
            b:[2,5,13,10,14],
            i:[19,30,20,25,23],
            n:[44,45,"free",35,33],
            g:[50,60,55,51,53],
            o:[73,67,63,65,75]},
  {cardname:"card153",
         b:[7,9,1,15,14],
         i:[18,26,28,30,25],
         n:[45,38,"free",34,32],
         g:[47,60,54,57,46],
         o:[75,71,63,72,67]},
  {cardname:"card154",
           b:[1,3,2,6,7],
           i:[29,22,28,17,20],
           n:[36,35,"free",37,33],
           g:[54,50,60,49,55],
           o:[75,72,68,63,61]},
  {cardname:"card155",
        b:[5,8,4,1,9],
         i:[22,27,21,24,23],
         n:[31,33,"free",44,34],
         g:[60,50,49,54,46],
         o:[64,66,68,71,70]},
  {cardname:"card156",
       b:[4,14,9,5,7],
       i:[30,21,19,26,23],
       n:[41,36,"free",38,39],
       g:[58,55,56,51,52],
       o:[73,69,65,71,72]},
  {cardname:"card157",
     b:[2,9,8,12,1],
     i:[17,29,22,20,30],
     n:[42,40,"free",32,37],
     g:[53,52,59,56,55],
     o:[71,73,70,61,65]},
  {cardname:"card158",
      b:[13,11,9,14,8],
      i:[29,23,24,19,16],
      n:[34,33,"free",35,44],
      g:[46,49,56,50,47],
      o:[72,71,69,65,66]},
  {cardname:"card159",
      b:[7,15,3,10,14],
      i:[22,16,25,27,17],
      n:[43,37,"free",32,45],
      g:[50,52,60,57,51],
      o:[74,68,70,73,61]},
  {cardname:"card160",
      b:[4,2,13,9,8],
      i:[22,18,25,21,24],
      n:[42,32,"free",35,33],
      g:[56,49,47,60,48],
      o:[69,73,68,71,75]},
  {cardname:"card161",
     b:[12,15,7,8,6],
     i:[18,21,27,19,20],
     n:[40,38,"free",39,31],
     g:[59,47,60,52,57],
     o:[68,72,66,64,67]},
  {cardname:"card162",
      b:[3,11,12,6,4],
      i:[20,25,28,23,30],
      n:[45,39,"free",37,41],
      g:[47,60,59,48,56],
      o:[68,67,63,61,74]},
  {cardname:"card163",
     b:[2,13,12,5,9],
     i:[25,28,29,27,16],
     n:[37,35,"free",32,45],
     g:[55,58,56,48,51],
     o:[71,73,64,72,66]},
  {cardname:"card164",
     b:[1,13,7,6,3],
     i:[16,24,17,20,30],
     n:[45,36,"free",39,43],
     g:[56,58,49,48,46],
     o:[67,68,65,61,71]},
  {cardname:"card165",
     b:[15,11,9,5,12],
     i:[16,20,21,30,26],
     n:[44,34,"free",33,43],
     g:[58,55,50,54,59],
     o:[72,73,67,70,64]},
  {cardname:"card166",
      b:[10,12,11,13,9],
      i:[17,22,26,30,19],
      n:[40,34,"free",43,35],
      g:[54,46,53,48,55],
      o:[64,62,70,68,66]},
  {cardname:"card167",
       b:[15,5,1,2,6],
       i:[24,26,30,21,18],
       n:[36,41,"free",33,35],
       g:[50,57,55,53,56],
       o:[67,73,66,68,70]},
  {cardname:"card168",
       b:[15,1,11,2,9],
       i:[28,23,29,17,18],
       n:[32,31,"free",42,44],
       g:[51,52,55,56,50],
       o:[63,75,66,70,72]},
  {cardname:"card169",
      b:[14,12,8,3,1],
      i:[23,21,27,26,16],
      n:[34,43,"free",42,37],
      g:[51,47,56,53,48],
      o:[68,73,65,71,61]},
  {cardname:"card170",
      b:[15,9,10,13,1],
      i:[20,29,22,18,21],
      n:[39,42,"free",45,32],
      g:[57,60,49,56,58],
      o:[72,62,68,65,63]},
  {cardname:"card171",
      b:[14,1,8,4,10],
      i:[29,18,26,22,27],
      n:[39,33,"free",34,45],
      g:[53,58,51,56,55],
      o:[67,61,70,68,65]},
  {cardname:"card172",
      b:[3,5,9,4,11],
      i:[21,29,28,22,17],
      n:[38,34,"free",31,41],
      g:[49,56,50,48,58],
      o:[74,69,65,68,70]},
  {cardname:"card173",
       b:[11,8,3,6,9],
       i:[18,24,25,19,30],
       n:[44,34,"free",45,41],
       g:[46,53,52,47,50],
       o:[64,75,63,61,69]},
  {cardname:"card174",
      b:[2,6,7,3,10],
      i:[16,18,26,28,30],
      n:[32,44,"free",40,41],
      g:[59,47,48,54,57],
      o:[72,66,73,71,69]},
  {cardname:"card175",
      b:[2,12,8,7,10],
      i:[17,24,19,27,23],
      n:[35,36,"free",43,33],
      g:[48,49,54,56,47],
      o:[72,68,61,63,70]},
  {cardname:"card176",
       b:[5,15,7,12,2],
       i:[24,27,30,29,16],
       n:[33,40,"free",35,37],
       g:[60,50,59,55,48],
       o:[67,73,63,68,72]},
  {cardname:"card177",
        b:[9,5,2,3,4],
        i:[25,27,26,30,20],
        n:[39,37,"free",32,36],
        g:[46,55,57,50,58],
        o:[70,65,69,74,61]},
  {cardname:"card178",
     b:[7,9,1,8,5],
     i:[29,19,22,27,25],
     n:[41,32,"free",40,34],
     g:[58,46,60,51,57],
     o:[66,61,64,67,72]},
  {cardname:"card179",
     b:[9,13,11,1,6],
     i:[29,28,24,30,26],
     n:[38,43,"free",41,34],
     g:[57,48,60,51,47],
     o:[75,68,62,73,63]},
  {cardname:"card180",
       b:[4,12,9,3,7],
       i:[25,21,19,30,26],
       n:[33,35,"free",44,36],
       g:[51,58,54,46,52],
       o:[61,66,67,68,70]},
  {cardname:"card181",
     b:[3,11,12,14,2],
     i:[23,26,17,25,28],
     n:[32,43,"free",42,38],
     g:[52,49,51,57,55],
     o:[61,66,68,71,69]},
  {cardname:"card182",
     b:[15,6,4,13,3],
     i:[19,29,20,22,24],
     n:[45,31,"free",35,33],
     g:[47,51,50,55,49],
     o:[64,75,73,63,72]},
  {cardname:"card183",
       b:[14,9,3,11,7],
       i:[19,17,22,16,30],
       n:[37,36,"free",40,38],
       g:[53,54,56,48,50],
       o:[69,73,71,67,74]},
  {cardname:"card184",
     b:[10,6,7,13,4],
     i:[16,29,18,22,23],
     n:[32,34,"free",42,39],
     g:[48,52,53,54,58],
     o:[67,62,71,73,65]},
  {cardname:"card185",
    b:[4,9,3,15,10],
    i:[25,23,19,21,18],
    n:[40,41,"free",35,42],
    g:[56,59,60,57,50],
    o:[75,65,64,66,68]},
  {cardname:"card186",
      b:[4,15,5,3,10],
      i:[24,23,16,18,21],
      n:[42,34,"free",35,41],
      g:[55,56,48,57,58],
      o:[74,71,72,73,67]},
  {cardname:"card187",
      b:[7,13,6,1,10],
      i:[29,21,18,27,26],
      n:[44,36,"free",38,45],
      g:[60,59,55,53,56],
      o:[62,63,67,65,69]},
  {cardname:"card188",
      b:[6,11,4,2,8],
      i:[19,29,30,25,22],
      n:[35,33,"free",38,42],
      g:[47,49,60,57,46],
      o:[67,68,74,66,69]},
  {cardname:"card189",
      b:[2,11,14,12,15],
      i:[20,29,16,26,28],
      n:[36,44,"free",43,38],
      g:[49,48,53,56,46],
      o:[63,75,69,71,66]},
  {cardname:"card190",
        b:[14,11,8,2,9],
        i:[26,30,20,27,22],
        n:[40,34,"free",35,37],
        g:[55,49,58,53,50],
        o:[67,62,65,71,75]},
  {cardname:"card191",
      b:[12,2,5,7,13],
      i:[27,29,19,25,20],
      n:[32,43,"free",35,41],
      g:[47,57,56,53,49],
      o:[71,70,68,65,74]},
  {cardname:"card192",
    b:[8,3,9,13,1],
    i:[18,28,19,17,26],
    n:[32,41,"free",44,34],
    g:[47,56,51,48,55],
    o:[65,61,73,75,71]},
  {cardname:"card193",
    b:[8,1,5,11,6],
    i:[24,18,23,16,29],
    n:[35,36,"free",31,41],
    g:[50,51,53,48,60],
    o:[65,74,61,73,75]},
  {cardname:"card194",
    b:[14,12,10,9,1],
    i:[28,21,29,19,16],
    n:[42,41,"free",36,34],
    g:[47,51,59,50,54],
    o:[72,63,61,73,74]},
  {cardname:"card195",
     b:[15,4,1,14,11],
     i:[22,20,19,27,21],
     n:[41,44,"free",42,45],
     g:[50,53,46,48,57],
     o:[71,72,62,70,63]},
  {cardname:"card196",
     b:[2,8,11,7,15],
     i:[21,24,18,28,25],
     n:[43,35,"free",34,32],
     g:[48,53,50,52,56],
     o:[65,67,72,68,63]},
  {cardname:"card197",
     b:[5,14,7,12,8],
     i:[21,17,22,16,26],
     n:[37,44,"free",36,34],
     g:[60,54,55,51,52],
     o:[65,61,71,68,74]},
  {cardname:"card198",
     b:[3,8,9,2,7],
     i:[24,30,23,19,29],
     n:[32,41,"free",37,36],
     g:[58,46,57,55,48],
     o:[73,62,64,72,75]},
  {cardname:"card199",
     b:[11,4,5,12,15],
     i:[23,30,26,28,25],
     n:[35,43,"free",45,40],
     g:[49,50,57,56,55],
     o:[61,72,63,66,64]},
  {cardname:"card200",
      b:[8,12,5,6,13],
      i:[22,26,20,30,29],
      n:[41,36,"free",44,32],
      g:[47,58,57,48,49],
      o:[63,68,65,70,73]},
  {cardname:"card201",
    b:[12,6,9,14,3],
    i:[24,19,17,25,23],
    n:[32,33,"free",40,38],
    g:[56,52,55,51,47],
    o:[65,62,69,66,64]},
  {cardname:"card202",
     b:[3,2,1,15,11],
     i:[22,28,29,24,17],
     n:[41,39,"free",44,42],
     g:[51,56,60,54,53],
     o:[62,65,66,64,72]},
  {cardname:"card203",
      b:[6,1,11,15,13],
      i:[25,27,16,20,30],
      n:[40,35,"free",44,32],
      g:[58,56,50,54,57],
      o:[68,63,62,67,69]},
  {cardname:"card204",
      b:[15,1,11,13,7],
      i:[22,20,26,25,19],
      n:[40,44,"free",35,38],
      g:[55,59,54,51,49],
      o:[66,69,74,61,73]},
  {cardname:"card205",
      b:[4,15,10,3,2],
      i:[23,25,19,21,24],
      n:[31,32,"free",43,44],
      g:[58,55,54,51,46],
      o:[63,73,68,72,62]},
  {cardname:"card206",
     b:[10,2,5,14,3],
     i:[16,28,29,27,22],
     n:[34,44,"free",35,43],
     g:[49,55,59,50,53],
     o:[62,67,68,63,71]},
  {cardname:"card207",
       b:[13,12,6,14,2],
       i:[22,17,23,28,26],
       n:[40,39,"free",32,34],
       g:[57,49,48,60,55],
       o:[73,74,66,67,61]},
  {cardname:"card208",
       b:[3,5,1,14,6],
       i:[22,24,26,30,23],
       n:[31,35,"free",36,38],
       g:[59,57,55,49,46],
       o:[69,72,63,71,75]},
  {cardname:"card209",
     b:[14,2,6,7,12],
     i:[24,16,21,19,26],
     n:[37,31,"free",43,40],
     g:[60,53,55,49,57],
     o:[68,64,62,67,63]},
  {cardname:"card210",
      b:[4,6,15,7,13],
      i:[25,16,30,22,17],
      n:[34,43,"free",41,32],
      g:[52,59,55,51,48],
      o:[75,62,74,65,63]},
  {cardname:"card211",
      b:[15,14,2,8,9],
      i:[29,23,30,28,26],
      n:[34,32,"free",44,33],
      g:[56,52,51,50,49],
      o:[66,71,73,75,65]},
  {cardname:"card212",
      b:[4,11,12,7,10],
      i:[27,17,24,21,29],
      n:[42,41,"free",44,35],
      g:[47,58,56,60,51],
      o:[61,75,74,73,67]},
  {cardname:"card213",
      b:[3,2,5,10,1],
      i:[26,24,18,17,22],
      n:[44,32,"free",45,36],
      g:[54,52,60,49,59],
      o:[74,62,64,71,69]},
  {cardname:"card214",
       b:[7,1,2,4,5],
       i:[21,19,29,23,27],
       n:[38,34,"free",32,43],
       g:[58,60,57,48,52],
       o:[66,68,70,69,61]},
  {cardname:"card215",
      b:[6,11,9,10,5],
      i:[28,29,26,19,23],
      n:[43,37,"free",38,41],
      g:[55,60,51,54,47],
      o:[66,72,65,64,71]},
  
      {cardname:"card216",
      b:[3,12,15,10,9],
      i:[16,25,17,26,19],
      n:[42,44,"free",39,31],
      g:[54,60,56,51,59],
      o:[61,75,72,74,70]},
  {cardname:"card217",
       b:[12,4,3,9,13],
       i:[27,23,26,20,30],
       n:[40,36,"free",34,32],
       g:[48,55,50,56,58],
       o:[73,71,62,66,74]},
  {cardname:"card218",
      b:[7,4,15,1,8],
      i:[21,16,28,29,17],
      n:[39,37,"free",45,33],
      g:[49,55,50,54,47],
      o:[70,75,69,68,73]},
  {cardname:"card219",
      b:[13,9,4,5,7],
     i:[16,18,28,27,23],
     n:[40,41,"free",44,37],
     g:[52,55,60,58,47],
     o:[72,70,74,67,66]},
  {cardname:"card220",
       b:[2,10,5,7,8],
       i:[19,24,17,28,22],
       n:[34,31,"free",39,41],
       g:[59,60,57,55,47],
       o:[75,61,66,64,73]},
  {cardname:"card221",
       b:[6,13,2,14,8],
       i:[16,29,26,28,20],
       n:[43,37,"free",41,31],
       g:[51,60,53,47,58],
       o:[70,72,73,62,65]},
  {cardname:"card222",
         b:[4,6,9,3,11],
         i:[26,19,29,25,24],
         n:[31,39,"free",38,36],
         g:[56,60,57,58,54],
         o:[72,69,68,71,61]},
  {cardname:"card223",
       b:[15,7,11,9,3],
       i:[23,20,29,24,17],
       n:[42,35,"free",38,44],
       g:[51,50,55,56,58],
       o:[70,62,65,75,68]},
  {cardname:"card224",
         b:[10,3,5,15,11],
         i:[22,21,17,23,18],
         n:[35,31,"free",36,32],
         g:[58,56,53,49,46],
         o:[71,62,67,75,66]},
  {cardname:"card225",
       b:[1,5,4,9,8],
       i:[19,23,24,20,21],
       n:[38,39,"free",42,36],
       g:[53,57,48,60,51],
       o:[64,65,61,70,71]},
  {cardname:"card226",
       b:[1,15,7,8,5],
       i:[26,17,27,18,25],
       n:[33,34,"free",41,43],
       g:[47,53,52,55,58],
       o:[66,62,70,71,63]},
  {cardname:"card227",
       b:[6,5,13,10,2],
       i:[25,20,26,23,29],
       n:[41,38,"free",36,33],
       g:[55,48,51,49,56],
       o:[61,73,72,66,68]},
  {cardname:"card228",
        b:[10,11,5,9,8],
        i:[16,25,22,29,26],
        n:[39,35,"free",40,37],
        g:[55,56,49,46,48],
        o:[66,67,61,64,74]},
  {cardname:"card229",
       b:[3,1,15,9,2],
       i:[19,29,22,23,17],
       n:[44,38,"free",37,40],
       g:[54,57,50,47,58],
       o:[69,66,73,74,62]},
  {cardname:"card230",
        b:[2,7,14,10,1],
        i:[24,19,25,18,27],
        n:[36,40,"free",43,41],
        g:[60,54,58,47,50],
        o:[67,61,64,74,68]},
  {cardname:"card231",
       b:[9,7,8,2,3],
       i:[20,18,24,27,17],
       n:[38,32,"free",41,35],
       g:[46,56,54,50,48],
       o:[75,71,74,73,62]},
  {cardname:"card232",
      b:[14,2,13,6,5],
      i:[18,26,24,19,28],
      n:[45,33,"free",37,39],
      g:[47,57,48,51,52],
      o:[63,75,65,69,68]},
  {cardname:"card233",
        b:[15,8,13,1,12],
        i:[25,16,17,19,20],
        n:[42,33,"free",40,39],
        g:[49,50,47,60,55],
        o:[72,68,66,65,71]},
  {cardname:"card234",
        b:[3,6,12,7,10],
        i:[27,19,23,30,29],
        n:[40,45,"free",37,34],
        g:[60,48,47,59,52],
        o:[62,68,74,64,63]},
  {cardname:"card235",
         b:[4,3,1,15,2],
         i:[22,30,26,23,18],
         n:[32,35,"free",37,40],
         g:[47,53,51,58,49],
         o:[63,61,71,65,74]},
  {cardname:"card236",
        b:[13,6,14,12,2],
        i:[26,27,18,21,22],
        n:[37,40,"free",33,36],
        g:[60,57,51,48,55],
        o:[68,72,73,75,62]},
  {cardname:"card237",
        b:[8,4,11,6,5],
        i:[26,19,28,30,16],
        n:[44,34,"free",31,35],
        g:[54,59,60,48,58],
        o:[71,72,62,69,66]},
  {cardname:"card238",
        b:[12,4,3,10,5],
        i:[29,17,30,19,24],
        n:[36,40,"free",41,31],
        g:[58,48,55,46,50],
        o:[72,71,66,68,67]},
  {cardname:"card239",
       b:[4,6,9,14,13],
       i:[18,27,25,29,26],
       n:[40,36,"free",45,34],
       g:[58,47,48,49,59],
       o:[73,62,67,74,72]},
  {cardname:"card240",
       b:[4,15,8,2,5],
       i:[20,21,28,30,27],
       n:[44,42,"free",45,38],
       g:[53,54,58,47,59],
       o:[70,66,64,63,62]},
  {cardname:"card241",
       b:[5,11,8,1,2],
       i:[22,19,21,20,16],
       n:[36,38,"free",35,37],
       g:[52,50,58,49,51],
       o:[64,69,63,68,73]},
  {cardname:"card242",
         b:[1,12,7,9,4],
         i:[28,21,16,22,26],
         n:[31,43,"free",38,32],
         g:[58,56,55,49,46],
         o:[67,62,70,74,61]},
  {cardname:"card243",
        b:[8,5,13,4,6],
        i:[21,23,27,20,26],
        n:[33,39,"free",43,40],
        g:[57,54,49,46,53],
        o:[69,70,63,71,64]},
  {cardname:"card244",
        b:[11,10,13,12,7],
        i:[22,20,27,19,23],
        n:[33,43,"free",37,41],
        g:[54,46,60,51,49],
        o:[72,74,63,70,62]},
  {cardname:"card245",
         b:[7,14,11,6,8],
         i:[30,23,26,16,25],
         n:[42,38,"free",41,44],
         g:[57,50,48,53,58],
         o:[64,70,72,66,61]},
  {cardname:"card246",
        b:[2,13,6,10,5],
        i:[29,16,26,30,18],
        n:[34,42,"free",31,33],
        g:[49,51,57,58,55],
        o:[73,62,64,75,70]},
  {cardname:"card247",
        b:[12,5,15,14,9],
        i:[28,21,26,30,23],
        n:[38,42,"free",37,36],
        g:[57,51,59,46,52],
        o:[75,71,74,63,70]},
  {cardname:"card248",
       b:[10,1,15,4,5],
       i:[19,30,27,20,29],
       n:[37,40,"free",43,31],
       g:[49,58,60,50,47],
       o:[63,65,69,71,70]},
  {cardname:"card249",
       b:[14,7,6,9,12],
       i:[28,26,18,22,30],
       n:[34,36,"free",32,33],
       g:[50,53,52,48,46],
       o:[74,70,62,64,67]},
  {cardname:"card250",
      b:[7,13,15,3,10],
      i:[23,28,16,17,19],
      n:[32,33,"free",40,37],
      g:[60,48,49,55,53],
      o:[68,64,61,75,69]},
  {cardname:"card251",
         b:[1,2,5,3,4],
         i:[20,28,29,19,16],
         n:[43,44,"free",39,40],
         g:[48,54,59,50,53],
         o:[71,74,61,67,63]},
  {cardname:"card252",
        b:[14,3,1,8,15],
        i:[28,20,24,17,16],
        n:[45,34,"free",35,42],
        g:[50,53,47,51,60],
        o:[73,62,67,68,72]},
  {cardname:"card253",
        b:[12,5,13,10,4],
        i:[30,22,20,26,29],
        n:[43,32,"free",40,39],
        g:[50,59,60,47,51],
        o:[71,74,64,68,63]},
  {cardname:"card254",
        b:[12,15,3,8,10],
        i:[18,24,20,19,23],
        n:[34,45,"free",39,36],
        g:[59,46,51,57,60],
        o:[61,75,69,63,64]},
  {cardname:"card255",
       b:[3,4,9,1,6],
       i:[17,24,20,16,26],
       n:[36,44,"free",42,31],
       g:[54,60,53,59,57],
       o:[68,72,69,67,74]},
  {cardname:"card256",
        b:[4,15,7,3,14],
        i:[18,22,20,21,27],
        n:[36,40,"free",45,38],
        g:[59,60,48,52,49],
        o:[64,61,72,67,74]},
  {cardname:"card257",
        b:[7,6,1,5,9],
        i:[20,26,25,17,24],
        n:[44,37,"free",33,43],
        g:[48,55,51,60,50],
        o:[63,71,73,69,62]},
  {cardname:"card258",
        b:[4,7,3,6,9],
        i:[29,22,27,24,19],
        n:[44,42,"free",34,31],
        g:[60,48,57,47,56],
        o:[67,70,64,63,73]},
  {cardname:"card259",
       b:[8,11,1,4,13],
       i:[21,26,18,23,19],
       n:[34,33,"free",35,42],
       g:[60,46,47,58,49],
       o:[62,63,75,64,61]},
  {cardname:"card260",
        b:[1,5,12,14,13],
        i:[25,24,19,29,17],
        n:[34,35,"free",32,38],
        g:[56,53,55,52,47],
        o:[70,66,63,71,69]},
  {cardname:"card261",
         b:[10,9,2,13,3],
         i:[26,16,17,18,22],
         n:[42,37,"free",32,33],
         g:[56,54,58,59,51],
         o:[71,62,67,70,72]},
  {cardname:"card262",
         b:[10,6,12,11,4],
         i:[19,27,29,18,20],
         n:[41,31,"free",35,39],
         g:[48,51,54,53,52],
         o:[61,68,66,71,64]},
  {cardname:"card263",
         b:[10,9,13,1,15],
         i:[25,30,20,21,17],
         n:[39,40,"free",41,37],
         g:[58,49,55,54,60],
         o:[71,67,64,74,62]},
  {cardname:"card264",
         b:[11,13,7,4,1],
         i:[22,30,16,17,20],
         n:[38,35,"free",40,36],
         g:[56,46,57,60,49],
         o:[63,65,75,61,67]},
  {cardname:"card265",
       b:[14,11,8,10,13],
       i:[22,23,21,30,25],
       n:[36,40,"free",41,33],
       g:[58,46,60,52,59],
       o:[68,73,74,61,66]},
  {cardname:"card266",
        b:[3,4,13,6,15],
        i:[19,22,16,17,29],
        n:[35,37,"free",40,34],
        g:[46,47,55,52,53],
        o:[72,75,67,62,71]},
  {cardname:"card267",
       b:[15,5,9,8,11],
       i:[20,18,21,28,17],
       n:[37,39,"free",31,41],
       g:[48,57,60,53,59],
       o:[69,75,61,68,67]},
  {cardname:"card268",
       b:[3,11,1,13,8],
       i:[25,28,30,19,17],
       n:[40,42,"free",38,45],
       g:[47,50,53,59,55],
       o:[65,68,73,69,63]},
  {cardname:"card269",
        b:[14,6,12,5,10],
        i:[24,19,25,21,30],
        n:[37,35,"free",31,43],
        g:[59,48,60,54,47],
        o:[66,71,62,69,70]},
  {cardname:"card270",
        b:[4,1,14,10,7],
        i:[19,21,30,27,18],
        n:[38,35,"free",39,31],
        g:[47,46,51,55,56],
        o:[75,74,64,73,66]},
  {cardname:"card271",
          b:[2,1,6,15,14],
          i:[22,20,18,19,29],
          n:[33,41,"free",44,34],
          g:[46,48,52,56,55],
          o:[63,75,73,61,71]},
  {cardname:"card272",
        b:[9,13,11,6,12],
        i:[25,27,23,17,22],
        n:[39,34,"free",32,36],
        g:[58,46,54,57,56],
        o:[68,72,75,63,70]},
  {cardname:"card273",
        b:[4,8,2,7,14],
        i:[20,23,25,28,16],
        n:[35,37,"free",38,31],
        g:[55,48,51,47,57],
        o:[74,75,70,68,72]},
  {cardname:"card274",
       b:[9,1,2,5,14],
       i:[20,25,21,16,26],
       n:[33,32,"free",41,38],
       g:[47,55,58,57,48],
       o:[62,71,66,61,68]},
  {cardname:"card275",
       b:[7,12,10,8,11],
       i:[21,28,26,18,17],
       n:[33,38,"free",32,36],
       g:[47,55,49,56,58],
       o:[72,67,62,64,65]},
  {cardname:"card276",
         b:[10,14,7,5,12],
         i:[19,21,25,29,26],
         n:[37,35,"free",41,36],
         g:[46,59,54,50,49],
         o:[63,69,72,71,64]},
  {cardname:"card277",
        b:[10,11,1,12,2],
        i:[30,26,19,20,18],
        n:[37,44,"free",35,34],
        g:[53,58,59,54,60],
        o:[75,64,73,66,61]},
  {cardname:"card278",
        b:[5,14,9,1,12],
        i:[26,30,25,29,27],
        n:[34,35,"free",38,31],
        g:[59,49,57,58,55],
        o:[66,69,62,61,64]},
  {cardname:"card279",
       b:[11,5,12,6,9],
       i:[20,25,17,22,18],
       n:[32,31,"free",39,42],
       g:[59,49,60,58,55],
       o:[75,72,71,69,68]},
  {cardname:"card280",
        b:[9,5,3,8,7],
        i:[17,20,30,22,25],
        n:[39,34,"free",33,43],
        g:[59,56,52,53,48],
        o:[69,63,62,66,72]},
  {cardname:"card281",
        b:[7,12,5,2,11],
        i:[16,29,25,28,24],
        n:[42,36,"free",33,44],
        g:[47,50,58,52,51],
        o:[72,73,66,75,67]},
  {cardname:"card282",
       b:[9,4,3,15,1],
       i:[29,19,22,23,30],
       n:[43,45,"free",39,35],
       g:[59,52,58,54,46],
       o:[69,68,65,64,75]},
  {cardname:"card283",
        b:[8,7,1,9,13],
        i:[20,30,24,21,26],
        n:[40,41,"free",37,45],
        g:[59,52,51,53,58],
        o:[67,66,61,70,64]},
  {cardname:"card284",
        b:[2,3,13,11,8],
        i:[26,17,23,16,28],
        n:[36,31,"free",32,43],
        g:[48,53,49,47,52],
        o:[74,65,63,73,71]},
  {cardname:"card285",
        b:[15,8,6,10,9],
        i:[28,22,19,30,21],
        n:[39,37,"free",38,44],
        g:[55,52,48,46,58],
        o:[64,74,73,68,71]},
  {cardname:"card286",
       b:[9,15,8,14,6],
       i:[28,19,16,23,20],
       n:[35,39,"free",44,33],
       g:[59,52,48,51,46],
       o:[66,69,64,61,70]},
  {cardname:"card287",
      b:[13,8,6,11,7],
      i:[17,30,18,27,23],
      n:[31,38,"free",42,40],
      g:[60,54,58,52,53],
      o:[71,69,72,64,67]},
  {cardname:"card288",
       b:[15,8,10,7,13],
       i:[19,17,18,29,21],
       n:[44,40,"free",31,38],
       g:[57,56,50,46,55],
       o:[63,75,73,74,67]},
  {cardname:"card289",
        b:[8,10,13,6,11],
        i:[26,29,22,24,21],
        n:[38,37,"free",45,39],
        g:[55,48,49,50,53],
        o:[65,75,68,71,62]},
  {cardname:"card290",
        b:[8,15,5,10,13],
        i:[20,22,19,16,27],
        n:[37,40,"free",32,43],
        g:[58,50,49,59,56],
        o:[70,61,64,62,67]},
  {cardname:"card291",
         b:[2,12,3,1,6],
         i:[23,16,20,18,21],
         n:[34,42,"free",36,44],
         g:[59,47,60,50,48],
         o:[71,70,64,74,66]},
  {cardname:"card292",
       b:[11,1,14,7,3],
       i:[23,16,20,18,21],
       n:[32,31,"free",44,40],
       g:[46,58,48,57,54],
       o:[63,64,67,75,70]},
  {cardname:"card293",
        b:[5,6,13,9,4],
        i:[20,28,30,27,22],
        n:[34,39,"free",40,33],
        g:[49,58,59,53,54],
        o:[65,62,75,73,69]},
  {cardname:"card294",
         b:[2,11,6,12,4],
         i:[16,25,29,24,23],
         n:[32,34,"free",41,43],
         g:[57,49,59,53,51],
         o:[72,62,74,75,66]},
  {cardname:"card295",
        b:[13,8,10,3,5],
        i:[28,20,26,17,29],
        n:[37,42,"free",36,32],
        g:[51,49,58,46,56],
        o:[64,63,65,71,69]},
  {cardname:"card296",
        b:[6,15,8,5,13],
        i:[21,16,20,28,26],
        n:[43,34,"free",33,39],
        g:[52,53,50,49,46],
        o:[65,70,61,66,71]},
  {cardname:"card297",
       b:[9,8,3,5,12],
       i:[25,18,19,28,30],
       n:[35,44,"free",37,31],
       g:[47,55,57,48,49],
       o:[71,61,69,73,72]},
  {cardname:"card298",
        b:[6,2,9,15,4],
        i:[26,24,17,27,21],
        n:[41,39,"free",43,32],
        g:[57,48,60,56,46],
        o:[66,68,61,62,72]},
  {cardname:"card299",
        b:[11,9,4,8,14],
        i:[24,30,22,26,20],
        n:[38,32,"free",34,42],
        g:[51,52,53,48,59],
        o:[61,72,69,65,73]},
  {cardname:"card300",
       b:[8,12,9,6,15],
       i:[26,25,21,16,17],
       n:[43,44,"free",37,35],
       g:[57,53,54,59,56],
       o:[71,66,72,73,68]},
  {cardname:"card301",
        b:[8,9,7,12,2],
        i:[28,24,26,17,20],
        n:[41,31,"free",45,40],
        g:[51,47,60,58,55],
        o:[62,68,73,64,70]},
  
  {cardname:"card302",
        b:[13,11,1,4,14],
        i:[17,21,25,22,28],
        n:[35,36,"free",41,31],
        g:[49,50,51,52,54],
        o:[64,63,61,73,75]},
  {cardname:"card303",
         b:[14,8,4,13,3],
         i:[26,19,16,21,29],
         n:[40,39,"free",37,31],
         g:[46,54,49,48,59],
         o:[73,61,66,68,65]},
  {cardname:"card304",
         b:[8,3,9,13,11],
         i:[28,20,23,17,27],
         n:[33,38,"free",44,45],
         g:[48,50,51,57,47],
         o:[64,62,61,75,74]},
  {cardname:"card305",
        b:[15,12,1,5,3],
        i:[28,17,25,27,20],
        n:[31,39,"free",43,32],
        g:[46,54,48,53,47],
        o:[64,71,62,74,73]},
  {cardname:"card306",
         b:[3,13,5,1,2],
         i:[16,30,24,29,25],
         n:[32,40,"free",43,36],
         g:[53,55,52,58,46],
         o:[68,74,61,63,75]},
  {cardname:"card307",
        b:[14,6,12,4,2],
        i:[21,22,16,17,19],
        n:[39,44,"free",38,42],
        g:[59,56,51,50,49],
        o:[67,70,75,63,62]},
  {cardname:"card308",
         b:[2,5,11,7,13],
         i:[19,17,22,16,27],
         n:[33,37,"free",34,38],
         g:[49,46,51,50,54],
         o:[61,74,75,67,64]},
  {cardname:"card309",
      b:[9,15,7,13,11],
      i:[25,20,27,24,30],
      n:[44,42,"free",40,38],
      g:[54,57,49,46,48],
      o:[72,71,66,69,65]},
  {cardname:"card310",
          b:[12,10,3,4,2],
          i:[16,21,27,30,18],
          n:[41,39,"free",31,34],
          g:[48,57,46,59,55],
          o:[67,68,69,73,65]},
  
          {cardname:"card311",
          b:[4,9,14,11,12],
          i:[27,22,30,16,20],
          n:[31,33,"free",43,36],
          g:[52,58,47,48,60],
          o:[74,75,62,67,61]},
     {cardname:"card312",
          b:[12,4,9,10,3],
          i:[29,16,30,22,19],
          n:[38,45,"free",32,31],
          g:[59,57,55,56,52],
          o:[71,63,65,74,68]},
     {cardname:"card313",
           b:[8,13,12,7,2],
           i:[18,19,17,25,16],
           n:[42,38,"free",37,34],
           g:[53,57,46,50,55],
           o:[74,69,72,71,63]},
     {cardname:"card314",
           b:[13,8,2,3,11],
           i:[28,29,27,23,19],
           n:[39,35,"free",36,37],
           g:[49,56,50,52,46],
           o:[74,63,61,70,65]},
     {cardname:"card315",
           b:[2,12,9,4,8],
           i:[18,26,27,28,30],
           n:[41,36,"free",42,37],
           g:[60,52,57,46,59],
           o:[74,70,67,75,68]},
     {cardname:"card316",
           b:[8,4,12,13,5],
           i:[25,20,16,27,17],
           n:[40,38,"free",43,32],
           g:[49,59,55,50,46],
           o:[62,70,66,75,74]},
     {cardname:"card317",
           b:[13,1,5,8,3],
           i:[26,16,27,19,25],
           n:[33,35,"free",34,38],
           g:[52,54,51,58,56],
           o:[71,63,66,62,64]},
     {cardname:"card318",
          b:[10,3,13,7,15],
          i:[18,24,26,21,17],
          n:[39,41,"free",40,34],
          g:[49,46,51,60,53],
          o:[64,67,65,68,73]},
     {cardname:"card319",
          b:[15,13,6,5,9],
          i:[19,30,26,20,21],
          n:[41,36,"free",35,38],
          g:[54,49,57,52,50],
          o:[63,66,61,70,73]},
     {cardname:"card320",
          b:[3,11,4,10,2],
          i:[24,26,20,19,17],
          n:[36,39,"free",45,33],
          g:[56,60,47,52,50],
          o:[68,61,72,63,62]},
     {cardname:"card321",
          b:[8,6,13,2,9],
          i:[20,26,28,21,23],
          n:[36,40,"free",32,41],
          g:[55,52,59,46,60],
          o:[71,70,68,63,61]},
     {cardname:"card322",
          b:[2,15,7,9,10],
          i:[27,26,20,19,30],
          n:[35,37,"free",42,33],
          g:[53,46,47,55,49],
          o:[70,62,68,73,61]},
     {cardname:"card323",
           b:[8,14,5,15,2],
           i:[25,29,28,23,19],
           n:[36,39,"free",41,34],
           g:[49,46,51,52,57],
           o:[62,67,73,70,71]},
     {cardname:"card324",
          b:[13,5,7,4,9],
          i:[18,25,26,23,19],
          n:[44,31,"free",36,37],
          g:[55,49,52,53,57],
          o:[70,63,73,61,68]},
     {cardname:"card325",
         b:[10,15,7,5,3],
         i:[16,17,30,18,29],
         n:[42,44,"free",41,43],
         g:[49,57,58,60,50],
         o:[63,72,68,70,64]},
     {cardname:"card326",
         b:[4,15,8,12,13],
         i:[20,21,24,26,19],
         n:[40,33,"free",39,43],
         g:[53,58,51,48,49],
         o:[65,70,68,64,72]},
     {cardname:"card327",
         b:[3,9,4,15,13],
         i:[18,26,23,28,25],
         n:[39,32,"free",41,34],
         g:[53,57,56,49,59],
         o:[70,72,73,66,67]},
     {cardname:"card328",
          b:[14,2,9,13,11],
          i:[19,22,23,30,20],
          n:[31,38,"free",36,35],
          g:[46,51,52,48,50],
          o:[75,74,63,70,66]},
     {cardname:"card329",
            b:[2,13,8,9,10],
            i:[18,16,25,17,30],
            n:[31,32,"free",36,40],
            g:[49,51,60,54,55],
            o:[69,67,63,64,68]},
     {cardname:"card330",
            b:[7,10,13,2,14],
            i:[27,28,21,19,25],
            n:[35,40,"free",34,31],
            g:[46,52,51,53,55],
            o:[72,69,68,66,63]},
     {cardname:"card331",
            b:[13,8,5,1,6],
            i:[29,24,28,27,16],
            n:[34,37,"free",38,36],
            g:[47,57,51,55,50],
            o:[74,66,62,67,70]},
     {cardname:"card332",
           b:[1,13,5,4,6],
           i:[19,22,28,16,18],
           n:[32,44,"free",41,35],
           g:[53,58,54,47,60],
           o:[69,64,65,68,67]},
     {cardname:"card333",
           b:[2,14,8,7,5],
           i:[19,26,22,30,27],
           n:[38,32,"free",36,34],
           g:[52,51,58,48,59],
           o:[67,74,65,62,70]},
     {cardname:"card334",
           b:[7,11,5,15,2],
           i:[20,17,26,18,27],
           n:[44,34,"free",33,32],
           g:[52,57,56,51,55],
           o:[66,73,63,67,75]},
     {cardname:"card335",
           b:[13,12,1,10,4],
           i:[29,16,24,17,18],
           n:[43,36,"free",32,45],
           g:[55,58,60,47,54],
           o:[62,70,61,64,65]},
     {cardname:"card336",
          b:[8,3,9,14,15],
          i:[20,19,22,27,25],
          n:[37,42,"free",35,33],
          g:[57,48,53,60,54],
          o:[70,69,61,74,65]},
     {cardname:"card337",
           b:[11,13,3,5,8],
           i:[18,26,27,28,30],
           n:[42,33,"free",43,32],
           g:[52,58,55,51,48],
           o:[67,68,65,71,69]},
     {cardname:"card338",
          b:[9,3,13,1,7],
          i:[28,27,30,21,17],
          n:[34,38,"free",36,40],
          g:[56,51,48,46,59],
          o:[69,67,71,75,73]},
     {cardname:"card339",
          b:[7,11,10,4,8],
          i:[16,18,25,27,30],
          n:[39,41,"free",33,36],
          g:[48,58,46,56,53],
          o:[68,69,64,66,70]},
     {cardname:"card340",
          b:[3,6,15,2,7],
          i:[26,18,29,21,19],
          n:[33,32,"free",31,35],
          g:[60,53,52,48,59],
          o:[66,69,61,70,65]},
     {cardname:"card341",
         b:[9,12,1,14,7],
         i:[16,26,24,18,27],
         n:[45,42,"free",33,35],
         g:[52,60,57,55,53],
         o:[66,73,70,68,62]},
     {cardname:"card342",
         b:[10,14,15,2,4],
         i:[29,18,20,26,21],
         n:[40,33,"free",34,44],
         g:[51,49,56,53,57],
         o:[73,66,67,62,71]},
     {cardname:"card343",
         b:[7,4,3,11,9],
         i:[19,17,16,22,27],
         n:[35,39,"free",36,38],
         g:[59,51,46,55,57],
         o:[71,67,69,75,62]},
     {cardname:"card344",
          b:[9,10,6,7,8],
          i:[27,26,24,23,18],
          n:[45,37,"free",42,31],
          g:[50,56,55,60,49],
          o:[70,65,74,75,62]},
     {cardname:"card345",
          b:[9,7,11,2,8],
          i:[21,19,26,18,25],
          n:[43,32,"free",33,37],
          g:[55,54,59,50,58],
          o:[63,69,65,75,62]},
     {cardname:"card346",
           b:[7,5,3,13,9],
           i:[16,30,25,23,29],
           n:[43,41,"free",37,38],
           g:[52,50,53,60,47],
           o:[71,63,65,69,68]},
     {cardname:"card347",
          b:[11,9,2,7,1],
          i:[16,26,30,29,20],
          n:[38,37,"free",44,42],
          g:[48,52,53,60,58],
          o:[70,65,67,62,74]},
     {cardname:"card348",
          b:[7,2,1,4,10],
          i:[18,20,19,17,27],
          n:[33,32,"free",35,38],
          g:[46,58,48,55,49],
          o:[67,73,62,69,68]},
     {cardname:"card349",
            b:[15,9,3,10,4],
            i:[16,30,20,22,19],
            n:[39,36,"free",40,31],
            g:[48,51,49,56,46],
            o:[74,75,65,66,73]},
     {cardname:"card350",
            b:[14,2,15,4,5],
            i:[26,16,18,28,29],
            n:[36,33,"free",32,43],
            g:[48,58,60,46,57],
            o:[61,69,74,70,64]},
     {cardname:"card351",
          b:[8,15,12,5,6],
          i:[30,18,20,29,27],
          n:[45,37,"free",33,42],
          g:[60,59,55,47,56],
          o:[71,64,67,68,72]},
     {cardname:"card352",
          b:[14,4,1,5,11],
          i:[26,24,20,18,17],
          n:[35,33,"free",32,41],
          g:[52,48,60,51,59],
          o:[65,66,68,72,64]},
     {cardname:"card353",
            b:[13,9,3,4,15],
            i:[16,17,24,18,27],
            n:[45,42,"free",32,40],
            g:[53,60,47,58,55],
            o:[71,66,65,64,73]},
     {cardname:"card354",
           b:[9,4,11,7,1],
           i:[18,25,28,19,21],
           n:[43,44,"free",36,35],
           g:[58,51,60,47,53],
           o:[63,71,72,66,69]},
     {cardname:"card355",
           b:[6,11,3,10,5],
           i:[30,20,26,18,17],
           n:[31,45,"free",42,37],
           g:[46,52,59,57,48],
           o:[71,69,66,73,72]},
     {cardname:"card356",
          b:[2,7,14,5,6],
          i:[30,18,27,24,22],
          n:[44,37,"free",43,35],
          g:[59,60,55,49,58],
          o:[66,69,64,67,62]},
     {cardname:"card357",
           b:[7,10,8,12,9],
           i:[18,19,24,27,22],
           n:[37,41,"free",36,43],
           g:[48,51,47,46,60],
           o:[70,72,62,66,71]},
     {cardname:"card358",
           b:[9,8,2,12,3],
           i:[30,21,27,29,26],
           n:[43,41,"free",39,38],
           g:[47,59,50,52,60],
           o:[72,61,73,63,71]},
     {cardname:"card359",
           b:[9,14,13,1,2],
           i:[25,22,28,19,24],
           n:[32,34,"free",33,41],
           g:[60,57,51,47,59],
           o:[64,67,62,72,71]},
     {cardname:"card360",
           b:[12,3,1,14,5],
           i:[17,19,25,26,16],
           n:[36,39,"free",33,31],
           g:[49,50,52,51,47],
           o:[63,67,72,62,74]},
     {cardname:"card361",
           b:[6,12,9,3,5],
           i:[16,20,17,22,18],
           n:[43,41,"free",39,40],
           g:[47,49,51,59,55],
           o:[67,70,62,68,63]},
     {cardname:"card362",
          b:[11,7,10,6,1],
          i:[20,22,26,21,24],
          n:[37,41,"free",40,33],
          g:[59,54,53,49,48],
          o:[61,72,67,75,63]},
     {cardname:"card363",
           b:[13,1,12,2,5],
           i:[16,24,20,21,25],
           n:[43,38,"free",41,40],
           g:[52,47,57,54,60],
           o:[65,71,75,61,68]},
     {cardname:"card364",
           b:[2,3,11,14,15],
           i:[23,17,21,25,20],
           n:[43,42,"free",39,40],
           g:[50,57,60,58,46],
           o:[67,73,69,75,66]},
     {cardname:"card365",
            b:[14,2,6,3,1],
            i:[30,27,25,28,18],
            n:[32,36,"free",40,44],
            g:[58,51,54,47,46],
            o:[62,71,74,69,68]},
     {cardname:"card366",
           b:[13,8,7,1,10],
           i:[23,16,21,20,17],
           n:[35,39,"free",37,36],
           g:[59,51,56,58,49],
           o:[62,70,73,66,71]},
     {cardname:"card367",
           b:[8,5,3,12,7],
           i:[22,30,20,24,29],
           n:[35,31,"free",40,33],
           g:[48,53,57,55,59],
           o:[72,71,61,75,69]},
     {cardname:"card368",
           b:[12,5,3,2,6],
           i:[24,30,17,19,16],
           n:[35,33,"free",42,44],
           g:[56,51,53,46,47],
           o:[63,75,68,61,66]},
     {cardname:"card369",
          b:[1,13,15,9,6],
          i:[16,24,26,20,18],
          n:[37,33,"free",32,41],
          g:[53,57,47,56,46],
          o:[65,62,63,73,68]},
     {cardname:"card370",
          b:[1,3,15,5,13],
          i:[30,25,19,24,29],
          n:[33,36,"free",45,40],
          g:[46,47,51,54,48],
          o:[67,72,74,68,64]},
     {cardname:"card371",
          b:[9,12,7,1,15],
          i:[21,18,20,25,27],
          n:[34,35,"free",45,33],
          g:[50,53,51,55,60],
          o:[67,70,75,73,74]},
     {cardname:"card372",
          b:[3,5,1,4,7],
          i:[16,21,25,24,27],
          n:[31,32,"free",33,37],
          g:[48,57,56,46,54],
          o:[62,63,75,65,68]},
     {cardname:"card373",
          b:[12,15,8,3,2],
          i:[25,18,21,27,30],
          n:[43,36,"free",34,42],
          g:[49,52,53,54,58],
          o:[66,72,68,67,74]},
     {cardname:"card374",
          b:[10,5,2,3,6],
          i:[30,16,19,20,24],
          n:[32,44,"free",43,40],
          g:[58,46,49,53,59],
          o:[68,74,61,71,73]},
     {cardname:"card375",
          b:[14,12,2,4,3],
          i:[17,25,28,18,23],
          n:[38,43,"free",44,31],
          g:[59,46,51,49,54],
          o:[64,72,75,71,69]},
     {cardname:"card376",
         b:[1,3,5,8,11],
         i:[17,26,28,25,21],
         n:[31,43,"free",34,45],
         g:[57,49,53,59,46],
         o:[68,61,66,65,70]},
     {cardname:"card377",
          b:[8,1,15,12,5],
          i:[17,22,19,23,27],
          n:[43,39,"free",42,37],
          g:[51,55,47,48,56],
          o:[63,64,69,74,70]},
     {cardname:"card378",
          b:[6,2,3,15,11],
          i:[23,29,30,24,21],
          n:[42,43,"free",35,39],
          g:[47,58,49,55,54],
          o:[74,70,72,68,69]},
     {cardname:"card379",
          b:[10,2,5,4,6],
          i:[19,28,24,18,21],
          n:[41,43,"free",34,40],
          g:[53,47,54,56,57],
          o:[73,74,63,70,62]},
     {cardname:"card380",
          b:[10,5,2,8,4],
          i:[21,19,26,22,28],
          n:[39,41,"free",40,36],
          g:[49,54,53,59,51],
          o:[73,69,65,72,75]},
     {cardname:"card381",
          b:[6,3,4,15,13],
          i:[27,24,16,29,25],
          n:[41,36,"free",44,34],
          g:[53,52,54,58,56],
          o:[71,62,70,64,68]},
     {cardname:"card382",
           b:[8,12,15,9,3],
           i:[20,26,29,18,21],
           n:[34,40,"free",44,38],
           g:[53,55,60,58,50],
           o:[72,61,66,64,75]},
     {cardname:"card383",
           b:[13,1,4,7,12],
           i:[16,26,29,21,25],
           n:[40,31,"free",36,43],
           g:[54,51,46,52,55],
           o:[74,68,69,75,67]},
     {cardname:"card384",
           b:[6,13,14,7,1],
           i:[29,24,18,22,23],
           n:[33,44,"free",45,35],
           g:[55,52,58,60,59],
           o:[69,67,75,63,68]},
     {cardname:"card385",
          b:[10,13,6,7,15],
          i:[28,24,17,26,20],
          n:[38,33,"free",34,39],
          g:[54,55,60,50,47],
          o:[65,67,75,74,63]},
     {cardname:"card386",
          b:[7,14,2,1,6],
          i:[28,18,30,24,16],
          n:[42,45,"free",32,38],
          g:[57,59,46,60,50],
          o:[63,72,70,66,61]},
     {cardname:"card387",
          b:[11,5,2,4,13],
          i:[23,30,26,18,22],
          n:[35,39,"free",45,34],
          g:[60,58,55,57,56],
          o:[67,63,73,65,64]},
     {cardname:"card388",
         b:[5,7,15,2,12],
         i:[21,26,19,23,30],
         n:[34,44,"free",42,35],
         g:[58,47,48,49,50],
         o:[74,62,75,67,70]},
     {cardname:"card389",
           b:[11,5,12,7,6],
           i:[29,30,28,27,24],
           n:[36,38,"free",33,45],
           g:[60,59,55,54,50],
           o:[70,61,63,71,65]},
     {cardname:"card390",
           b:[3,1,6,8,10],
           i:[22,19,18,17,30],
           n:[43,44,"free",34,31],
           g:[60,58,57,54,48],
           o:[68,64,72,67,63]},
     {cardname:"card391",
          b:[13,11,9,14,12],
          i:[24,19,26,18,27],
          n:[36,31,"free",37,40],
          g:[53,50,56,58,54],
          o:[62,66,63,73,61]},
     {cardname:"card392",
          b:[6,13,11,1,7],
          i:[26,19,17,18,28],
          n:[39,32,"free",41,38],
          g:[55,56,57,48,52],
          o:[72,74,66,62,75]},
     {cardname:"card393",
          b:[1,7,6,5,15],
          i:[19,26,25,30,21],
          n:[39,41,"free",45,42],
          g:[59,53,46,57,50],
          o:[70,73,61,74,71]},
     {cardname:"card394",
            b:[8,10,1,12,14],
            i:[25,18,30,21,22],
            n:[32,37,"free",45,36],
            g:[49,48,50,54,46],
            o:[62,74,67,71,66]},
     {cardname:"card395",
          b:[12,2,1,5,8],
          i:[30,17,20,29,27],
          n:[35,31,"free",36,42],
          g:[58,60,55,50,46],
          o:[70,61,65,67,74]},
     {cardname:"card396",
          b:[14,13,15,11,4],
          i:[23,29,21,24,22],
          n:[36,37,"free",35,44],
          g:[58,56,55,51,48],
          o:[64,68,61,65,72]},
     {cardname:"card397",
          b:[2,14,12,3,10],
          i:[17,19,27,23,22],
          n:[42,43,"free",40,45],
          g:[53,58,56,47,51],
          o:[71,72,67,64,75]},
     {cardname:"card398",
           b:[1,5,11,4,10],
           i:[25,30,18,22,23],
           n:[32,38,"free",33,44],
           g:[53,48,60,58,57],
           o:[67,64,68,62,75]},
     {cardname:"card399",
          b:[14,4,15,6,10],
          i:[30,20,26,23,29],
          n:[33,45,"free",35,31],
          g:[60,56,51,52,57],
          o:[72,74,62,61,70]},
     {cardname:"card400",
          b:[3,15,8,10,12],
          i:[27,30,20,29,25],
          n:[36,38,"free",39,32],
          g:[47,51,50,60,54],
          o:[61,69,74,71,72]},
     {cardname:"card401",
          b:[3,6,5,15,13],
          i:[19,29,23,22,17],
          n:[35,37,"free",43,44],
          g:[53,52,55,49,54],
          o:[69,74,65,61,64]},
     {cardname:"card402",
          b:[11,15,6,13,3],
          i:[16,28,25,18,20],
          n:[32,33,"free",35,36],
          g:[59,54,46,52,55],
          o:[67,71,65,64,61]},
     {cardname:"card403",
          b:[10,11,8,7,9],
          i:[22,16,26,20,19],
          n:[31,33,"free",37,40],
          g:[49,58,47,52,53],
          o:[63,62,73,74,68]},
     {cardname:"card404",
          b:[13,2,7,5,14],
          i:[23,20,18,27,24],
          n:[41,45,"free",43,37],
          g:[58,47,60,57,49],
          o:[65,67,73,68,64]},
     {cardname:"card405",
          b:[3,2,11,15,4],
          i:[26,20,27,22,16],
          n:[42,31,"free",38,44],
          g:[53,50,56,51,58],
          o:[64,61,63,71,72]},
  
          {cardname:"card406",
          b:[14,15,13,10,4],
           i:[26,23,17,27,24],
           n:[36,45,"free",31,32],
           g:[57,48,56,55,49],
           o:[64,62,61,65,66]},
      {cardname:"card407",
         b:[12,9,11,10,4],
         i:[25,28,19,24,20],
         n:[31,39,"free",33,35],
         g:[48,49,60,57,47],
         o:[65,62,70,66,63]},
      {cardname:"card408",
          b:[11,3,12,4,5],
          i:[19,16,30,27,23],
          n:[43,39,"free",35,37],
          g:[48,47,59,51,53],
          o:[66,73,70,72,71]},
      {cardname:"card409",
           b:[7,4,13,12,5],
           i:[30,25,23,26,21],
           n:[32,44,"free",43,38],
           g:[49,57,46,55,51],
           o:[63,61,67,74,68]},
      {cardname:"card410",
           b:[8,9,2,11,4],
           i:[17,21,24,20,19],
           n:[41,45,"free",42,38],
           g:[46,56,48,58,55],
           o:[74,62,72,75,63]},
      {cardname:"card411",
              b:[14,10,1,8,2],
              i:[22,20,27,28,29],
              n:[44,35,"free",40,36],
              g:[51,59,49,60,53],
              o:[62,70,61,67,72]},
      {cardname:"card412",
            b:[14,6,12,3,13],
            i:[30,26,22,29,28],
            n:[41,37,"free",43,44],
            g:[48,53,52,49,58],
            o:[69,71,74,61,65]},
      {cardname:"card413",
           b:[11,4,6,10,14],
           i:[30,19,27,22,21],
           n:[34,45,"free",35,44],
           g:[56,47,52,55,46],
           o:[69,61,71,67,72]},
      {cardname:"card414",
          b:[15,4,12,11,6],
          i:[29,25,27,22,28],
          n:[31,35,"free",37,42],
          g:[51,50,49,57,47],
          o:[67,62,63,74,64]},
      {cardname:"card415",
           b:[13,12,7,15,1],
           i:[17,25,28,24,27],
           n:[40,34,"free",36,31],
           g:[46,60,59,57,52],
           o:[66,64,68,72,63]},
      {cardname:"card416",
           b:[9,12,6,3,11],
           i:[17,30,21,22,23],
           n:[31,42,"free",37,32],
           g:[60,52,48,57,58],
           o:[65,68,74,73,63]},
      {cardname:"card417",
           b:[12,2,10,7,5],
           i:[29,20,25,21,28],
           n:[33,39,"free",42,41],
           g:[59,56,48,55,46],
           o:[65,64,62,70,73]},
      {cardname:"card418",
            b:[12,5,4,14,9],
            i:[21,27,25,20,30],
            n:[40,41,"free",45,33],
            g:[53,47,49,56,58],
            o:[69,68,74,66,65]},
      {cardname:"card419",
            b:[10,7,15,3,1],
            i:[27,26,16,21,30],
            n:[31,43,"free",37,39],
            g:[50,54,47,46,59],
            o:[72,71,66,64,65]},
      {cardname:"card420",
            b:[11,10,1,8,15],
            i:[30,21,23,25,22],
            n:[35,34,"free",33,41],
            g:[53,54,46,50,48],
            o:[70,75,65,72,61]},
      {cardname:"card421",
           b:[1,12,11,7,5],
           i:[24,28,17,30,29],
           n:[35,37,"free",36,32],
           g:[59,54,60,57,56],
           o:[70,72,74,62,69]},
      {cardname:"card422",
            b:[3,8,13,1,5],
            i:[26,19,17,25,27],
            n:[41,31,"free",42,34],
            g:[58,54,52,59,47],
            o:[72,62,68,75,65]},
      {cardname:"card423",
           b:[2,5,9,1,4],
           i:[24,20,26,17,29],
           n:[43,40,"free",44,41],
           g:[54,52,51,53,46],
           o:[68,73,65,74,62]},
      {cardname:"card424",
           b:[4,5,6,7,9],
           i:[30,22,27,25,21],
           n:[43,36,"free",42,44],
           g:[49,46,50,54,47],
           o:[69,74,62,68,75]},
      {cardname:"card425",
           b:[11,7,2,9,6],
           i:[24,16,20,30,19],
           n:[42,32,"free",39,41],
           g:[59,52,57,53,60],
           o:[63,65,73,71,70]},
      {cardname:"card426",
            b:[12,2,11,5,8],
            i:[18,22,17,23,16],
            n:[38,39,"free",42,40],
            g:[48,47,51,54,59],
            o:[64,72,74,75,62]},
      {cardname:"card427",
           b:[14,9,7,6,8],
           i:[16,29,22,26,19],
           n:[39,36,"free",43,37],
           g:[58,47,49,50,48],
           o:[65,62,66,72,74]},
      {cardname:"card428",
            b:[3,4,8,15,7],
            i:[17,22,21,19,29],
            n:[38,37,"free",42,32],
            g:[49,54,58,55,47],
            o:[69,75,66,74,70]},
      {cardname:"card429",
           b:[4,12,13,10,5],
           i:[23,20,18,25,19],
           n:[38,36,"free",45,35],
           g:[51,49,60,55,53],
           o:[74,64,70,63,71]},
      {cardname:"card430",
          b:[8,10,13,7,14],
          i:[20,27,30,24,28],
          n:[31,41,"free",45,42],
          g:[58,55,56,49,60],
          o:[72,64,74,70,71]},
      {cardname:"card431",
          b:[6,11,15,10,2],
          i:[22,18,19,25,29],
          n:[40,43,"free",33,32],
          g:[55,48,50,47,53],
          o:[72,67,69,63,64]},
      {cardname:"card432",
           b:[2,12,6,3,10],
           i:[17,28,29,19,25],
           n:[38,41,"free",33,35],
           g:[57,60,59,56,54],
           o:[62,64,66,61,73]},
  
  
  ]
  
    export default cards;
  