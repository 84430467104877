import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy } from 'firebase/firestore';

const db = getFirestore();
const GlowingButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(90deg, #6A1B9A, #8E24AA)',
  color: '#FFFFFF',
  borderRadius: '30px',
  padding: 3,
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '18px',
  boxShadow: '0 0 15px 5px rgba(142, 36, 170, 0.5)',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 0 20px 10px rgba(142, 36, 170, 0.7)',
    background: 'linear-gradient(90deg, #7B1FA2, #9C27B0)',
  },
  '&:active': {
    boxShadow: '0 0 10px 2px rgba(142, 36, 170, 0.7)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1c253a',
    },
    secondary: {
      main: '#00C9C8',
    },
    background: {
      paper: '#1c253a',
    },
    text: {
      primary: '#ffffff',
      secondary: '#00C9C8',
    },
  },
});

const MessageBubble = ({ message, sender, isOwnMessage }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isOwnMessage ? 'row' : 'row',
        alignItems: 'center',
        mb: 2,
        maxWidth: '100%', // Ensure the entire bubble doesn't exceed the viewport width
        wordBreak: 'break-word', // Break long words to avoid overflow
      }}
    >
      <Typography
        variant="body2"
        sx={{ 
          paddingRight: 2, 
          mb: 1, 
          fontWeight: 'bold', 
          color: 'darkturquoise',
          flexShrink: 0, // Ensure the sender's name doesn't shrink
        }}
      >
        {sender}
      </Typography>
      <Box
        sx={{
          maxWidth: '60%',
          paddingLeft: 2,
          p: 1,
          borderRadius: '10px',
          backgroundColor: isOwnMessage ? 'grey.300' : 'grey.300',
          color: isOwnMessage ? 'black' : 'black',
          wordWrap: 'break-word', // Ensure text wraps within the container
        }}
      >
        <Typography variant="body1">{message}</Typography>
      </Box>
    </Box>
  );
};

const ChatInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#1c253a',
  position: 'fixed',
  bottom: 6,
  paddingBottom: 50,
  zIndex: 1000,
  borderTop: `1px solid ${theme.palette.primary.main}`,
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  marginRight: theme.spacing(2),
  backgroundColor: '#2c2f48',
  borderRadius: '10px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  input: {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5),
  },
}));

const GroupChat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showInput, setShowInput] = useState(true); // State to manage input visibility
  const username = localStorage.getItem('username'); // Fetch the username once
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const messagesQuery = query(collection(db, 'messages'), orderBy('timestamp', 'asc'));
    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const messages = snapshot.docs.map(doc => doc.data());
      setMessages(messages);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY < 150) { // Adjust this threshold as needed
        setShowInput(false);
      } else {
        setShowInput(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSendMessage = async () => {
    if (username && newMessage.trim() !== '') {
      await addDoc(collection(db, 'messages'), {
        sender: username,
        message: newMessage,
        isOwnMessage: true,
        timestamp: new Date(),
      });

      setNewMessage('');
    } else {
      console.error('Username not found or message is empty.');
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100vw',
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#1c253a',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            p: 2,
            paddingBottom: '200px',
          }}
        >
          {messages.map((msg, index) => (
            <MessageBubble
              key={index}
              sender={msg.sender}
              message={msg.message}
              isOwnMessage={msg.sender === username} // Check if the message is from the logged-in user
            />
          ))}
          <div ref={messagesEndRef} /> {/* Empty div to scroll to */}
        </Box>

        {showInput && (
          <ChatInputContainer>
            <CustomTextField
              variant="outlined"
              placeholder="Message Text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <GlowingButton onClick={handleSendMessage}>
              <Typography fontWeight={'bold'} paddingLeft={3} paddingRight={3} paddingTop={1.5} paddingBottom={1.5}>
                Send
              </Typography>
            </GlowingButton>
          </ChatInputContainer>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default GroupChat;
