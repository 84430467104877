import React, { useState } from 'react';
import { Button, Typography, Paper, Box, TextField, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import SkullIcon from '@mui/icons-material/EmojiFlags'; // Replace with the desired icon
import MuiAlert from '@mui/material/Alert'; // Import Alert for toast notifications
import cards from './constant'
import ResponsiveAppBar from './appbar';
import { OrbitSpace } from 'orbit-space';

const CellButton = styled(Button)(({ theme, special }) => ({
  width: '100%',
  height: '100%',
  minWidth: '70px',
  minHeight: '70px',
  backgroundColor: special ? '#8e44ad' : '#ecf0f1',
  color: special ? 'white' : 'black',
  '&:hover': {
    backgroundColor: special ? '#732d91' : '#bdc3c7',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '70px',
    minHeight: '70px',
  },
}));

const colors = ['#E53935', '#F57C00', '#FBC02D', '#43A047', '#1E88E5'];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BingoCardPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm1, setSearchTerm1] = useState('');
  const [filteredCard, setFilteredCard] = useState(null);
  const [filteredCard1, setFilteredCard1] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [showSearch1, setShowSearch1] = useState(true);

  // State to track clicked cells
  const [clickedCells, setClickedCells] = useState({});
  const [clickedCells1, setClickedCells1] = useState({});

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchChange1 = (event) => {
    setSearchTerm1(event.target.value);
  };

  const handleSearchClick = () => {
    const card = cards.find(card => card.cardname === `card${searchTerm}`);
    if (card) {
      setFilteredCard(card);
      setShowSearch(false);
      setOpenSnackbar(false);
      setClickedCells({}); // Reset clicked cells
    } else {
      setFilteredCard(null);
      setOpenSnackbar(true);
    }
  };

  const handleSearchClick1 = () => {
    const card = cards.find(card => card.cardname === `card${searchTerm1}`);
    if (card) {
      setFilteredCard1(card);
      setShowSearch1(false);
      setOpenSnackbar(false);
      setClickedCells1({}); // Reset clicked cells
    } else {
      setFilteredCard1(null);
      setOpenSnackbar(true);
    }
  };

  const handleBackClick = () => {
    setShowSearch(true);
    setFilteredCard(null);
  };

  const handleBackClick1 = () => {
    setShowSearch1(true);
    setFilteredCard1(null);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCellClick = (row, col) => {
    setClickedCells(prev => ({
      ...prev,
      [`${row}-${col}`]: !prev[`${row}-${col}`]
    }));
  };

  const handleCellClick1 = (row, col) => {
    setClickedCells1(prev => ({
      ...prev,
      [`${row}-${col}`]: !prev[`${row}-${col}`]
    }));
  };

  return (
    <OrbitSpace>
      <ResponsiveAppBar />
      <Typography variant="h4" gutterBottom align="center">
        Bingo Card
      </Typography>
      {showSearch ? (
        <Box display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
          <TextField
            label="Search Card Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={handleSearchChange}
            type="text"
            sx={{ backgroundColor: 'white' }}
          />
          <Button variant="contained" color="primary" onClick={handleSearchClick}>
            Search
          </Button>
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center" marginBottom={2}>
            {['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
              <Box
                key={index}
                padding={1.5}
                sx={{
                  background: colors[index],
                  borderRadius: '8px',
                  margin: '0 2px',
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: 'white',
                    width: '50px',
                    textAlign: 'center',
                  }}
                >
                  {letter}
                </Typography>
              </Box>
            ))}
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
              </TableHead>
              <TableBody>
                {Array.from({ length: 5 }).map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {[filteredCard.b, filteredCard.i, filteredCard.n, filteredCard.g, filteredCard.o].map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        align="center"
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: clickedCells[`${rowIndex}-${colIndex}`] ? '#8e44ad' : column[rowIndex] === 'free' ? '#8e44ad' : '#ecf0f1',
                          color: clickedCells[`${rowIndex}-${colIndex}`] || column[rowIndex] === 'free' ? 'white' : 'black',
                          fontSize: '1.2rem', // Set font size to enormous
                        }}
                        onClick={() => handleCellClick(rowIndex, colIndex)}
                      >
                        {column[rowIndex] === 'free' ? <SkullIcon fontSize="small" /> : column[rowIndex]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Button variant="outlined" onClick={handleBackClick}>
              Back
            </Button>
          </Box>
        </>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          Card not found or invalid input
        </Alert>
      </Snackbar>
      <Divider />
      {showSearch1 ? (
        <Box display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
          <TextField
            label="Search Card Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm1}
            onChange={handleSearchChange1}
            type="text"
            sx={{ backgroundColor: 'white' }}
          />
          <Button variant="contained" color="primary" onClick={handleSearchClick1}>
            Search
          </Button>
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center" marginBottom={2}>
            {['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
              <Box
                key={index}
                padding={1.5}
                sx={{
                  background: colors[index],
                  borderRadius: '8px',
                  margin: '0 2px',
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: 'white',
                    width: '50px',
                    textAlign: 'center',
                  }}
                >
                  {letter}
                </Typography>
              </Box>
            ))}
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
              </TableHead>
              <TableBody>
                {Array.from({ length: 5 }).map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {[filteredCard1.b, filteredCard1.i, filteredCard1.n, filteredCard1.g, filteredCard1.o].map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        align="center"
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: clickedCells1[`${rowIndex}-${colIndex}`] ? '#8e44ad' : column[rowIndex] === 'free' ? '#8e44ad' : '#ecf0f1',
                          color: clickedCells1[`${rowIndex}-${colIndex}`] || column[rowIndex] === 'free' ? 'white' : 'black',
                          fontSize: '1.2rem', // Set font size to enormous
                        }}
                        onClick={() => handleCellClick1(rowIndex, colIndex)}
                      >
                        {column[rowIndex] === 'free' ? <SkullIcon fontSize="small" /> : column[rowIndex]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Button variant="outlined" onClick={handleBackClick1}>
              Back
            </Button>
          </Box>
        </>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          Card not found or invalid input
        </Alert>
      </Snackbar>
    </OrbitSpace>
  );
};

export default BingoCardPage;
