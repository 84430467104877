// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBIm9dPN1xWRkLO61tZeXqpcDWHeJC_nKo",
    authDomain: "onlinebingo-7c139.firebaseapp.com",
    projectId: "onlinebingo-7c139",
    storageBucket: "onlinebingo-7c139.appspot.com",
    messagingSenderId: "764647175597",
    appId: "1:764647175597:web:7b9ba133254a1e33b41d24",
    measurementId: "G-B66YPLPWPM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const functions = getFunctions(app);



export { auth, app, functions };
