import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ResponsiveAppBar from './appbar';
import { CardContent, Card, Typography, Stack, Divider, Button } from '@mui/material';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { app } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DepositDialog from './deposit';
import WithdrawDialog from './withdraw';
import img from '../assets/SP-logo-CNRob7co.jpg';
import img2 from '../assets/logo.png';
import WithdrawTable from './transactionTable';
import DepositTable from './transactionTableDeposit';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TransactionTabs() {
  const [value, setValue] = useState(0);
  const [uid, setUid] = useState(null);
  const db = getFirestore(app);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionID, setSessionID] = useState('');
  const navigate = useNavigate();
  const [playersCount, setPlayersCount] = useState({});
  const [depositDialogOpen, setDepositDialogOpen] = useState(false);
  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
  const [user, setUser] = useState({
    uid: localStorage.getItem('uid'),
    username: localStorage.getItem('username')
  });

  useEffect(() => {
    const storedUid = localStorage.getItem('uid');
    if (storedUid) {
      setUid(storedUid);
    }
  }, []);

  const generateNonce = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDepositDialog = () => {
    setDepositDialogOpen(true);
  };

  const handleOpenWithdrawDialog = () => {
    setWithdrawDialogOpen(true);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ResponsiveAppBar />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Withdraw" {...a11yProps(0)} />
          <Tab label="Deposit" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <WithdrawTable/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
       <DepositTable/>
      </CustomTabPanel>
    
    
    </Box>
  );
}