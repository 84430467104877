import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import theme from './theme'; // Ensure you have the correct path to your theme
import CustomBingoText from './customBingoText';

// Styled components
const BingoNumbersContainer = styled(Stack)(({ isSmallScreen }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: 0,
  width: isSmallScreen ? '100%' : '100vh',
  background: 'Transparent'
}));

const BingoTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
});

const BingoCell = styled('td')(({ isCalled, isLastCalled, isWhite }) => ({
  width: '50px',
  height: '20px',
  textAlign: 'center',
  verticalAlign: 'middle',
  color: isCalled ? 'white' : '#3c3c3c',
  backgroundColor: isLastCalled ? (isWhite ? 'white' : '#3c3c3c') : 'none',
  border: '1px solid #ddd',
}));

// Firebase data fetching
const fetchCalledNumbers = async (setCalledNumbers) => {
  const database = getDatabase();
  try {
    const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
    const snapshot2 = await get(gameSessionNumberRef);
    const currentGameSessionNumber = snapshot2.val();

    if (currentGameSessionNumber) {
      const gameSessionRef = ref(database, `BingoGames/Game${currentGameSessionNumber}/calledNumbers`);
      onValue(gameSessionRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          let formattedCalledNumbers = { b: [], i: [], n: [], g: [], o: [] };

          Object.keys(data).forEach(category => {
            Object.values(data[category]).forEach(number => {
              if (!formattedCalledNumbers[category].includes(number)) {
                formattedCalledNumbers[category].push(number);
              }
            });
          });

          setCalledNumbers(formattedCalledNumbers);
        }
      });
    }
  } catch (error) {
    console.error("Error fetching called numbers:", error);
  }
};

// Main component
const BingoNumber = React.memo(({ numbers }) => {
  const [calledNumbers, setCalledNumbers] = useState({ b: [], i: [], n: [], g: [], o: [] });
  const [isWhite, setIsWhite] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchCalledNumbers(setCalledNumbers);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsWhite(prevState => !prevState);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <BingoNumbersContainer isSmallScreen={isSmallScreen}>
      <BingoTable>
        <tbody>
          {numbers.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <th>
                <CustomBingoText>{String.fromCharCode(66 + rowIndex)}</CustomBingoText>
              </th>
              {row.map((number, colIndex) => {
                const category = number <= 15 ? 'b' : number <= 30 ? 'i' : number <= 45 ? 'n' : number <= 60 ? 'g' : 'o';
                const isCalled = calledNumbers[category].includes(number);
                const formattedNumber = number < 10 ? `0${number}` : number;

                return (
                  <BingoCell
                    key={number}
                    isCalled={isCalled}
                    isLastCalled={false} // You need to add logic to handle `isLastCalled`
                    isWhite={isWhite}
                  >
                    <Typography fontWeight={isCalled ? 'bold' : 'normal'}>{formattedNumber}</Typography>
                  </BingoCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </BingoTable>
    </BingoNumbersContainer>
  );
});

export default BingoNumber;
