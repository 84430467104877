import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { OrbitSpace } from 'orbit-space';
import img from './Glitter.png';
import { getDatabase, ref, get, set } from 'firebase/database';
import { toast } from 'react-toastify';
import PlayerList from './playerList';
import ResponsiveAppBar from './appbar';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const NumberCard = styled(Card)(({ theme, selected }) => ({
  color: selected ? 'white' : 'black',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    cursor: 'pointer',
  },
}));
const GlowingButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(90deg, #6A1B9A, #8E24AA)',
  color: '#FFFFFF',
  borderRadius: '30px',
  padding: 3,
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '18px',
  boxShadow: '0 0 15px 5px rgba(142, 36, 170, 0.5)',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 0 20px 10px rgba(142, 36, 170, 0.7)',
    background: 'linear-gradient(90deg, #7B1FA2, #9C27B0)',
  },
  '&:active': {
    boxShadow: '0 0 10px 2px rgba(142, 36, 170, 0.7)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const NumberContent = styled(CardContent)(({ theme, selected }) => ({
  backgroundColor: 'black',
  position: 'relative',
  backgroundImage: `url(${img})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: selected ? 'white' : 'black',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: selected ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
    zIndex: 1,
  },
  '& > .MuiTypography-root': {
    position: 'relative',
    zIndex: 2,
  },
}));

const NumberSelection = ({ onPlay }) => {
  const [selectedNumber, setSelectedNumber] = useState(null);
  const { stake } = useParams();
  const navigate = useNavigate();

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
  };

  const handlePlayClick = async () => {
    const uid = localStorage.getItem('uid');
    const database = getDatabase();
    const gameSessionNumberRef = ref(database, 'BingoGames/currentGame');
    const snapshot = await get(gameSessionNumberRef);
    const gameSessionNumber = snapshot.val();
    const gameSessionRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/cartelas`);

    // Check if the selected cartela number is already taken
    const cartelasSnapshot = await get(gameSessionRef);
    const existingCartelas = cartelasSnapshot.val();
    const isNumberTaken = Object.values(existingCartelas || {}).flat().includes(selectedNumber);

    if (isNumberTaken) {
      toast.error("This Cartela number is already taken!");
    } else {
      const userCartelaRef = ref(database, `BingoGames/Game${gameSessionNumber}/gameType${stake}/cartelas/${uid}`);
      await set(userCartelaRef, [selectedNumber]);
      navigate(`/page1/${selectedNumber}/${stake}`);
    }
  };

  const numbers = Array.from({ length: 100 }, (_, index) => index + 1);

  return (
    <OrbitSpace>
        <ResponsiveAppBar/>
      <ThemeProvider theme={theme}>
        <Box p={2}>
        
          <Typography variant="h4" align="center" gutterBottom sx={{ color: 'white' }}>
           Cartela
          </Typography>
          <Box display="flex" justifyContent="center" mt={4} sx={{ paddingBottom: 5 }}>
            <GlowingButton
              variant="contained"
              color="secondary"
              size="large"
              onClick={handlePlayClick}
              disabled={selectedNumber === null}
            >
                          <Typography paddingLeft={3} paddingRight={3} paddingTop={1} paddingBottom={1} fontWeight={'bold'}>Play Now</Typography>
            </GlowingButton>
          </Box>
          <Grid container spacing={0.5} justifyContent="center">
            {numbers.map((number) => (
              <Grid item xs={4} sm={1} md={1} key={number}>
                <NumberCard
                  selected={selectedNumber === number}
                  onClick={() => handleNumberClick(number)}
                >
                  <NumberContent selected={selectedNumber === number}>
                    <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
                      {number}
                    </Typography>
                  </NumberContent>
                </NumberCard>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" justifyContent="center" mt={4}>
            <GlowingButton
              variant="contained"
              color="secondary"
              size="large"
              onClick={handlePlayClick}
              disabled={selectedNumber === null}
            >
              Play Now
            </GlowingButton>
          </Box>
          <PlayerList/>
        </Box>
      </ThemeProvider>
    </OrbitSpace>
  );
};

export default NumberSelection;
