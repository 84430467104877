import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import GroupChat from './GroupChat'; // Assuming GroupChat is in the same directory
import LoginDialog from '../login2';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1c253a',
    },
    secondary: {
      main: '#00C9C8',
    },
    background: {
      paper: '#1c253a',
    },
    text: {
      primary: '#ffffff',
      secondary: '#00C9C8',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          background: 'linear-gradient(90deg, #1c253a 30%, #2b2d4d 90%)',
          color: '#00C9C8',
        },
        body: {
          height: '10px',
          color: '#ffffff',
        },
      },
    },
  },
});

const ScrollableContainer = styled(Box)(({ theme }) => ({
  maxHeight: '60vh',
  overflowY: 'auto',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  marginBottom: '70px', // Space for the fixed buttons
}));

const FooterButtons = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  padding: theme.spacing(1),
  background: '#1b105f',
  borderTop: `1px solid ${theme.palette.primary.main}`,
  zIndex: 1000, // Ensure it stays on top
}));

const PlayerList = () => {
  const [winners, setWinners] = useState([]);
  const [showChat, setShowChat] = useState(false); // State to manage chat visibility
  const [loginDialogOpen, setLoginDialogOpen] = useState(false); // State to manage login dialog visibility
  const { t } = useTranslation();

  useEffect(() => {
    const fetchWinners = async () => {
      const db = getFirestore();
      const winnersCol = collection(db, "winners");
      const q = query(winnersCol, orderBy("date", "desc"));
      const winnersSnapshot = await getDocs(q);
      const winnersList = winnersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setWinners(winnersList);
    };

    fetchWinners();
  }, []);

  const handleChatToggle = () => {
    const uid = localStorage.getItem('uid');
    if (!uid) {
      setLoginDialogOpen(true); // Open the login dialog if UID is not found
    } else {
      setShowChat(prev => !prev); // Toggle chat visibility if UID is found
    }
  };

  const handleLoginDialogClose = () => {
    setLoginDialogOpen(false);
  };

  const handleScroll = (e) => {
    const container = e.target;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const scrollTop = container.scrollTop;

    const scrolledPercentage = (scrollTop + clientHeight) / scrollHeight;

    if (scrolledPercentage >= 0.8) {
      container.scrollTop = scrollTop - 1; // Prevent scrolling further downwards
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {!showChat && (
        <ScrollableContainer onScroll={handleScroll}>
          <TableContainer component={Paper} sx={{ background: '#1b105f' }}>
            <Table sx={{ height: 23 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Win</TableCell>
                  <TableCell align="center">Game #</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {winners.map((winner) => (
                  <TableRow key={winner.id}>
                    <TableCell align="center" sx={{ color: '#22E4E8', height: '2px', maxLines: 1 }}>{winner.name}</TableCell>
                    <TableCell align="center" sx={{ color: '#22E4E8' }}>{`${winner.stake} ETB`}</TableCell>
                    <TableCell align="center" sx={{ color: '#22E4E8' }}>{`${winner.GameNumber}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollableContainer>
      )}

      {showChat && <GroupChat />} {/* Render GroupChat when showChat is true */}

      <FooterButtons>
        <Button
          variant="contained"
          sx={{ border: `2px solid ${showChat ? 'grey' : '#7B1FA2'}`, background: showChat ? 'primary' : 'linear-gradient(90deg, #7B1FA2, #9C27B0)' }}
          color={showChat ? 'primary' : 'primary'}
          onClick={() => setShowChat(false)}
        >
          {t("Top Wins")}
        </Button>
        <Button
          variant="contained"
          sx={{ border: `2px solid ${showChat ? '#7B1FA2' : 'grey'}`, background: showChat ? 'linear-gradient(90deg, #7B1FA2, #9C27B0)' : 'primary' }}
          color={showChat ? 'primary' : 'primary'}
          onClick={handleChatToggle}
        >
          <Typography paddingLeft={2} paddingRight={2}>{showChat ? t('Chat'): t('Chat')}</Typography>
        </Button>
      </FooterButtons>

      {/* Render the LoginDialog component */}
      <LoginDialog open={loginDialogOpen} onClose={handleLoginDialogClose} />
    </ThemeProvider>
  );
};

export default PlayerList;
